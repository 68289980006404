export default [
    {
        type: "FeatureCollection",
        crs: {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:OGC:1.3:CRS84",
            },
        },
        features: [
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.028493085388675,
                    centroid_y: 40.641049676813893,
                    zone_label: 1,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.033573826763629, 40.637263690863797],
                            [-74.023523355163832, 40.637179138976514],
                            [-74.023411769236432, 40.644835505601812],
                            [-74.033463388906142, 40.644920080203491],
                            [-74.033573826763629, 40.637263690863797],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.018442061193142,
                    centroid_y: 40.640964676246767,
                    zone_label: 2,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.023523355163832, 40.637179138976514],
                            [-74.013472926425422, 40.637093712562553],
                            [-74.01336019244674, 40.644750056238578],
                            [-74.023411769236432, 40.644835505601812],
                            [-74.023523355163832, 40.637179138976514],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.018329879689304,
                    centroid_y: 40.648621026196864,
                    zone_label: 3,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.023411769236432, 40.644835505601812],
                            [-74.01336019244674, 40.644750056238578],
                            [-74.013247415147035, 40.652406389471722],
                            [-74.023300140429015, 40.652491861789414],
                            [-74.023411769236432, 40.644835505601812],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.017880722387446,
                    centroid_y: 40.679246321586284,
                    zone_label: 4,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.022964996540153, 40.675460867720616],
                            [-74.012908823133287, 40.675375326508451],
                            [-74.012795872360968, 40.683031617964708],
                            [-74.022853196026105, 40.683117182152081],
                            [-74.022964996540153, 40.675460867720616],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.017768325146562,
                    centroid_y: 40.686902619328279,
                    zone_label: 5,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.022853196026105, 40.683117182152081],
                            [-74.012795872360968, 40.683031617964708],
                            [-74.012682878173322, 40.69068789897544],
                            [-74.022741352538702, 40.690773486143208],
                            [-74.022853196026105, 40.683117182152081],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.017430874126148,
                    centroid_y: 40.709871449893051,
                    zone_label: 6,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.022517536568998, 40.706086062802392],
                            [-74.012456759476422, 40.706000429658239],
                            [-74.012343634929351, 40.713656679329247],
                            [-74.022405564049237, 40.713742335469405],
                            [-74.022517536568998, 40.706086062802392],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.008391080088742,
                    centroid_y: 40.640878801040962,
                    zone_label: 7,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.013472926425422, 40.637093712562553],
                            [-74.003422540989206, 40.63700741163278],
                            [-74.003308658978085, 40.644663732124677],
                            [-74.01336019244674, 40.644750056238578],
                            [-74.013472926425422, 40.637093712562553],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.008277750322534,
                    centroid_y: 40.648535127921733,
                    zone_label: 8,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.01336019244674, 40.644750056238578],
                            [-74.003308658978085, 40.644663732124677],
                            [-74.003194733204921, 40.652320042168419],
                            [-74.013247415147035, 40.652406389471722],
                            [-74.01336019244674, 40.644750056238578],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.008164377005301,
                    centroid_y: 40.656191444356722,
                    zone_label: 9,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.013247415147035, 40.652406389471722],
                            [-74.003194733204921, 40.652320042168419],
                            [-74.003080763650686, 40.659976341763503],
                            [-74.013134594507449, 40.660062712261414],
                            [-74.013247415147035, 40.652406389471722],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.007937499642097,
                    centroid_y: 40.671504045887275,
                    zone_label: 10,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.013021730509166, 40.66771902460718],
                            [-74.002966750296366, 40.667632630909402],
                            [-74.002852693122875, 40.675288909605577],
                            [-74.012908823133287, 40.675375326508451],
                            [-74.013021730509166, 40.66771902460718],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.007823995558226,
                    centroid_y: 40.67916033098178,
                    zone_label: 11,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.012908823133287, 40.675375326508451],
                            [-74.002852693122875, 40.675288909605577],
                            [-74.002738592111172, 40.682945177851487],
                            [-74.012795872360968, 40.683031617964708],
                            [-74.012908823133287, 40.675375326508451],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.007369542764479,
                    centroid_y: 40.709785366875664,
                    zone_label: 12,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.012456759476422, 40.706000429658239],
                            [-74.002396025855916, 40.705913919882491],
                            [-74.002281749300437, 40.713570146322155],
                            [-74.012343634929351, 40.713656679329247],
                            [-74.012456759476422, 40.706000429658239],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.007255820356491,
                    centroid_y: 40.71744159972549,
                    zone_label: 13,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.012343634929351, 40.713656679329247],
                            [-74.002281749300437, 40.713570146322155],
                            [-74.002167428811262, 40.72122636230894],
                            [-74.012230466891296, 40.721312918552641],
                            [-74.012343634929351, 40.713656679329247],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.00714205422662,
                    centroid_y: 40.725097822124809,
                    zone_label: 14,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.012230466891296, 40.721312918552641],
                            [-74.002167428811262, 40.72122636230894],
                            [-74.002053064369207, 40.728882567842327],
                            [-74.012117255343298, 40.728969147327895],
                            [-74.012230466891296, 40.721312918552641],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.007028244355823,
                    centroid_y: 40.73275403407311,
                    zone_label: 15,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.012117255343298, 40.728969147327895],
                            [-74.002053064369207, 40.728882567842327],
                            [-74.001938655955158, 40.736538762921782],
                            [-74.012004000266415, 40.736625365654469],
                            [-74.012117255343298, 40.728969147327895],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.006914390725044,
                    centroid_y: 40.740410235569847,
                    zone_label: 16,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.012004000266415, 40.736625365654469],
                            [-74.001938655955158, 40.736538762921782],
                            [-74.001824203549944, 40.744194947546795],
                            [-74.011890701641676, 40.744281573531893],
                            [-74.012004000266415, 40.736625365654469],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -74.006800493315225,
                    centroid_y: 40.748066426614528,
                    zone_label: 17,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.011890701641676, 40.744281573531893],
                            [-74.001824203549944, 40.744194947546795],
                            [-74.001709707134381, 40.751851121716832],
                            [-74.011777359450107, 40.7519377709596],
                            [-74.011890701641676, 40.744281573531893],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.998340142516426,
                    centroid_y: 40.640792051207384,
                    zone_label: 18,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.003422540989206, 40.63700741163278],
                            [-73.993372199296019, 40.6369202361982],
                            [-73.993257169271459, 40.644576533271078],
                            [-74.003308658978085, 40.644663732124677],
                            [-74.003422540989206, 40.63700741163278],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.998225664506748,
                    centroid_y: 40.648448354783952,
                    zone_label: 19,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.003308658978085, 40.644663732124677],
                            [-73.993257169271459, 40.644576533271078],
                            [-73.993142095043865, 40.652232819890529],
                            [-74.003194733204921, 40.652320042168419],
                            [-74.003308658978085, 40.644663732124677],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.998111142504982,
                    centroid_y: 40.656104647909466,
                    zone_label: 20,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.003194733204921, 40.652320042168419],
                            [-73.993142095043865, 40.652232819890529],
                            [-73.993026976594038, 40.65988909605602],
                            [-74.003080763650686, 40.659976341763503],
                            [-74.003194733204921, 40.652320042168419],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.997996576492028,
                    centroid_y: 40.663760930583422,
                    zone_label: 21,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.003080763650686, 40.659976341763503],
                            [-73.993026976594038, 40.65988909605602],
                            [-73.992911813902708, 40.667545361767026],
                            [-74.002966750296366, 40.667632630909402],
                            [-74.003080763650686, 40.659976341763503],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.997881966448688,
                    centroid_y: 40.671417202805273,
                    zone_label: 22,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.002966750296366, 40.667632630909402],
                            [-73.992911813902708, 40.667545361767026],
                            [-73.992796606950677, 40.675201617023014],
                            [-74.002852693122875, 40.675288909605577],
                            [-74.002966750296366, 40.667632630909402],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.997767312355819,
                    centroid_y: 40.6790734645745,
                    zone_label: 23,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.002852693122875, 40.675288909605577],
                            [-73.992796606950677, 40.675201617023014],
                            [-73.992681355718645, 40.68285786182345],
                            [-74.002738592111172, 40.682945177851487],
                            [-74.002852693122875, 40.675288909605577],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.997652614194251,
                    centroid_y: 40.686729715890557,
                    zone_label: 24,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.002738592111172, 40.682945177851487],
                            [-73.992681355718645, 40.68285786182345],
                            [-73.992566060187386, 40.690514096167824],
                            [-74.002624447242169, 40.690601435646634],
                            [-74.002738592111172, 40.682945177851487],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.997537871944829,
                    centroid_y: 40.694385956752939,
                    zone_label: 25,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.002624447242169, 40.690601435646634],
                            [-73.992566060187386, 40.690514096167824],
                            [-73.992450720337587, 40.698170320055574],
                            [-74.00251025849677, 40.69825768299048],
                            [-74.002624447242169, 40.690601435646634],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.99742308558838,
                    centroid_y: 40.702042187161112,
                    zone_label: 26,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.00251025849677, 40.69825768299048],
                            [-73.992450720337587, 40.698170320055574],
                            [-73.992335336149992, 40.70582653348621],
                            [-74.002396025855916, 40.705913919882491],
                            [-74.00251025849677, 40.69825768299048],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.997308255105651,
                    centroid_y: 40.709698407114537,
                    zone_label: 27,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.002396025855916, 40.705913919882491],
                            [-73.992335336149992, 40.70582653348621],
                            [-73.992219907605246, 40.713482736459177],
                            [-74.002281749300437, 40.713570146322155],
                            [-74.002396025855916, 40.705913919882491],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.997193380477455,
                    centroid_y: 40.717354616612695,
                    zone_label: 28,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.002281749300437, 40.713570146322155],
                            [-73.992219907605246, 40.713482736459177],
                            [-73.992104434684066, 40.72113892897395],
                            [-74.002167428811262, 40.72122636230894],
                            [-74.002281749300437, 40.713570146322155],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.997078461684538,
                    centroid_y: 40.725010815655061,
                    zone_label: 29,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.002167428811262, 40.72122636230894],
                            [-73.992104434684066, 40.72113892897395],
                            [-73.991988917367124, 40.72879511103001],
                            [-74.002053064369207, 40.728882567842327],
                            [-74.002167428811262, 40.72122636230894],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.996963498707743,
                    centroid_y: 40.732667004241122,
                    zone_label: 30,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.002053064369207, 40.728882567842327],
                            [-73.991988917367124, 40.72879511103001],
                            [-73.991873355635065, 40.736451282626831],
                            [-74.001938655955158, 40.736538762921782],
                            [-74.002053064369207, 40.728882567842327],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.996848491527714,
                    centroid_y: 40.740323182370332,
                    zone_label: 31,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.001938655955158, 40.736538762921782],
                            [-73.991873355635065, 40.736451282626831],
                            [-73.991757749468547, 40.74410744376388],
                            [-74.001824203549944, 40.744194947546795],
                            [-74.001938655955158, 40.736538762921782],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.99673344012524,
                    centroid_y: 40.747979350042172,
                    zone_label: 32,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.001824203549944, 40.744194947546795],
                            [-73.991757749468547, 40.74410744376388],
                            [-73.991642098848175, 40.751763594440639],
                            [-74.001709707134381, 40.751851121716832],
                            [-74.001824203549944, 40.744194947546795],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.996618344481035,
                    centroid_y: 40.755635507256137,
                    zone_label: 33,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.001709707134381, 40.751851121716832],
                            [-73.991642098848175, 40.751763594440639],
                            [-73.991526403754605, 40.759419734656603],
                            [-74.001595166689299, 40.75950728543139],
                            [-74.001709707134381, 40.751851121716832],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.996503204575831,
                    centroid_y: 40.763291654011702,
                    zone_label: 34,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-74.001595166689299, 40.75950728543139],
                            [-73.991526403754605, 40.759419734656603],
                            [-73.991410664168441, 40.767075864411197],
                            [-74.001480582195484, 40.767163438689934],
                            [-74.001595166689299, 40.75950728543139],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.988289248917084,
                    centroid_y: 40.640704426757125,
                    zone_label: 35,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.993372199296019, 40.6369202361982],
                            [-73.983321901786638, 40.636832186269906],
                            [-73.983205723767853, 40.644488459688901],
                            [-73.993257169271459, 40.644576533271078],
                            [-73.993372199296019, 40.6369202361982],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.988173622683078,
                    centroid_y: 40.648360706794584,
                    zone_label: 36,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.993257169271459, 40.644576533271078],
                            [-73.983205723767853, 40.644488459688901],
                            [-73.983089501105056, 40.652144722649133],
                            [-73.993142095043865, 40.652232819890529],
                            [-73.993257169271459, 40.644576533271078],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.988057952015922,
                    centroid_y: 40.656016976375689,
                    zone_label: 37,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.993142095043865, 40.652232819890529],
                            [-73.983089501105056, 40.652144722649133],
                            [-73.982973233778836, 40.659800975150056],
                            [-73.993026976594038, 40.65988909605602],
                            [-73.993142095043865, 40.652232819890529],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987942236896245,
                    centroid_y: 40.6636732354999,
                    zone_label: 38,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.993026976594038, 40.65988909605602],
                            [-73.982973233778836, 40.659800975150056],
                            [-73.98285692176978, 40.667457217191163],
                            [-73.992911813902708, 40.667545361767026],
                            [-73.993026976594038, 40.65988909605602],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987826477304765,
                    centroid_y: 40.671329484166698,
                    zone_label: 39,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.992911813902708, 40.667545361767026],
                            [-73.98285692176978, 40.667457217191163],
                            [-73.98274056505845, 40.675113448771889],
                            [-73.992796606950677, 40.675201617023014],
                            [-73.992911813902708, 40.667545361767026],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987710673222068,
                    centroid_y: 40.678985722375522,
                    zone_label: 40,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.992796606950677, 40.675201617023014],
                            [-73.98274056505845, 40.675113448771889],
                            [-73.982624163625388, 40.682769669891726],
                            [-73.992681355718645, 40.68285786182345],
                            [-73.992796606950677, 40.675201617023014],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987594824628871,
                    centroid_y: 40.686641950125882,
                    zone_label: 41,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.992681355718645, 40.68285786182345],
                            [-73.982624163625388, 40.682769669891726],
                            [-73.982507717451128, 40.69042588055013],
                            [-73.992566060187386, 40.690514096167824],
                            [-73.992681355718645, 40.68285786182345],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987478931505748,
                    centroid_y: 40.694298167417223,
                    zone_label: 42,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.992566060187386, 40.690514096167824],
                            [-73.982507717451128, 40.69042588055013],
                            [-73.982391226516228, 40.698082080746573],
                            [-73.992450720337587, 40.698170320055574],
                            [-73.992566060187386, 40.690514096167824],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987362993833372,
                    centroid_y: 40.701954374249027,
                    zone_label: 43,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.992450720337587, 40.698170320055574],
                            [-73.982391226516228, 40.698082080746573],
                            [-73.982274690801191, 40.705738270480516],
                            [-73.992335336149992, 40.70582653348621],
                            [-73.992450720337587, 40.698170320055574],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987247011592288,
                    centroid_y: 40.709610570620754,
                    zone_label: 44,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.992335336149992, 40.70582653348621],
                            [-73.982274690801191, 40.705738270480516],
                            [-73.982158110286491, 40.713394449751448],
                            [-73.992219907605246, 40.713482736459177],
                            [-73.992335336149992, 40.70582653348621],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987130984763127,
                    centroid_y: 40.717266756531863,
                    zone_label: 45,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.992219907605246, 40.713482736459177],
                            [-73.982158110286491, 40.713394449751448],
                            [-73.98204148495266, 40.721050618558806],
                            [-73.992104434684066, 40.72113892897395],
                            [-73.992219907605246, 40.713482736459177],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.987014913326419,
                    centroid_y: 40.724922931981858,
                    zone_label: 46,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.992104434684066, 40.72113892897395],
                            [-73.98204148495266, 40.721050618558806],
                            [-73.981924814780157, 40.728706776902101],
                            [-73.991988917367124, 40.72879511103001],
                            [-73.992104434684066, 40.72113892897395],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.98689879726281,
                    centroid_y: 40.732579096970191,
                    zone_label: 47,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.991988917367124, 40.72879511103001],
                            [-73.981924814780157, 40.728706776902101],
                            [-73.981808099749443, 40.736362924780785],
                            [-73.991873355635065, 40.736451282626831],
                            [-73.991988917367124, 40.72879511103001],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.986782636552803,
                    centroid_y: 40.740235251496351,
                    zone_label: 48,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.991873355635065, 40.736451282626831],
                            [-73.981808099749443, 40.736362924780785],
                            [-73.981691339840964, 40.744019062194319],
                            [-73.991757749468547, 40.74410744376388],
                            [-73.991873355635065, 40.736451282626831],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.986666431176957,
                    centroid_y: 40.747891395559776,
                    zone_label: 49,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.991757749468547, 40.74410744376388],
                            [-73.981691339840964, 40.744019062194319],
                            [-73.981574535035179, 40.75167518914219],
                            [-73.991642098848175, 40.751763594440639],
                            [-73.991757749468547, 40.74410744376388],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.986550181115803,
                    centroid_y: 40.75554752915999,
                    zone_label: 50,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.991642098848175, 40.751763594440639],
                            [-73.981574535035179, 40.75167518914219],
                            [-73.98145768531252, 40.759331305623888],
                            [-73.991526403754605, 40.759419734656603],
                            [-73.991642098848175, 40.751763594440639],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.986433886349829,
                    centroid_y: 40.763203652296419,
                    zone_label: 51,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.991526403754605, 40.759419734656603],
                            [-73.98145768531252, 40.759331305623888],
                            [-73.981340790653377, 40.766987411638837],
                            [-73.991410664168441, 40.767075864411197],
                            [-73.991526403754605, 40.759419734656603],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.986317546859638,
                    centroid_y: 40.770859764968577,
                    zone_label: 52,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.991410664168441, 40.767075864411197],
                            [-73.981340790653377, 40.766987411638837],
                            [-73.981223851038166, 40.774643507186553],
                            [-73.991294880070242, 40.774731983703958],
                            [-73.991410664168441, 40.767075864411197],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.986201162625591,
                    centroid_y: 40.778515867175926,
                    zone_label: 53,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.991294880070242, 40.774731983703958],
                            [-73.981223851038166, 40.774643507186553],
                            [-73.981106866447291, 40.782299592266511],
                            [-73.991179051440639, 40.782388092534312],
                            [-73.991294880070242, 40.774731983703958],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.986084733628246,
                    centroid_y: 40.786171958917933,
                    zone_label: 54,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.991179051440639, 40.782388092534312],
                            [-73.981106866447291, 40.782299592266511],
                            [-73.980989836861113, 40.789955666878171],
                            [-73.991063178260191, 40.790044190901796],
                            [-73.991179051440639, 40.782388092534312],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.978238399731595,
                    centroid_y: 40.640615927701312,
                    zone_label: 55,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.983321901786638, 40.636832186269906],
                            [-73.973271648901886, 40.636743261859124],
                            [-73.973154322908258, 40.644399511389373],
                            [-73.983205723767853, 40.644488459688901],
                            [-73.983321901786638, 40.636832186269906],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.978121625292573,
                    centroid_y: 40.648272183964771,
                    zone_label: 56,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.983205723767853, 40.644488459688901],
                            [-73.973154322908258, 40.644399511389373],
                            [-73.97303695182967, 40.652055750455467],
                            [-73.983089501105056, 40.652144722649133],
                            [-73.983205723767853, 40.644488459688901],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.978004805979324,
                    centroid_y: 40.655928429766519,
                    zone_label: 57,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.983089501105056, 40.652144722649133],
                            [-73.97303695182967, 40.652055750455467],
                            [-73.972919535646497, 40.659711979056866],
                            [-73.982973233778836, 40.659800975150056],
                            [-73.983089501105056, 40.652144722649133],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.977887941772309,
                    centroid_y: 40.663584665105994,
                    zone_label: 58,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.982973233778836, 40.659800975150056],
                            [-73.972919535646497, 40.659711979056866],
                            [-73.972802074339128, 40.667368197193035],
                            [-73.98285692176978, 40.667457217191163],
                            [-73.982973233778836, 40.659800975150056],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.977771032651987,
                    centroid_y: 40.671240889982684,
                    zone_label: 59,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.98285692176978, 40.667457217191163],
                            [-73.972802074339128, 40.667368197193035],
                            [-73.972684567887939, 40.675024404863436],
                            [-73.98274056505845, 40.675113448771889],
                            [-73.98285692176978, 40.667457217191163],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.977654078598903,
                    centroid_y: 40.678897104396036,
                    zone_label: 60,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.98274056505845, 40.675113448771889],
                            [-73.972684567887939, 40.675024404863436],
                            [-73.972567016273302, 40.682680602067542],
                            [-73.982624163625388, 40.682769669891726],
                            [-73.98274056505845, 40.675113448771889],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.97753707959339,
                    centroid_y: 40.68655330834553,
                    zone_label: 61,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.982624163625388, 40.682769669891726],
                            [-73.972567016273302, 40.682680602067542],
                            [-73.972449419475538, 40.6903367888048],
                            [-73.982507717451128, 40.69042588055013],
                            [-73.982624163625388, 40.682769669891726],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.977420035615964,
                    centroid_y: 40.694209501830628,
                    zone_label: 62,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.982507717451128, 40.69042588055013],
                            [-73.972449419475538, 40.6903367888048],
                            [-73.972331777475006, 40.69799296507469],
                            [-73.982391226516228, 40.698082080746573],
                            [-73.982507717451128, 40.69042588055013],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.977302946647029,
                    centroid_y: 40.701865684850809,
                    zone_label: 63,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.982391226516228, 40.698082080746573],
                            [-73.972331777475006, 40.69799296507469],
                            [-73.972214090252024, 40.705649130876679],
                            [-73.982274690801191, 40.705738270480516],
                            [-73.982391226516228, 40.698082080746573],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.977068633656273,
                    centroid_y: 40.717178019494227,
                    zone_label: 64,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.982158110286491, 40.713394449751448],
                            [-73.972096357786896, 40.713305286210236],
                            [-73.971978580059925, 40.720961431074805],
                            [-73.98204148495266, 40.721050618558806],
                            [-73.982158110286491, 40.713394449751448],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976951409595259,
                    centroid_y: 40.724834171116427,
                    zone_label: 65,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.98204148495266, 40.721050618558806],
                            [-73.971978580059925, 40.720961431074805],
                            [-73.971860757051374, 40.728617565469882],
                            [-73.981924814780157, 40.728706776902101],
                            [-73.98204148495266, 40.721050618558806],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976834140464305,
                    centroid_y: 40.732490312271572,
                    zone_label: 66,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.981924814780157, 40.728706776902101],
                            [-73.971860757051374, 40.728617565469882],
                            [-73.971742888741545, 40.73627368939492],
                            [-73.981808099749443, 40.736362924780785],
                            [-73.981924814780157, 40.728706776902101],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976716826243745,
                    centroid_y: 40.74014644295913,
                    zone_label: 67,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.981808099749443, 40.736362924780785],
                            [-73.971742888741545, 40.73627368939492],
                            [-73.9716249751107, 40.743929802849394],
                            [-73.981691339840964, 40.744019062194319],
                            [-73.981808099749443, 40.736362924780785],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976599466913996,
                    centroid_y: 40.747802563178574,
                    zone_label: 68,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.981691339840964, 40.744019062194319],
                            [-73.9716249751107, 40.743929802849394],
                            [-73.971507016139071, 40.751585905832776],
                            [-73.981574535035179, 40.75167518914219],
                            [-73.981691339840964, 40.744019062194319],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976482062455375,
                    centroid_y: 40.755458672929393,
                    zone_label: 69,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.981574535035179, 40.75167518914219],
                            [-73.971507016139071, 40.751585905832776],
                            [-73.971389011806892, 40.759241998344542],
                            [-73.98145768531252, 40.759331305623888],
                            [-73.981574535035179, 40.75167518914219],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976364612848172,
                    centroid_y: 40.763114772211026,
                    zone_label: 70,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.98145768531252, 40.759331305623888],
                            [-73.971389011806892, 40.759241998344542],
                            [-73.971270962094394, 40.766898080384138],
                            [-73.981340790653377, 40.766987411638837],
                            [-73.98145768531252, 40.759331305623888],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976247118072749,
                    centroid_y: 40.770770861022967,
                    zone_label: 71,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.981340790653377, 40.766987411638837],
                            [-73.971270962094394, 40.766898080384138],
                            [-73.971152866981782, 40.774554151951065],
                            [-73.981223851038166, 40.774643507186553],
                            [-73.981340790653377, 40.766987411638837],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976129578109365,
                    centroid_y: 40.778426939364707,
                    zone_label: 72,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.981223851038166, 40.774643507186553],
                            [-73.971152866981782, 40.774554151951065],
                            [-73.971034726449247, 40.782210213044785],
                            [-73.981106866447291, 40.782299592266511],
                            [-73.981223851038166, 40.774643507186553],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.976011992938339,
                    centroid_y: 40.786083007235682,
                    zone_label: 73,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.981106866447291, 40.782299592266511],
                            [-73.971034726449247, 40.782210213044785],
                            [-73.970916540476978, 40.78986626366477],
                            [-73.980989836861113, 40.789955666878171],
                            [-73.981106866447291, 40.782299592266511],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.975894362539918,
                    centroid_y: 40.793739064635389,
                    zone_label: 74,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.980989836861113, 40.789955666878171],
                            [-73.970916540476978, 40.78986626366477],
                            [-73.970798309045151, 40.797522303810503],
                            [-73.980872762260006, 40.797611731021021],
                            [-73.980989836861113, 40.789955666878171],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.968187595400877,
                    centroid_y: 40.640526554051242,
                    zone_label: 75,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.973271648901886, 40.636743261859124],
                            [-73.96322144108251, 40.636653462977165],
                            [-73.963102967133651, 40.644309688383821],
                            [-73.973154322908258, 40.644399511389373],
                            [-73.973271648901886, 40.636743261859124],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.968069672776338,
                    centroid_y: 40.648182786305824,
                    zone_label: 76,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.973154322908258, 40.644399511389373],
                            [-73.963102967133651, 40.644309688383821],
                            [-73.962984447658854, 40.651965903320871],
                            [-73.97303695182967, 40.652055750455467],
                            [-73.973154322908258, 40.644399511389373],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.967951704836494,
                    centroid_y: 40.65583900809326,
                    zone_label: 77,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.97303695182967, 40.652055750455467],
                            [-73.962984447658854, 40.651965903320871],
                            [-73.962865882638354, 40.659622107787783],
                            [-73.972919535646497, 40.659711979056866],
                            [-73.97303695182967, 40.652055750455467],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.967833691561651,
                    centroid_y: 40.663495219413015,
                    zone_label: 78,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.972919535646497, 40.659711979056866],
                            [-73.962865882638354, 40.659622107787783],
                            [-73.962747272052297, 40.667278301784016],
                            [-73.972802074339128, 40.667368197193035],
                            [-73.972919535646497, 40.659711979056866],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.96771563293207,
                    centroid_y: 40.671151420264557,
                    zone_label: 79,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.972802074339128, 40.667368197193035],
                            [-73.962747272052297, 40.667278301784016],
                            [-73.962628615880902, 40.674934485309016],
                            [-73.972684567887939, 40.675024404863436],
                            [-73.972802074339128, 40.667368197193035],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.967597528928067,
                    centroid_y: 40.67880761064734,
                    zone_label: 80,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.972684567887939, 40.675024404863436],
                            [-73.962628615880902, 40.674934485309016],
                            [-73.962509914104331, 40.682590658362265],
                            [-73.972567016273302, 40.682680602067542],
                            [-73.972684567887939, 40.675024404863436],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.967479379529863,
                    centroid_y: 40.686463790560815,
                    zone_label: 81,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.972567016273302, 40.682680602067542],
                            [-73.962509914104331, 40.682590658362265],
                            [-73.96239116670273, 40.690246820943202],
                            [-73.972449419475538, 40.6903367888048],
                            [-73.972567016273302, 40.682680602067542],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.967361184717646,
                    centroid_y: 40.694119960004464,
                    zone_label: 82,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.972449419475538, 40.6903367888048],
                            [-73.96239116670273, 40.690246820943202],
                            [-73.962272373656234, 40.697902973051328],
                            [-73.972331777475006, 40.69799296507469],
                            [-73.972449419475538, 40.6903367888048],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.967242944471792,
                    centroid_y: 40.701776118977769,
                    zone_label: 83,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.972331777475006, 40.69799296507469],
                            [-73.962272373656234, 40.697902973051328],
                            [-73.962153534945003, 40.705559114686075],
                            [-73.972214090252024, 40.705649130876679],
                            [-73.972331777475006, 40.69799296507469],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.96712465877242,
                    centroid_y: 40.70943226748016,
                    zone_label: 84,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.972214090252024, 40.705649130876679],
                            [-73.962153534945003, 40.705559114686075],
                            [-73.962034650549128, 40.713215245846918],
                            [-73.972096357786896, 40.713305286210236],
                            [-73.972214090252024, 40.705649130876679],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.967006327599748,
                    centroid_y: 40.717088405511099,
                    zone_label: 85,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.972096357786896, 40.713305286210236],
                            [-73.962034650549128, 40.713215245846918],
                            [-73.961915720448729, 40.720871366533309],
                            [-73.971978580059925, 40.720961431074805],
                            [-73.972096357786896, 40.713305286210236],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.966413988943472,
                    centroid_y: 40.755368938575685,
                    zone_label: 86,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.971507016139071, 40.751585905832776],
                            [-73.9614395426035, 40.751495744523794],
                            [-73.961320383681596, 40.759151812829991],
                            [-73.971389011806892, 40.759241998344542],
                            [-73.971507016139071, 40.751585905832776],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.966295384514751,
                    centroid_y: 40.763025013766843,
                    zone_label: 87,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.971389011806892, 40.759241998344542],
                            [-73.961320383681596, 40.759151812829991],
                            [-73.961201178935525, 40.766807870658518],
                            [-73.971270962094394, 40.766898080384138],
                            [-73.971389011806892, 40.759241998344542],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.966176734473834,
                    centroid_y: 40.770681078482852,
                    zone_label: 88,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.971270962094394, 40.766898080384138],
                            [-73.961201178935525, 40.766807870658518],
                            [-73.961081928345294, 40.774463918008898],
                            [-73.971152866981782, 40.774554151951065],
                            [-73.971270962094394, 40.766898080384138],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.96605803880081,
                    centroid_y: 40.778337132723173,
                    zone_label: 89,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.971152866981782, 40.774554151951065],
                            [-73.961081928345294, 40.774463918008898],
                            [-73.960962631890922, 40.782119954880571],
                            [-73.971034726449247, 40.782210213044785],
                            [-73.971152866981782, 40.774554151951065],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.965939297475799,
                    centroid_y: 40.785993176487274,
                    zone_label: 90,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.971034726449247, 40.782210213044785],
                            [-73.960962631890922, 40.782119954880571],
                            [-73.9608432895524, 40.789775981273017],
                            [-73.970916540476978, 40.78986626366477],
                            [-73.971034726449247, 40.782210213044785],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.965820510478906,
                    centroid_y: 40.793649209774649,
                    zone_label: 91,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.970916540476978, 40.78986626366477],
                            [-73.9608432895524, 40.789775981273017],
                            [-73.960723901309692, 40.79743199718569],
                            [-73.970798309045151, 40.797522303810503],
                            [-73.970916540476978, 40.78986626366477],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.965701677790179,
                    centroid_y: 40.801305232584753,
                    zone_label: 92,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.970798309045151, 40.797522303810503],
                            [-73.960723901309692, 40.79743199718569],
                            [-73.960604467142787, 40.805088002618106],
                            [-73.970680032133927, 40.80517833348145],
                            [-73.970798309045151, 40.797522303810503],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.965582799389665,
                    centroid_y: 40.808961244917057,
                    zone_label: 93,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.970680032133927, 40.80517833348145],
                            [-73.960604467142787, 40.805088002618106],
                            [-73.96048498703162, 40.812743997569683],
                            [-73.970561709723441, 40.812834352677086],
                            [-73.970680032133927, 40.80517833348145],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.958136836365725,
                    centroid_y: 40.640436305818262,
                    zone_label: 94,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.96322144108251, 40.636653462977165],
                            [-73.953171278769275, 40.636562789635462],
                            [-73.953051656884966, 40.644218990683676],
                            [-73.963102967133651, 40.644309688383821],
                            [-73.96322144108251, 40.636653462977165],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.958017765575377,
                    centroid_y: 40.648092513829091,
                    zone_label: 95,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.963102967133651, 40.644309688383821],
                            [-73.953051656884966, 40.644218990683676],
                            [-73.952931989033772, 40.651875181256777],
                            [-73.962984447658854, 40.651965903320871],
                            [-73.963102967133651, 40.644309688383821],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.95789864902865,
                    centroid_y: 40.655748711367309,
                    zone_label: 96,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.962984447658854, 40.651965903320871],
                            [-73.952931989033772, 40.651875181256777],
                            [-73.952812275195711, 40.659531361354247],
                            [-73.962865882638354, 40.659622107787783],
                            [-73.962984447658854, 40.651965903320871],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.957779486705704,
                    centroid_y: 40.663404898432361,
                    zone_label: 97,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.962865882638354, 40.659622107787783],
                            [-73.952812275195711, 40.659531361354247],
                            [-73.952692515350805, 40.667187530975518],
                            [-73.962747272052297, 40.667278301784016],
                            [-73.962865882638354, 40.659622107787783],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.957660278586602,
                    centroid_y: 40.671061075023715,
                    zone_label: 98,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.962747272052297, 40.667278301784016],
                            [-73.952692515350805, 40.667187530975518],
                            [-73.952572709479043, 40.674843690120071],
                            [-73.962628615880902, 40.674934485309016],
                            [-73.962747272052297, 40.667278301784016],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.957541024651405,
                    centroid_y: 40.678717241140816,
                    zone_label: 99,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.962628615880902, 40.674934485309016],
                            [-73.952572709479043, 40.674843690120071],
                            [-73.952452857560374, 40.682499838787358],
                            [-73.962509914104331, 40.682590658362265],
                            [-73.962628615880902, 40.674934485309016],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.957421724880234,
                    centroid_y: 40.686373396783139,
                    zone_label: 100,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.962509914104331, 40.682590658362265],
                            [-73.952452857560374, 40.682499838787358],
                            [-73.95233295957479, 40.690155976976818],
                            [-73.96239116670273, 40.690246820943202],
                            [-73.962509914104331, 40.682590658362265],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.957302379253136,
                    centroid_y: 40.694029541950165,
                    zone_label: 101,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.96239116670273, 40.690246820943202],
                            [-73.95233295957479, 40.690155976976818],
                            [-73.952213015502224, 40.697812104687941],
                            [-73.962272373656234, 40.697902973051328],
                            [-73.96239116670273, 40.690246820943202],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.957182987750059,
                    centroid_y: 40.701685676641311,
                    zone_label: 102,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.962272373656234, 40.697902973051328],
                            [-73.952213015502224, 40.697812104687941],
                            [-73.952093025322611, 40.705468221920171],
                            [-73.962153534945003, 40.705559114686075],
                            [-73.962272373656234, 40.697902973051328],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.957063550351108,
                    centroid_y: 40.70934180085608,
                    zone_label: 103,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.962153534945003, 40.705559114686075],
                            [-73.952093025322611, 40.705468221920171],
                            [-73.9519729890159, 40.713124328672976],
                            [-73.962034650549128, 40.713215245846918],
                            [-73.962153534945003, 40.705559114686075],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.956944067036304,
                    centroid_y: 40.716997914593904,
                    zone_label: 104,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.962034650549128, 40.713215245846918],
                            [-73.9519729890159, 40.713124328672976],
                            [-73.951852906561967, 40.720780424945808],
                            [-73.961915720448729, 40.720871366533309],
                            [-73.962034650549128, 40.713215245846918],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.95682453778565,
                    centroid_y: 40.724654017854277,
                    zone_label: 105,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.961915720448729, 40.720871366533309],
                            [-73.951852906561967, 40.720780424945808],
                            [-73.951732777940762, 40.728436510738142],
                            [-73.961796744623896, 40.728527476744738],
                            [-73.961915720448729, 40.720871366533309],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.95670496257911,
                    centroid_y: 40.732310110636618,
                    zone_label: 106,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.961796744623896, 40.728527476744738],
                            [-73.951732777940762, 40.728436510738142],
                            [-73.951612603132133, 40.736092586049431],
                            [-73.961677723054706, 40.736183576480634],
                            [-73.961796744623896, 40.728527476744738],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.956585341396647,
                    centroid_y: 40.73996619294045,
                    zone_label: 107,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.961677723054706, 40.736183576480634],
                            [-73.951612603132133, 40.736092586049431],
                            [-73.951492382115958, 40.743748650879155],
                            [-73.96155865572122, 40.743839665740509],
                            [-73.961677723054706, 40.736183576480634],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.956465674218279,
                    centroid_y: 40.747622264765212,
                    zone_label: 108,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.96155865572122, 40.743839665740509],
                            [-73.951492382115958, 40.743748650879155],
                            [-73.951372114872129, 40.751404705226754],
                            [-73.9614395426035, 40.751495744523794],
                            [-73.96155865572122, 40.743839665740509],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.956345961023914,
                    centroid_y: 40.755278326110343,
                    zone_label: 109,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.9614395426035, 40.751495744523794],
                            [-73.951372114872129, 40.751404705226754],
                            [-73.951251801380451, 40.759060749091717],
                            [-73.961320383681596, 40.759151812829991],
                            [-73.9614395426035, 40.751495744523794],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.956226201793498,
                    centroid_y: 40.762934376975345,
                    zone_label: 110,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.961320383681596, 40.759151812829991],
                            [-73.951251801380451, 40.759060749091717],
                            [-73.951131441620788, 40.766716782473495],
                            [-73.961201178935525, 40.766807870658518],
                            [-73.961320383681596, 40.759151812829991],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.956106396506982,
                    centroid_y: 40.770590417359685,
                    zone_label: 111,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.961201178935525, 40.766807870658518],
                            [-73.951131441620788, 40.766716782473495],
                            [-73.951011035572947, 40.774372805371556],
                            [-73.961081928345294, 40.774463918008898],
                            [-73.961201178935525, 40.766807870658518],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.955986545144228,
                    centroid_y: 40.778246447262802,
                    zone_label: 112,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.961081928345294, 40.774463918008898],
                            [-73.951011035572947, 40.774372805371556],
                            [-73.950890583216761, 40.782028817785374],
                            [-73.960962631890922, 40.782119954880571],
                            [-73.961081928345294, 40.774463918008898],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.955866647685198,
                    centroid_y: 40.785902466684199,
                    zone_label: 113,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.960962631890922, 40.782119954880571],
                            [-73.950890583216761, 40.782028817785374],
                            [-73.950770084531996, 40.789684819714424],
                            [-73.9608432895524, 40.789775981273017],
                            [-73.960962631890922, 40.782119954880571],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.955746704109742,
                    centroid_y: 40.793558475623342,
                    zone_label: 114,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.9608432895524, 40.789775981273017],
                            [-73.950770084531996, 40.789684819714424],
                            [-73.950649539498457, 40.797340811158158],
                            [-73.960723901309692, 40.79743199718569],
                            [-73.9608432895524, 40.789775981273017],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.955626714397738,
                    centroid_y: 40.801214474079678,
                    zone_label: 115,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.960723901309692, 40.79743199718569],
                            [-73.950649539498457, 40.797340811158158],
                            [-73.950528948095908, 40.804996792116043],
                            [-73.960604467142787, 40.805088002618106],
                            [-73.960723901309692, 40.79743199718569],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.955506678529048,
                    centroid_y: 40.808870462052688,
                    zone_label: 116,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.960604467142787, 40.805088002618106],
                            [-73.950528948095908, 40.804996792116043],
                            [-73.950408310304098, 40.812652762587568],
                            [-73.96048498703162, 40.812743997569683],
                            [-73.960604467142787, 40.805088002618106],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.955386596483521,
                    centroid_y: 40.816526439541846,
                    zone_label: 117,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.96048498703162, 40.812743997569683],
                            [-73.950408310304098, 40.812652762587568],
                            [-73.950287626102806, 40.820308722572186],
                            [-73.960365460956126, 40.820399982039937],
                            [-73.96048498703162, 40.812743997569683],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.955266468240993,
                    centroid_y: 40.824182406546626,
                    zone_label: 118,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.960365460956126, 40.820399982039937],
                            [-73.950287626102806, 40.820308722572186],
                            [-73.950166895471739, 40.827964672069363],
                            [-73.960245888896239, 40.828055956028308],
                            [-73.960365460956126, 40.820399982039937],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.948086123067057,
                    centroid_y: 40.640345183013899,
                    zone_label: 119,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.953171278769275, 40.636562789635462],
                            [-73.94312116240296, 40.636471241845577],
                            [-73.943000392603139, 40.644127418300492],
                            [-73.953051656884966, 40.644218990683676],
                            [-73.953171278769275, 40.636562789635462],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.947965904130768,
                    centroid_y: 40.648001366546104,
                    zone_label: 120,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.953051656884966, 40.644218990683676],
                            [-73.943000392603139, 40.644127418300492],
                            [-73.942879576395526, 40.651783584274767],
                            [-73.952931989033772, 40.651875181256777],
                            [-73.953051656884966, 40.644218990683676],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.947845638997066,
                    centroid_y: 40.655657539600156,
                    zone_label: 121,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.952931989033772, 40.651875181256777],
                            [-73.942879576395526, 40.651783584274767],
                            [-73.942758713759957, 40.659439739767834],
                            [-73.952812275195711, 40.659531361354247],
                            [-73.952931989033772, 40.651875181256777],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.947725327645927,
                    centroid_y: 40.663313702175529,
                    zone_label: 122,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.952812275195711, 40.659531361354247],
                            [-73.942758713759957, 40.659439739767834],
                            [-73.942637804676181, 40.667095884779151],
                            [-73.952692515350805, 40.667187530975518],
                            [-73.952812275195711, 40.659531361354247],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.947604970057185,
                    centroid_y: 40.670969854271654,
                    zone_label: 123,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.952692515350805, 40.667187530975518],
                            [-73.942637804676181, 40.667095884779151],
                            [-73.942516849124061, 40.674752019308187],
                            [-73.952572709479043, 40.674843690120071],
                            [-73.952692515350805, 40.667187530975518],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.947484566210804,
                    centroid_y: 40.678625995888005,
                    zone_label: 124,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.952572709479043, 40.674843690120071],
                            [-73.942516849124061, 40.674752019308187],
                            [-73.942395847083347, 40.68240814335438],
                            [-73.952452857560374, 40.682499838787358],
                            [-73.952572709479043, 40.674843690120071],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.94736411608659,
                    centroid_y: 40.686282127024043,
                    zone_label: 125,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.952452857560374, 40.682499838787358],
                            [-73.942395847083347, 40.68240814335438],
                            [-73.942274798533816, 40.690064256917211],
                            [-73.95233295957479, 40.690155976976818],
                            [-73.952452857560374, 40.682499838787358],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.947243619664448,
                    centroid_y: 40.69393824767922,
                    zone_label: 126,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.95233295957479, 40.690155976976818],
                            [-73.942274798533816, 40.690064256917211],
                            [-73.942153703455233, 40.697720359996119],
                            [-73.952213015502224, 40.697812104687941],
                            [-73.95233295957479, 40.690155976976818],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.9471230769242,
                    centroid_y: 40.70159435785299,
                    zone_label: 127,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.952213015502224, 40.697812104687941],
                            [-73.942153703455233, 40.697720359996119],
                            [-73.942032561827332, 40.705376452590563],
                            [-73.952093025322611, 40.705468221920171],
                            [-73.952213015502224, 40.697812104687941],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.94700248784568,
                    centroid_y: 40.709250457544833,
                    zone_label: 128,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.952093025322611, 40.705468221920171],
                            [-73.942032561827332, 40.705376452590563],
                            [-73.94191137362985, 40.713032534700012],
                            [-73.9519729890159, 40.713124328672976],
                            [-73.952093025322611, 40.705468221920171],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.946881852408737,
                    centroid_y: 40.716906546754196,
                    zone_label: 129,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.9519729890159, 40.713124328672976],
                            [-73.94191137362985, 40.713032534700012],
                            [-73.941790138842535, 40.720688606323897],
                            [-73.951852906561967, 40.720780424945808],
                            [-73.9519729890159, 40.713124328672976],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.946761170593177,
                    centroid_y: 40.724562625480523,
                    zone_label: 130,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.951852906561967, 40.720780424945808],
                            [-73.941790138842535, 40.720688606323897],
                            [-73.941668857445052, 40.728344667461727],
                            [-73.951732777940762, 40.728436510738142],
                            [-73.951852906561967, 40.720780424945808],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.946640442378794,
                    centroid_y: 40.732218693723304,
                    zone_label: 131,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.951732777940762, 40.728436510738142],
                            [-73.941668857445052, 40.728344667461727],
                            [-73.941547529417107, 40.736000718112912],
                            [-73.951612603132133, 40.736092586049431],
                            [-73.951732777940762, 40.728436510738142],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.946519667745349,
                    centroid_y: 40.739874751481999,
                    zone_label: 132,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.951612603132133, 40.736092586049431],
                            [-73.941547529417107, 40.736000718112912],
                            [-73.941426154738394, 40.743656758276956],
                            [-73.951492382115958, 40.743748650879155],
                            [-73.951612603132133, 40.736092586049431],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.946398846672622,
                    centroid_y: 40.747530798756038,
                    zone_label: 133,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.951492382115958, 40.743748650879155],
                            [-73.941426154738394, 40.743656758276956],
                            [-73.941304733388577, 40.751312787953282],
                            [-73.951372114872129, 40.751404705226754],
                            [-73.951492382115958, 40.743748650879155],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.946277979140405,
                    centroid_y: 40.755186835544919,
                    zone_label: 134,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.951372114872129, 40.751404705226754],
                            [-73.941304733388577, 40.751312787953282],
                            [-73.941183265347306, 40.758968807141379],
                            [-73.951251801380451, 40.759060749091717],
                            [-73.951372114872129, 40.751404705226754],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.945915097583963,
                    centroid_y: 40.778154882995196,
                    zone_label: 135,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.951011035572947, 40.774372805371556],
                            [-73.940940189108971, 40.774280814050698],
                            [-73.940818580871152, 40.781936801770868],
                            [-73.950890583216761, 40.782028817785374],
                            [-73.951011035572947, 40.774372805371556],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.945794044010981,
                    centroid_y: 40.785810877838045,
                    zone_label: 136,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.950890583216761, 40.782028817785374],
                            [-73.940818580871152, 40.781936801770868],
                            [-73.940696925860365, 40.789592779000657],
                            [-73.950770084531996, 40.789684819714424],
                            [-73.950890583216761, 40.782028817785374],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.945672943877071,
                    centroid_y: 40.793466862193057,
                    zone_label: 137,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.950770084531996, 40.789684819714424],
                            [-73.940696925860365, 40.789592779000657],
                            [-73.940575224056218, 40.797248745739516],
                            [-73.950649539498457, 40.797340811158158],
                            [-73.950770084531996, 40.789684819714424],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.945551797161954,
                    centroid_y: 40.801122836059697,
                    zone_label: 138,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.950649539498457, 40.797340811158158],
                            [-73.940575224056218, 40.797248745739516],
                            [-73.940453475438275, 40.804904701986942],
                            [-73.950528948095908, 40.804996792116043],
                            [-73.950649539498457, 40.797340811158158],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.945430603845239,
                    centroid_y: 40.808778799437427,
                    zone_label: 139,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.950528948095908, 40.804996792116043],
                            [-73.940453475438275, 40.804904701986942],
                            [-73.940331679986102, 40.812560647742373],
                            [-73.950408310304098, 40.812652762587568],
                            [-73.950528948095908, 40.804996792116043],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.945309363906631,
                    centroid_y: 40.816434752325726,
                    zone_label: 140,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.950408310304098, 40.812652762587568],
                            [-73.940331679986102, 40.812560647742373],
                            [-73.940209837679262, 40.820216583005305],
                            [-73.950287626102806, 40.820308722572186],
                            [-73.950408310304098, 40.812652762587568],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.945188077325753,
                    centroid_y: 40.824090694724042,
                    zone_label: 141,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.950287626102806, 40.820308722572186],
                            [-73.940209837679262, 40.820216583005305],
                            [-73.940087948497279, 40.827872507775183],
                            [-73.950166895471739, 40.827964672069363],
                            [-73.950287626102806, 40.820308722572186],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.945066744082226,
                    centroid_y: 40.831746626631876,
                    zone_label: 142,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.950166895471739, 40.827964672069363],
                            [-73.940087948497279, 40.827872507775183],
                            [-73.939966012419674, 40.835528422051475],
                            [-73.950046118390603, 40.83562061107861],
                            [-73.950166895471739, 40.827964672069363],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.944945364155657,
                    centroid_y: 40.839402548048682,
                    zone_label: 143,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.950046118390603, 40.83562061107861],
                            [-73.939966012419674, 40.835528422051475],
                            [-73.93984402942597, 40.843184325833661],
                            [-73.949925294839119, 40.843276539599358],
                            [-73.950046118390603, 40.83562061107861],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.944823937525655,
                    centroid_y: 40.847058458973919,
                    zone_label: 144,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.949925294839119, 40.843276539599358],
                            [-73.93984402942597, 40.843184325833661],
                            [-73.93972199949566, 40.85084021912121],
                            [-73.949804424797009, 40.850932457631082],
                            [-73.949925294839119, 40.843276539599358],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.938035455945723,
                    centroid_y: 40.640253185649762,
                    zone_label: 145,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.94312116240296, 40.636471241845577],
                            [-73.933071092424257, 40.636378819619161],
                            [-73.932949174729131, 40.644034971245944],
                            [-73.943000392603139, 40.644127418300492],
                            [-73.94312116240296, 40.636471241845577],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.937914088883531,
                    centroid_y: 40.647909344468466,
                    zone_label: 146,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.943000392603139, 40.644127418300492],
                            [-73.932949174729131, 40.644034971245944],
                            [-73.93282721018528, 40.65169111238648],
                            [-73.942879576395526, 40.651783584274767],
                            [-73.943000392603139, 40.644127418300492],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.937792675182948,
                    centroid_y: 40.65556549280344,
                    zone_label: 147,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.942879576395526, 40.651783584274767],
                            [-73.93282721018528, 40.65169111238648],
                            [-73.932705198772339, 40.659347243040209],
                            [-73.942758713759957, 40.659439739767834],
                            [-73.942879576395526, 40.651783584274767],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.937671214823695,
                    centroid_y: 40.663221630654128,
                    zone_label: 148,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.942758713759957, 40.659439739767834],
                            [-73.932705198772339, 40.659347243040209],
                            [-73.932583140469944, 40.667003363206582],
                            [-73.942637804676181, 40.667095884779151],
                            [-73.942758713759957, 40.659439739767834],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.937549707785479,
                    centroid_y: 40.670877758020019,
                    zone_label: 149,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.942637804676181, 40.667095884779151],
                            [-73.932583140469944, 40.667003363206582],
                            [-73.932461035257674, 40.674659472885047],
                            [-73.942516849124061, 40.674752019308187],
                            [-73.942637804676181, 40.667095884779151],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.937428154047993,
                    centroid_y: 40.678533874900531,
                    zone_label: 150,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.942516849124061, 40.674752019308187],
                            [-73.932461035257674, 40.674659472885047],
                            [-73.932338883115136, 40.682315572075062],
                            [-73.942395847083347, 40.68240814335438],
                            [-73.942516849124061, 40.674752019308187],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.937306553590886,
                    centroid_y: 40.686189981295144,
                    zone_label: 151,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.942395847083347, 40.68240814335438],
                            [-73.932338883115136, 40.682315572075062],
                            [-73.932216684021896, 40.689971660776081],
                            [-73.942274798533816, 40.690064256917211],
                            [-73.942395847083347, 40.68240814335438],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.937184906393853,
                    centroid_y: 40.693846077203304,
                    zone_label: 152,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.942274798533816, 40.690064256917211],
                            [-73.932216684021896, 40.689971660776081],
                            [-73.932094437957545, 40.69762773898757],
                            [-73.942153703455233, 40.697720359996119],
                            [-73.942274798533816, 40.690064256917211],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.937063212436584,
                    centroid_y: 40.701502162624458,
                    zone_label: 153,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.942153703455233, 40.697720359996119],
                            [-73.932094437957545, 40.69762773898757],
                            [-73.931972144901621, 40.705283806708962],
                            [-73.942032561827332, 40.705376452590563],
                            [-73.942153703455233, 40.697720359996119],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.936941471698702,
                    centroid_y: 40.709158237558093,
                    zone_label: 154,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.942032561827332, 40.705376452590563],
                            [-73.931972144901621, 40.705283806708962],
                            [-73.931849804833675, 40.712939863939731],
                            [-73.94191137362985, 40.713032534700012],
                            [-73.942032561827332, 40.705376452590563],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.936819684159786,
                    centroid_y: 40.716814302003627,
                    zone_label: 155,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.94191137362985, 40.713032534700012],
                            [-73.931849804833675, 40.712939863939731],
                            [-73.931727417733214, 40.720595910679322],
                            [-73.941790138842535, 40.720688606323897],
                            [-73.94191137362985, 40.713032534700012],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.936697849799529,
                    centroid_y: 40.724470355960527,
                    zone_label: 156,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.941790138842535, 40.720688606323897],
                            [-73.931727417733214, 40.720595910679322],
                            [-73.931604983579774, 40.728251946927202],
                            [-73.941668857445052, 40.728344667461727],
                            [-73.941790138842535, 40.720688606323897],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.936575968597509,
                    centroid_y: 40.732126399428275,
                    zone_label: 157,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.941668857445052, 40.728344667461727],
                            [-73.931604983579774, 40.728251946927202],
                            [-73.93148250235285, 40.73590797268281],
                            [-73.941547529417107, 40.736000718112912],
                            [-73.941668857445052, 40.728344667461727],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.936454040533292,
                    centroid_y: 40.739782432406308,
                    zone_label: 158,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.941547529417107, 40.736000718112912],
                            [-73.93148250235285, 40.73590797268281],
                            [-73.931359974031921, 40.74356398794562],
                            [-73.941426154738394, 40.743656758276956],
                            [-73.941547529417107, 40.736000718112912],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.936332065586456,
                    centroid_y: 40.747438454894102,
                    zone_label: 159,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.941426154738394, 40.743656758276956],
                            [-73.931359974031921, 40.74356398794562],
                            [-73.93123739859648, 40.751219992715107],
                            [-73.941304733388577, 40.751312787953282],
                            [-73.941426154738394, 40.743656758276956],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.936210043736622,
                    centroid_y: 40.755094466891101,
                    zone_label: 160,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.941304733388577, 40.751312787953282],
                            [-73.93123739859648, 40.751219992715107],
                            [-73.931114776025964, 40.758875986990695],
                            [-73.941183265347306, 40.758968807141379],
                            [-73.941304733388577, 40.751312787953282],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.936087974963272,
                    centroid_y: 40.762750468396781,
                    zone_label: 161,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.941183265347306, 40.758968807141379],
                            [-73.931114776025964, 40.758875986990695],
                            [-73.930992106299854, 40.766531970771865],
                            [-73.94106175059423, 40.766624815840686],
                            [-73.941183265347306, 40.758968807141379],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.935599230225691,
                    centroid_y: 40.793374369495531,
                    zone_label: 162,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.940696925860365, 40.789592779000657],
                            [-73.930623813982109, 40.789499859143461],
                            [-73.930500955427775, 40.79715580094156],
                            [-73.940575224056218, 40.797248745739516],
                            [-73.940696925860365, 40.789592779000657],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.93547692652777,
                    centroid_y: 40.801030318536533,
                    zone_label: 163,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.940575224056218, 40.797248745739516],
                            [-73.930500955427775, 40.79715580094156],
                            [-73.930378049614873, 40.804811732242548],
                            [-73.940453475438275, 40.804904701986942],
                            [-73.940575224056218, 40.797248745739516],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.935354575783393,
                    centroid_y: 40.808686257082996,
                    zone_label: 164,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.940453475438275, 40.804904701986942],
                            [-73.930378049614873, 40.804811732242548],
                            [-73.930255096522771, 40.812467653045886],
                            [-73.940331679986102, 40.812560647742373],
                            [-73.940453475438275, 40.804904701986942],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.93523217797204,
                    centroid_y: 40.816342185134367,
                    zone_label: 165,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.940331679986102, 40.812560647742373],
                            [-73.930255096522771, 40.812467653045886],
                            [-73.930132096130848, 40.820123563351054],
                            [-73.940209837679262, 40.820216583005305],
                            [-73.940331679986102, 40.812560647742373],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.935109733073148,
                    centroid_y: 40.823998102690148,
                    zone_label: 166,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.940209837679262, 40.820216583005305],
                            [-73.930132096130848, 40.820123563351054],
                            [-73.930009048418427, 40.827779463157498],
                            [-73.940087948497279, 40.827872507775183],
                            [-73.940209837679262, 40.820216583005305],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.934864701930479,
                    centroid_y: 40.839309906312728,
                    zone_label: 167,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.939966012419674, 40.835528422051475],
                            [-73.929885953364845, 40.835435352464692],
                            [-73.929762810949413, 40.843091231272098],
                            [-73.93984402942597, 40.843184325833661],
                            [-73.939966012419674, 40.835528422051475],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.934742115645534,
                    centroid_y: 40.846965792378469,
                    zone_label: 168,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.93984402942597, 40.843184325833661],
                            [-73.929762810949413, 40.843091231272098],
                            [-73.929639621151438, 40.850747099579188],
                            [-73.93972199949566, 40.85084021912121],
                            [-73.93984402942597, 40.843184325833661],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.934619482190712,
                    centroid_y: 40.854621667946482,
                    zone_label: 169,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.93972199949566, 40.85084021912121],
                            [-73.929639621151438, 40.850747099579188],
                            [-73.929516383950244, 40.858402957385429],
                            [-73.939599922608238, 40.858496101913595],
                            [-73.93972199949566, 40.85084021912121],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.934496801545393,
                    centroid_y: 40.862277533016226,
                    zone_label: 170,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.939599922608238, 40.858496101913595],
                            [-73.929516383950244, 40.858402957385429],
                            [-73.92939309932504, 40.86605880469029],
                            [-73.939477798743184, 40.866151974210283],
                            [-73.939599922608238, 40.858496101913595],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927984835442501,
                    centroid_y: 40.640160313737546,
                    zone_label: 171,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.933071092424257, 40.636378819619161],
                            [-73.923021069273929, 40.636285522967981],
                            [-73.922898003703821, 40.643941649531804],
                            [-73.932949174729131, 40.644034971245944],
                            [-73.933071092424257, 40.636378819619161],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927862320274699,
                    centroid_y: 40.64781644760788,
                    zone_label: 172,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.932949174729131, 40.644034971245944],
                            [-73.922898003703821, 40.643941649531804],
                            [-73.922774890844096, 40.651597765603718],
                            [-73.93282721018528, 40.65169111238648],
                            [-73.932949174729131, 40.644034971245944],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927739758027485,
                    centroid_y: 40.65547257098887,
                    zone_label: 173,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.93282721018528, 40.65169111238648],
                            [-73.922774890844096, 40.651597765603718],
                            [-73.922651730674204, 40.659253871183154],
                            [-73.932705198772339, 40.659347243040209],
                            [-73.93282721018528, 40.65169111238648],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927617148680426,
                    centroid_y: 40.663128683879926,
                    zone_label: 174,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.932705198772339, 40.659347243040209],
                            [-73.922651730674204, 40.659253871183154],
                            [-73.922528523173554, 40.666909966269586],
                            [-73.932583140469944, 40.667003363206582],
                            [-73.932705198772339, 40.659347243040209],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927494492213,
                    centroid_y: 40.670784786280549,
                    zone_label: 175,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.932583140469944, 40.667003363206582],
                            [-73.922528523173554, 40.666909966269586],
                            [-73.92240526832154, 40.674566050862438],
                            [-73.932461035257674, 40.674659472885047],
                            [-73.932583140469944, 40.667003363206582],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927371788604759,
                    centroid_y: 40.678440878190145,
                    zone_label: 176,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.932461035257674, 40.674659472885047],
                            [-73.92240526832154, 40.674566050862438],
                            [-73.922281966097614, 40.682222124961172],
                            [-73.932338883115136, 40.682315572075062],
                            [-73.932461035257674, 40.674659472885047],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927249037835097,
                    centroid_y: 40.686096959608214,
                    zone_label: 177,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.932338883115136, 40.682315572075062],
                            [-73.922281966097614, 40.682222124961172],
                            [-73.922158616481113, 40.689878188565238],
                            [-73.932216684021896, 40.689971660776081],
                            [-73.932338883115136, 40.682315572075062],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927126239883563,
                    centroid_y: 40.693753030534147,
                    zone_label: 178,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.932216684021896, 40.689971660776081],
                            [-73.922158616481113, 40.689878188565238],
                            [-73.922035219451445, 40.697534241674084],
                            [-73.932094437957545, 40.69762773898757],
                            [-73.932216684021896, 40.689971660776081],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.927003394729596,
                    centroid_y: 40.701409090967466,
                    zone_label: 179,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.932094437957545, 40.69762773898757],
                            [-73.922035219451445, 40.697534241674084],
                            [-73.921911774987962, 40.705190284287177],
                            [-73.931972144901621, 40.705283806708962],
                            [-73.932094437957545, 40.69762773898757],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.926880502352688,
                    centroid_y: 40.709065140907576,
                    zone_label: 180,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.931972144901621, 40.705283806708962],
                            [-73.921911774987962, 40.705190284287177],
                            [-73.921788283070001, 40.712846316403954],
                            [-73.931849804833675, 40.712939863939731],
                            [-73.931972144901621, 40.705283806708962],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.926757562732234,
                    centroid_y: 40.71672118035395,
                    zone_label: 181,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.931849804833675, 40.712939863939731],
                            [-73.921788283070001, 40.712846316403954],
                            [-73.921664743676899, 40.720502338023877],
                            [-73.931727417733214, 40.720595910679322],
                            [-73.931849804833675, 40.712939863939731],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.92651154167838,
                    centroid_y: 40.732033227763296,
                    zone_label: 182,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.931604983579774, 40.728251946927202],
                            [-73.921541156787995, 40.728158349146391],
                            [-73.921417522382583, 40.735814349770955],
                            [-73.93148250235285, 40.73590797268281],
                            [-73.931604983579774, 40.728251946927202],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.926388460203825,
                    centroid_y: 40.739689235725194,
                    zone_label: 183,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.93148250235285, 40.73590797268281],
                            [-73.921417522382583, 40.735814349770955],
                            [-73.921293840439972, 40.743470339897037],
                            [-73.931359974031921, 40.74356398794562],
                            [-73.93148250235285, 40.73590797268281],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.926265331403343,
                    centroid_y: 40.747345233191176,
                    zone_label: 184,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.931359974031921, 40.74356398794562],
                            [-73.921293840439972, 40.743470339897037],
                            [-73.921170110939428, 40.751126319524069],
                            [-73.93123739859648, 40.751219992715107],
                            [-73.931359974031921, 40.74356398794562],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.926142155256329,
                    centroid_y: 40.7550012201607,
                    zone_label: 185,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.93123739859648, 40.751219992715107],
                            [-73.921170110939428, 40.751126319524069],
                            [-73.921046333860232, 40.758782288651531],
                            [-73.931114776025964, 40.758875986990695],
                            [-73.93123739859648, 40.751219992715107],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.926018931742121,
                    centroid_y: 40.762657196633228,
                    zone_label: 186,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.931114776025964, 40.758875986990695],
                            [-73.921046333860232, 40.758782288651531],
                            [-73.920922509181622, 40.766438247278863],
                            [-73.930992106299854, 40.766531970771865],
                            [-73.931114776025964, 40.758875986990695],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.925895660840084,
                    centroid_y: 40.770313162608204,
                    zone_label: 187,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930992106299854, 40.766531970771865],
                            [-73.920922509181622, 40.766438247278863],
                            [-73.920798636882878, 40.774094195405524],
                            [-73.930869389397543, 40.77418794405807],
                            [-73.930992106299854, 40.766531970771865],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.925772342529527,
                    centroid_y: 40.777969118085117,
                    zone_label: 188,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930869389397543, 40.77418794405807],
                            [-73.920798636882878, 40.774094195405524],
                            [-73.920674716943196, 40.781750133030997],
                            [-73.930746625298497, 40.781843906848785],
                            [-73.930869389397543, 40.77418794405807],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.925648976789802,
                    centroid_y: 40.785625063063407,
                    zone_label: 189,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930746625298497, 40.781843906848785],
                            [-73.920674716943196, 40.781750133030997],
                            [-73.9205507493418, 40.789406060154718],
                            [-73.930623813982109, 40.789499859143461],
                            [-73.930746625298497, 40.781843906848785],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.925525563600203,
                    centroid_y: 40.793280997542546,
                    zone_label: 190,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930623813982109, 40.789499859143461],
                            [-73.9205507493418, 40.789406060154718],
                            [-73.920426734057884, 40.797061976776156],
                            [-73.930500955427775, 40.79715580094156],
                            [-73.930623813982109, 40.789499859143461],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.925402102939998,
                    centroid_y: 40.800936921521981,
                    zone_label: 191,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930500955427775, 40.79715580094156],
                            [-73.920426734057884, 40.797061976776156],
                            [-73.920302671070644, 40.804717882894764],
                            [-73.930378049614873, 40.804811732242548],
                            [-73.930500955427775, 40.79715580094156],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.925278594788523,
                    centroid_y: 40.808592835001193,
                    zone_label: 192,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930378049614873, 40.804811732242548],
                            [-73.920302671070644, 40.804717882894764],
                            [-73.92017856035929, 40.812373778509993],
                            [-73.930255096522771, 40.812467653045886],
                            [-73.930378049614873, 40.804811732242548],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.925155039124974,
                    centroid_y: 40.816248737979627,
                    zone_label: 193,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930255096522771, 40.812467653045886],
                            [-73.92017856035929, 40.812373778509993],
                            [-73.920054401902917, 40.820029663621341],
                            [-73.930132096130848, 40.820123563351054],
                            [-73.930255096522771, 40.812467653045886],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.925031435928659,
                    centroid_y: 40.823904630456774,
                    zone_label: 194,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930132096130848, 40.820123563351054],
                            [-73.920054401902917, 40.820029663621341],
                            [-73.919930195680735, 40.827685538228238],
                            [-73.930009048418427, 40.827779463157498],
                            [-73.930132096130848, 40.820123563351054],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.924907785178803,
                    centroid_y: 40.831560512432063,
                    zone_label: 195,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.930009048418427, 40.827779463157498],
                            [-73.919930195680735, 40.827685538228238],
                            [-73.919805941671854, 40.835341402330165],
                            [-73.929885953364845, 40.835435352464692],
                            [-73.930009048418427, 40.827779463157498],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.924784086854586,
                    centroid_y: 40.839216383904976,
                    zone_label: 196,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.929885953364845, 40.835435352464692],
                            [-73.919805941671854, 40.835341402330165],
                            [-73.919681639855398, 40.842997255926569],
                            [-73.929762810949413, 40.843091231272098],
                            [-73.929885953364845, 40.835435352464692],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.924660340935318,
                    centroid_y: 40.84687224487498,
                    zone_label: 197,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.929762810949413, 40.843091231272098],
                            [-73.919681639855398, 40.842997255926569],
                            [-73.919557290210477, 40.850653099016924],
                            [-73.929639621151438, 40.850747099579188],
                            [-73.929762810949413, 40.843091231272098],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.924536547400095,
                    centroid_y: 40.854528095341536,
                    zone_label: 198,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.929639621151438, 40.850747099579188],
                            [-73.919557290210477, 40.850653099016924],
                            [-73.919432892716188, 40.858308931600696],
                            [-73.929516383950244, 40.858402957385429],
                            [-73.929639621151438, 40.850747099579188],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.924412706228182,
                    centroid_y: 40.86218393530411,
                    zone_label: 199,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.929516383950244, 40.858402957385429],
                            [-73.919432892716188, 40.858308931600696],
                            [-73.919308447351625, 40.865964753677353],
                            [-73.92939309932504, 40.86605880469029],
                            [-73.929516383950244, 40.858402957385429],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.917934261998184,
                    centroid_y: 40.640066567289082,
                    zone_label: 200,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.923021069273929, 40.636285522967981],
                            [-73.912971093392656, 40.636191351903932],
                            [-73.912846879968143, 40.643847453169961],
                            [-73.922898003703821, 40.643941649531804],
                            [-73.923021069273929, 40.636285522967981],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.917810598745262,
                    centroid_y: 40.647722675976205,
                    zone_label: 201,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.922898003703821, 40.643941649531804],
                            [-73.912846879968143, 40.643847453169961],
                            [-73.91272261881312, 40.651503543938368],
                            [-73.922774890844096, 40.651597765603718],
                            [-73.922898003703821, 40.643941649531804],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.917686887971897,
                    centroid_y: 40.655378774168277,
                    zone_label: 202,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.922774890844096, 40.651597765603718],
                            [-73.91272261881312, 40.651503543938368],
                            [-73.912598309906826, 40.659159624208584],
                            [-73.922651730674204, 40.659253871183154],
                            [-73.922774890844096, 40.651597765603718],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.917563129657538,
                    centroid_y: 40.663034861864752,
                    zone_label: 203,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.922651730674204, 40.659253871183154],
                            [-73.912598309906826, 40.659159624208584],
                            [-73.912473953228499, 40.666815693980077],
                            [-73.922528523173554, 40.666909966269586],
                            [-73.922651730674204, 40.659253871183154],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.917439323781423,
                    centroid_y: 40.670690939065082,
                    zone_label: 204,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.922528523173554, 40.666909966269586],
                            [-73.912473953228499, 40.666815693980077],
                            [-73.912349548757362, 40.67447175325227],
                            [-73.92240526832154, 40.674566050862438],
                            [-73.922528523173554, 40.666909966269586],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.917315470322862,
                    centroid_y: 40.678347005768686,
                    zone_label: 205,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.92240526832154, 40.674566050862438],
                            [-73.912349548757362, 40.67447175325227],
                            [-73.912225096472639, 40.682127802024617],
                            [-73.922281966097614, 40.682222124961172],
                            [-73.92240526832154, 40.674566050862438],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.917191569261178,
                    centroid_y: 40.686003061975072,
                    zone_label: 206,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.922281966097614, 40.682222124961172],
                            [-73.912225096472639, 40.682127802024617],
                            [-73.912100596353497, 40.689783840296585],
                            [-73.922158616481113, 40.689878188565238],
                            [-73.922281966097614, 40.682222124961172],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.917067620575665,
                    centroid_y: 40.693659107683644,
                    zone_label: 207,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.922158616481113, 40.689878188565238],
                            [-73.912100596353497, 40.689783840296585],
                            [-73.911976048379145, 40.697439868067619],
                            [-73.922035219451445, 40.697534241674084],
                            [-73.922158616481113, 40.689878188565238],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.916943624245604,
                    centroid_y: 40.701315142893854,
                    zone_label: 208,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.922035219451445, 40.697534241674084],
                            [-73.911976048379145, 40.697439868067619],
                            [-73.911851452528751, 40.705095885337137],
                            [-73.921911774987962, 40.705190284287177],
                            [-73.922035219451445, 40.697534241674084],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.916819580250205,
                    centroid_y: 40.708971167605192,
                    zone_label: 209,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.921911774987962, 40.705190284287177],
                            [-73.911851452528751, 40.705095885337137],
                            [-73.911726808781438, 40.712751892104613],
                            [-73.921788283070001, 40.712846316403954],
                            [-73.921911774987962, 40.705190284287177],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.916695488568763,
                    centroid_y: 40.716627181817067,
                    zone_label: 210,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.921788283070001, 40.712846316403954],
                            [-73.911726808781438, 40.712751892104613],
                            [-73.911602117116388, 40.7204078883695],
                            [-73.921664743676899, 40.720502338023877],
                            [-73.921788283070001, 40.712846316403954],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.916571349180472,
                    centroid_y: 40.72428318552894,
                    zone_label: 211,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.921664743676899, 40.720502338023877],
                            [-73.911602117116388, 40.7204078883695],
                            [-73.911477377512711, 40.728063874131244],
                            [-73.921541156787995, 40.728158349146391],
                            [-73.921664743676899, 40.720502338023877],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.916447162064586,
                    centroid_y: 40.731939178740269,
                    zone_label: 212,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.921541156787995, 40.728158349146391],
                            [-73.911477377512711, 40.728063874131244],
                            [-73.91135258994953, 40.735719849389298],
                            [-73.921417522382583, 40.735814349770955],
                            [-73.921541156787995, 40.728158349146391],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.916322927200284,
                    centroid_y: 40.739595161450538,
                    zone_label: 213,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.921417522382583, 40.735814349770955],
                            [-73.91135258994953, 40.735719849389298],
                            [-73.911227754405942, 40.743375814143114],
                            [-73.921293840439972, 40.743470339897037],
                            [-73.921417522382583, 40.735814349770955],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.916198644566762,
                    centroid_y: 40.747251133659141,
                    zone_label: 214,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.921293840439972, 40.743470339897037],
                            [-73.911227754405942, 40.743375814143114],
                            [-73.911102870861043, 40.751031768392139],
                            [-73.921170110939428, 40.751126319524069],
                            [-73.921293840439972, 40.743470339897037],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.916074314143231,
                    centroid_y: 40.754907095365589,
                    zone_label: 215,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.921170110939428, 40.751126319524069],
                            [-73.911102870861043, 40.751031768392139],
                            [-73.9109779392939, 40.758687712135838],
                            [-73.921046333860232, 40.758782288651531],
                            [-73.921170110939428, 40.751126319524069],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.915949935908799,
                    centroid_y: 40.762563046569298,
                    zone_label: 216,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.921046333860232, 40.758782288651531],
                            [-73.9109779392939, 40.758687712135838],
                            [-73.910852959683581, 40.766343645373659],
                            [-73.920922509181622, 40.766438247278863],
                            [-73.921046333860232, 40.758782288651531],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.915825509842662,
                    centroid_y: 40.770218987269743,
                    zone_label: 217,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.920922509181622, 40.766438247278863],
                            [-73.910852959683581, 40.766343645373659],
                            [-73.910727932009124, 40.773999568105054],
                            [-73.920798636882878, 40.774094195405524],
                            [-73.920922509181622, 40.766438247278863],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.915701035923973,
                    centroid_y: 40.777874917466377,
                    zone_label: 218,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.920798636882878, 40.774094195405524],
                            [-73.910727932009124, 40.773999568105054],
                            [-73.910602856249596, 40.781655480329484],
                            [-73.920674716943196, 40.781750133030997],
                            [-73.920798636882878, 40.774094195405524],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.9155765141318,
                    centroid_y: 40.785530837158667,
                    zone_label: 219,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.920674716943196, 40.781750133030997],
                            [-73.910602856249596, 40.781655480329484],
                            [-73.910477732383981, 40.789311382046421],
                            [-73.9205507493418, 40.789406060154718],
                            [-73.920674716943196, 40.781750133030997],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.915451944445309,
                    centroid_y: 40.793186746346052,
                    zone_label: 220,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.9205507493418, 40.789406060154718],
                            [-73.910477732383981, 40.789311382046421],
                            [-73.910352560391303, 40.796967273255284],
                            [-73.920426734057884, 40.797061976776156],
                            [-73.9205507493418, 40.789406060154718],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.915327326843553,
                    centroid_y: 40.80084264502802,
                    zone_label: 221,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.920426734057884, 40.797061976776156],
                            [-73.910352560391303, 40.796967273255284],
                            [-73.910227340250572, 40.804623153955561],
                            [-73.920302671070644, 40.804717882894764],
                            [-73.920426734057884, 40.797061976776156],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.915202661305671,
                    centroid_y: 40.808498533203995,
                    zone_label: 222,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.920302671070644, 40.804717882894764],
                            [-73.910227340250572, 40.804623153955561],
                            [-73.910102071940756, 40.812279024146704],
                            [-73.92017856035929, 40.812373778509993],
                            [-73.920302671070644, 40.804717882894764],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.915077947810687,
                    centroid_y: 40.816154410873459,
                    zone_label: 223,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.92017856035929, 40.812373778509993],
                            [-73.910102071940756, 40.812279024146704],
                            [-73.909976755440823, 40.819934883828168],
                            [-73.920054401902917, 40.820029663621341],
                            [-73.92017856035929, 40.812373778509993],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.914953186337684,
                    centroid_y: 40.823810278035872,
                    zone_label: 224,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.920054401902917, 40.820029663621341],
                            [-73.909976755440823, 40.819934883828168],
                            [-73.909851390729742, 40.827590732999411],
                            [-73.919930195680735, 40.827685538228238],
                            [-73.920054401902917, 40.820029663621341],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.914828376865728,
                    centroid_y: 40.831466134690686,
                    zone_label: 225,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.919930195680735, 40.827685538228238],
                            [-73.909851390729742, 40.827590732999411],
                            [-73.909725977786422, 40.835246571659894],
                            [-73.919805941671854, 40.835341402330165],
                            [-73.919930195680735, 40.827685538228238],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.914703519373816,
                    centroid_y: 40.839121980837362,
                    zone_label: 226,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.919805941671854, 40.835341402330165],
                            [-73.909725977786422, 40.835246571659894],
                            [-73.909600516589833, 40.842902399809084],
                            [-73.919681639855398, 40.842997255926569],
                            [-73.919805941671854, 40.835341402330165],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.914578613840959,
                    centroid_y: 40.846777816475381,
                    zone_label: 227,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.919681639855398, 40.842997255926569],
                            [-73.909600516589833, 40.842902399809084],
                            [-73.909475007118857, 40.850558217446448],
                            [-73.919557290210477, 40.850653099016924],
                            [-73.919681639855398, 40.842997255926569],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.914453660246195,
                    centroid_y: 40.854433641604182,
                    zone_label: 228,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.919557290210477, 40.850653099016924],
                            [-73.909475007118857, 40.850558217446448],
                            [-73.909349449352433, 40.858214024571424],
                            [-73.919432892716188, 40.858308931600696],
                            [-73.919557290210477, 40.850653099016924],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.914328658568522,
                    centroid_y: 40.862089456223252,
                    zone_label: 229,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.919432892716188, 40.858308931600696],
                            [-73.909349449352433, 40.858214024571424],
                            [-73.909223843269402, 40.865869821183502],
                            [-73.919308447351625, 40.865964753677353],
                            [-73.919432892716188, 40.858308931600696],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.907883736053648,
                    centroid_y: 40.639971946316336,
                    zone_label: 230,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.912971093392656, 40.636191351903932],
                            [-73.902921165221187, 40.636096306438979],
                            [-73.902795803962988, 40.643752382172387],
                            [-73.912846879968143, 40.643847453169961],
                            [-73.912971093392656, 40.636191351903932],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.907758924736214,
                    centroid_y: 40.64762802958537,
                    zone_label: 231,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.912846879968143, 40.643847453169961],
                            [-73.902795803962988, 40.643752382172387],
                            [-73.902670394533388, 40.651408447402424],
                            [-73.91272261881312, 40.651503543938368],
                            [-73.912846879968143, 40.643847453169961],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.907634065457415,
                    centroid_y: 40.655284102353633,
                    zone_label: 232,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.91272261881312, 40.651503543938368],
                            [-73.902670394533388, 40.651408447402424],
                            [-73.902544936911468, 40.659064502128487],
                            [-73.912598309906826, 40.659159624208584],
                            [-73.91272261881312, 40.651503543938368],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.907509158196348,
                    centroid_y: 40.662940164620544,
                    zone_label: 233,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.912598309906826, 40.659159624208584],
                            [-73.902544936911468, 40.659064502128487],
                            [-73.90241943107624, 40.666720546350071],
                            [-73.912473953228499, 40.666815693980077],
                            [-73.912598309906826, 40.659159624208584],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.907384202932207,
                    centroid_y: 40.670596216385569,
                    zone_label: 234,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.912473953228499, 40.666815693980077],
                            [-73.90241943107624, 40.666720546350071],
                            [-73.90229387700677, 40.674376580066571],
                            [-73.912349548757362, 40.67447175325227],
                            [-73.912473953228499, 40.666815693980077],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.907259199644102,
                    centroid_y: 40.678252257648133,
                    zone_label: 235,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.912349548757362, 40.67447175325227],
                            [-73.90229387700677, 40.674376580066571],
                            [-73.902168274682055, 40.682032603277463],
                            [-73.912225096472639, 40.682127802024617],
                            [-73.912349548757362, 40.67447175325227],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.907134148311115,
                    centroid_y: 40.685908288407703,
                    zone_label: 236,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.912225096472639, 40.682127802024617],
                            [-73.902168274682055, 40.682032603277463],
                            [-73.902042624081105, 40.68968861598217],
                            [-73.912100596353497, 40.689783840296585],
                            [-73.912225096472639, 40.682127802024617],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.907009048912357,
                    centroid_y: 40.693564308663738,
                    zone_label: 237,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.912100596353497, 40.689783840296585],
                            [-73.902042624081105, 40.68968861598217],
                            [-73.901916925182903, 40.697344618180175],
                            [-73.911976048379145, 40.697439868067619],
                            [-73.912100596353497, 40.689783840296585],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.90688390142688,
                    centroid_y: 40.701220318415658,
                    zone_label: 238,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.911976048379145, 40.697439868067619],
                            [-73.901916925182903, 40.697344618180175],
                            [-73.90179117796643, 40.705000609870886],
                            [-73.911851452528751, 40.705095885337137],
                            [-73.911976048379145, 40.697439868067619],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.906758705833781,
                    centroid_y: 40.708876317662906,
                    zone_label: 239,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.911851452528751, 40.705095885337137],
                            [-73.90179117796643, 40.705000609870886],
                            [-73.901665382410641, 40.712656591053765],
                            [-73.911726808781438, 40.712751892104613],
                            [-73.911851452528751, 40.705095885337137],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.906633462112126,
                    centroid_y: 40.716532306404957,
                    zone_label: 240,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.911726808781438, 40.712751892104613],
                            [-73.901665382410641, 40.712656591053765],
                            [-73.901539538494504, 40.720312561728264],
                            [-73.911602117116388, 40.7204078883695],
                            [-73.911726808781438, 40.712751892104613],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.906508170240912,
                    centroid_y: 40.724188284641237,
                    zone_label: 241,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.911602117116388, 40.7204078883695],
                            [-73.901539538494504, 40.720312561728264],
                            [-73.901413646196943, 40.727968521893828],
                            [-73.911477377512711, 40.728063874131244],
                            [-73.911602117116388, 40.7204078883695],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.906382830199192,
                    centroid_y: 40.731844252371218,
                    zone_label: 242,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.911477377512711, 40.728063874131244],
                            [-73.901413646196943, 40.727968521893828],
                            [-73.901287705496884, 40.73562447154989],
                            [-73.91135258994953, 40.735719849389298],
                            [-73.911477377512711, 40.728063874131244],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.906257441965963,
                    centroid_y: 40.739500209594333,
                    zone_label: 243,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.91135258994953, 40.735719849389298],
                            [-73.901287705496884, 40.73562447154989],
                            [-73.901161716373238, 40.743280410695945],
                            [-73.911227754405942, 40.743375814143114],
                            [-73.91135258994953, 40.735719849389298],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.906132005520234,
                    centroid_y: 40.747156156310041,
                    zone_label: 244,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.911227754405942, 40.743375814143114],
                            [-73.901161716373238, 40.743280410695945],
                            [-73.901035678804888, 40.750936339331389],
                            [-73.911102870861043, 40.751031768392139],
                            [-73.911227754405942, 40.743375814143114],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.90600652084099,
                    centroid_y: 40.754812092517803,
                    zone_label: 245,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.911102870861043, 40.751031768392139],
                            [-73.901035678804888, 40.750936339331389],
                            [-73.900909592770745, 40.75859225745571],
                            [-73.9109779392939, 40.758687712135838],
                            [-73.911102870861043, 40.751031768392139],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.90588098790721,
                    centroid_y: 40.762468018217049,
                    zone_label: 246,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.9109779392939, 40.758687712135838],
                            [-73.900909592770745, 40.75859225745571],
                            [-73.900783458249649, 40.766248165068326],
                            [-73.910852959683581, 40.766343645373659],
                            [-73.9109779392939, 40.758687712135838],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.90575540669785,
                    centroid_y: 40.770123933407241,
                    zone_label: 247,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.910852959683581, 40.766343645373659],
                            [-73.900783458249649, 40.766248165068326],
                            [-73.900657275220482, 40.773904062168732],
                            [-73.910727932009124, 40.773999568105054],
                            [-73.910852959683581, 40.766343645373659],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.905629777191834,
                    centroid_y: 40.777779838087845,
                    zone_label: 248,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.910727932009124, 40.773999568105054],
                            [-73.900657275220482, 40.773904062168732],
                            [-73.90053104366207, 40.781559948756346],
                            [-73.910602856249596, 40.781655480329484],
                            [-73.910727932009124, 40.773999568105054],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.905504099368144,
                    centroid_y: 40.785435732258314,
                    zone_label: 249,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.910602856249596, 40.781655480329484],
                            [-73.90053104366207, 40.781559948756346],
                            [-73.90040476355324, 40.789215824830649],
                            [-73.910477732383981, 40.789311382046421],
                            [-73.910602856249596, 40.781655480329484],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.905126775779905,
                    centroid_y: 40.808403351703433,
                    zone_label: 250,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.910227340250572, 40.804623153955561],
                            [-73.900152057599584, 40.804527545437068],
                            [-73.900025631712353, 40.812183389968119],
                            [-73.910102071940756, 40.812279024146704],
                            [-73.910227340250572, 40.804623153955561],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.905000904474448,
                    centroid_y: 40.816059203827898,
                    zone_label: 251,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.910102071940756, 40.812279024146704],
                            [-73.900025631712353, 40.812183389968119],
                            [-73.899899157189893, 40.819839223983664],
                            [-73.909976755440823, 40.819934883828168],
                            [-73.910102071940756, 40.812279024146704],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.904874984745717,
                    centroid_y: 40.823715045439506,
                    zone_label: 252,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.909976755440823, 40.819934883828168],
                            [-73.899899157189893, 40.819839223983664],
                            [-73.899772634010958, 40.82749504748314],
                            [-73.909851390729742, 40.827590732999411],
                            [-73.909976755440823, 40.819934883828168],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.904749016572595,
                    centroid_y: 40.831370876537704,
                    zone_label: 253,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.909851390729742, 40.827590732999411],
                            [-73.899772634010958, 40.82749504748314],
                            [-73.899646062154289, 40.835150860466044],
                            [-73.909725977786422, 40.835246571659894],
                            [-73.909851390729742, 40.827590732999411],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.904622999933935,
                    centroid_y: 40.839026697121973,
                    zone_label: 254,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.909725977786422, 40.835246571659894],
                            [-73.899646062154289, 40.835150860466044],
                            [-73.899519441598642, 40.842806662931807],
                            [-73.909600516589833, 40.842902399809084],
                            [-73.909725977786422, 40.835246571659894],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.904496934808549,
                    centroid_y: 40.846682507191765,
                    zone_label: 255,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.909600516589833, 40.842902399809084],
                            [-73.899519441598642, 40.842806662931807],
                            [-73.899392772322727, 40.850462454879896],
                            [-73.909475007118857, 40.850558217446448],
                            [-73.909600516589833, 40.842902399809084],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.90437082117522,
                    centroid_y: 40.85433830674652,
                    zone_label: 256,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.909475007118857, 40.850558217446448],
                            [-73.899392772322727, 40.850462454879896],
                            [-73.899266054305258, 40.858118236309764],
                            [-73.909349449352433, 40.858214024571424],
                            [-73.909475007118857, 40.850558217446448],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.904244659012804,
                    centroid_y: 40.861994095785732,
                    zone_label: 257,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.909349449352433, 40.858214024571424],
                            [-73.899266054305258, 40.858118236309764],
                            [-73.899139287524903, 40.865774007220892],
                            [-73.909223843269402, 40.865869821183502],
                            [-73.909349449352433, 40.858214024571424],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.897833258049658,
                    centroid_y: 40.639876450831331,
                    zone_label: 258,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.902921165221187, 40.636096306438979],
                            [-73.892871285200144, 40.636000386585259],
                            [-73.892744776129206, 40.643656436551233],
                            [-73.902795803962988, 40.643752382172387],
                            [-73.902921165221187, 40.636096306438979],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.89770729868853,
                    centroid_y: 40.647532508447426,
                    zone_label: 259,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.902795803962988, 40.643752382172387],
                            [-73.892744776129206, 40.643656436551233],
                            [-73.892618218445989, 40.651312476007995],
                            [-73.902670394533388, 40.651408447402424],
                            [-73.902795803962988, 40.643752382172387],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.897581290925103,
                    centroid_y: 40.655188555556983,
                    zone_label: 260,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.902670394533388, 40.651408447402424],
                            [-73.892618218445989, 40.651312476007995],
                            [-73.89249161212939, 40.658968504954998],
                            [-73.902544936911468, 40.659064502128487],
                            [-73.902670394533388, 40.651408447402424],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.89745523473826,
                    centroid_y: 40.662844592159388,
                    zone_label: 261,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.902544936911468, 40.659064502128487],
                            [-73.89249161212939, 40.658968504954998],
                            [-73.89236495715825, 40.666624523391675],
                            [-73.90241943107624, 40.666720546350071],
                            [-73.902544936911468, 40.659064502128487],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.897329130106996,
                    centroid_y: 40.670500618254103,
                    zone_label: 262,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.90241943107624, 40.666720546350071],
                            [-73.89236495715825, 40.666624523391675],
                            [-73.892238253511422, 40.674280531317457],
                            [-73.90229387700677, 40.674376580066571],
                            [-73.90241943107624, 40.666720546350071],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.89720297701021,
                    centroid_y: 40.678156633840565,
                    zone_label: 263,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.90229387700677, 40.674376580066571],
                            [-73.892238253511422, 40.674280531317457],
                            [-73.892111501167719, 40.681936528731782],
                            [-73.902168274682055, 40.682032603277463],
                            [-73.90229387700677, 40.674376580066571],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.897076775426811,
                    centroid_y: 40.685812638918229,
                    zone_label: 264,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.902168274682055, 40.682032603277463],
                            [-73.892111501167719, 40.681936528731782],
                            [-73.891984700105951, 40.689592515634132],
                            [-73.902042624081105, 40.68968861598217],
                            [-73.902168274682055, 40.682032603277463],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.896950525335711,
                    centroid_y: 40.693468633486525,
                    zone_label: 265,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.902042624081105, 40.68968861598217],
                            [-73.891984700105951, 40.689592515634132],
                            [-73.891857850304916, 40.697248492023903],
                            [-73.901916925182903, 40.697344618180175],
                            [-73.902042624081105, 40.68968861598217],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.896824226715793,
                    centroid_y: 40.70112461754492,
                    zone_label: 266,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.901916925182903, 40.697344618180175],
                            [-73.891857850304916, 40.697248492023903],
                            [-73.891730951743412, 40.704904457900561],
                            [-73.90179117796643, 40.705000609870886],
                            [-73.901916925182903, 40.697344618180175],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.89669787954594,
                    centroid_y: 40.708780591092825,
                    zone_label: 267,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.90179117796643, 40.705000609870886],
                            [-73.891730951743412, 40.704904457900561],
                            [-73.891604004400193, 40.712560413263546],
                            [-73.901665382410641, 40.712656591053765],
                            [-73.90179117796643, 40.705000609870886],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.896571483805019,
                    centroid_y: 40.716436554129714,
                    zone_label: 268,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.901665382410641, 40.712656591053765],
                            [-73.891604004400193, 40.712560413263546],
                            [-73.891477008254029, 40.72021635811231],
                            [-73.901539538494504, 40.720312561728264],
                            [-73.901665382410641, 40.712656591053765],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.896445039471857,
                    centroid_y: 40.72409250665504,
                    zone_label: 269,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.901539538494504, 40.720312561728264],
                            [-73.891477008254029, 40.72021635811231],
                            [-73.891349963283659, 40.727872292446293],
                            [-73.901413646196943, 40.727968521893828],
                            [-73.901539538494504, 40.720312561728264],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.896318546525279,
                    centroid_y: 40.731748448668206,
                    zone_label: 270,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.901413646196943, 40.727968521893828],
                            [-73.891349963283659, 40.727872292446293],
                            [-73.891222869467825, 40.735528216264925],
                            [-73.901287705496884, 40.73562447154989],
                            [-73.901413646196943, 40.727968521893828],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.896192004944126,
                    centroid_y: 40.739404380168715,
                    zone_label: 271,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.901287705496884, 40.73562447154989],
                            [-73.891222869467825, 40.735528216264925],
                            [-73.89109572678521, 40.74318412956768],
                            [-73.901161716373238, 40.743280410695945],
                            [-73.901287705496884, 40.73562447154989],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.896065414707209,
                    centroid_y: 40.747060301155976,
                    zone_label: 272,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.901161716373238, 40.743280410695945],
                            [-73.89109572678521, 40.74318412956768],
                            [-73.890968535214554, 40.750840032353992],
                            [-73.901035678804888, 40.750936339331389],
                            [-73.901161716373238, 40.743280410695945],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.895938775793311,
                    centroid_y: 40.754716211629464,
                    zone_label: 273,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.901035678804888, 40.750936339331389],
                            [-73.890968535214554, 40.750840032353992],
                            [-73.890841294734528, 40.758495924623311],
                            [-73.900909592770745, 40.75859225745571],
                            [-73.901035678804888, 40.750936339331389],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.895812088181188,
                    centroid_y: 40.76237211158859,
                    zone_label: 274,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.900909592770745, 40.75859225745571],
                            [-73.890841294734528, 40.758495924623311],
                            [-73.890714005323815, 40.766151806375078],
                            [-73.900783458249649, 40.766248165068326],
                            [-73.900909592770745, 40.75859225745571],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.89568535184965,
                    centroid_y: 40.770028001032848,
                    zone_label: 275,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.900783458249649, 40.766248165068326],
                            [-73.890714005323815, 40.766151806375078],
                            [-73.89058666696107, 40.773807677608751],
                            [-73.900657275220482, 40.773904062168732],
                            [-73.900783458249649, 40.766248165068326],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.89555856677741,
                    centroid_y: 40.777683879961685,
                    zone_label: 276,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.900657275220482, 40.773904062168732],
                            [-73.89058666696107, 40.773807677608751],
                            [-73.890459279624949, 40.781463538323784],
                            [-73.90053104366207, 40.781559948756346],
                            [-73.900657275220482, 40.773904062168732],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.895050938656254,
                    centroid_y: 40.808307290511692,
                    zone_label: 277,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.900152057599584, 40.804527545437068],
                            [-73.890076823562595, 40.804431057351508],
                            [-73.889949240119179, 40.812086875986466],
                            [-73.900025631712353, 40.812183389968119],
                            [-73.900152057599584, 40.804527545437068],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.894923909561413,
                    centroid_y: 40.815963116855144,
                    zone_label: 278,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.900025631712353, 40.812183389968119],
                            [-73.889949240119179, 40.812086875986466],
                            [-73.889821607595422, 40.819742684100035],
                            [-73.899899157189893, 40.819839223983664],
                            [-73.900025631712353, 40.812183389968119],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.894796831598129,
                    centroid_y: 40.823618932679864,
                    zone_label: 279,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.899899157189893, 40.819839223983664],
                            [-73.889821607595422, 40.819742684100035],
                            [-73.889693925969894, 40.827398481691667],
                            [-73.899772634010958, 40.82749504748314],
                            [-73.899899157189893, 40.819839223983664],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.894669704745027,
                    centroid_y: 40.831274737985339,
                    zone_label: 280,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.899772634010958, 40.82749504748314],
                            [-73.889693925969894, 40.827398481691667],
                            [-73.88956619522115, 40.835054268760835],
                            [-73.899646062154289, 40.835150860466044],
                            [-73.899772634010958, 40.82749504748314],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.894542528980807,
                    centroid_y: 40.838930532770995,
                    zone_label: 281,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.899646062154289, 40.835150860466044],
                            [-73.88956619522115, 40.835054268760835],
                            [-73.889438415327731, 40.842710045306958],
                            [-73.899519441598642, 40.842806662931807],
                            [-73.899646062154289, 40.835150860466044],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.894415304284038,
                    centroid_y: 40.846586317036333,
                    zone_label: 282,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.899519441598642, 40.842806662931807],
                            [-73.889438415327731, 40.842710045306958],
                            [-73.889310586268152, 40.850365811329524],
                            [-73.899392772322727, 40.850462454879896],
                            [-73.899519441598642, 40.842806662931807],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.894288030633376,
                    centroid_y: 40.854242090780751,
                    zone_label: 283,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.899392772322727, 40.850462454879896],
                            [-73.889310586268152, 40.850365811329524],
                            [-73.88918270802094, 40.858021566827965],
                            [-73.899266054305258, 40.858118236309764],
                            [-73.899392772322727, 40.850462454879896],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.894160708007391,
                    centroid_y: 40.86189785400375,
                    zone_label: 284,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.899266054305258, 40.858118236309764],
                            [-73.88918270802094, 40.858021566827965],
                            [-73.889054780564578, 40.865677311801775],
                            [-73.899139287524903, 40.865774007220892],
                            [-73.899266054305258, 40.858118236309764],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.887782828426907,
                    centroid_y: 40.639780080846229,
                    zone_label: 285,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.892871285200144, 40.636000386585259],
                            [-73.882821453770248, 40.635903592354957],
                            [-73.88269379690766, 40.643559616318683],
                            [-73.892744776129206, 40.643656436551233],
                            [-73.892871285200144, 40.636000386585259],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.887528564816193,
                    centroid_y: 40.655092133790497,
                    zone_label: 286,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.892618218445989, 40.651312476007995],
                            [-73.882566090991986, 40.651215629767329],
                            [-73.882438336001854, 40.65887163270034],
                            [-73.89249161212939, 40.658968504954998],
                            [-73.892618218445989, 40.651312476007995],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.887401359724635,
                    centroid_y: 40.662748144493442,
                    zone_label: 287,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.89249161212939, 40.658968504954998],
                            [-73.882438336001854, 40.65887163270034],
                            [-73.882310531915962, 40.666527625117141],
                            [-73.89236495715825, 40.666624523391675],
                            [-73.89249161212939, 40.658968504954998],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.887274105747323,
                    centroid_y: 40.670404144682855,
                    zone_label: 288,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.89236495715825, 40.666624523391675],
                            [-73.882310531915962, 40.666527625117141],
                            [-73.882182678712951, 40.674183607017177],
                            [-73.892238253511422, 40.674280531317457],
                            [-73.89236495715825, 40.666624523391675],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.887146802862915,
                    centroid_y: 40.678060134358155,
                    zone_label: 289,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.892238253511422, 40.674280531317457],
                            [-73.882182678712951, 40.674183607017177],
                            [-73.882054776371447, 40.681839578399881],
                            [-73.892111501167719, 40.681936528731782],
                            [-73.892238253511422, 40.674280531317457],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.887019451050222,
                    centroid_y: 40.685716113518794,
                    zone_label: 290,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.892111501167719, 40.681936528731782],
                            [-73.882054776371447, 40.681839578399881],
                            [-73.881926824870064, 40.689495539264705],
                            [-73.891984700105951, 40.689592515634132],
                            [-73.892111501167719, 40.681936528731782],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.886892050287926,
                    centroid_y: 40.693372082164224,
                    zone_label: 291,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.891984700105951, 40.689592515634132],
                            [-73.881926824870064, 40.689495539264705],
                            [-73.881798824187427, 40.69715148961108],
                            [-73.891857850304916, 40.697248492023903],
                            [-73.891984700105951, 40.689592515634132],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.88676460055467,
                    centroid_y: 40.701028040293863,
                    zone_label: 292,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.891857850304916, 40.697248492023903],
                            [-73.881798824187427, 40.69715148961108],
                            [-73.881670774302108, 40.704807429438446],
                            [-73.891730951743412, 40.704904457900561],
                            [-73.891857850304916, 40.697248492023903],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.886637101829194,
                    centroid_y: 40.708683987907158,
                    zone_label: 293,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.891730951743412, 40.704904457900561],
                            [-73.881670774302108, 40.704807429438446],
                            [-73.881542675192705, 40.712463358746241],
                            [-73.891604004400193, 40.712560413263546],
                            [-73.891730951743412, 40.704904457900561],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.886509554090139,
                    centroid_y: 40.716339925003574,
                    zone_label: 294,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.891604004400193, 40.712560413263546],
                            [-73.881542675192705, 40.712463358746241],
                            [-73.881414526837773, 40.720119277533925],
                            [-73.891477008254029, 40.72021635811231],
                            [-73.891604004400193, 40.712560413263546],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.886381957316175,
                    centroid_y: 40.723995851582544,
                    zone_label: 295,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.891477008254029, 40.72021635811231],
                            [-73.881414526837773, 40.720119277533925],
                            [-73.881286329215854, 40.727775185800915],
                            [-73.891349963283659, 40.727872292446293],
                            [-73.891477008254029, 40.72021635811231],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.88625431148597,
                    centroid_y: 40.731651767643513,
                    zone_label: 296,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.891349963283659, 40.727872292446293],
                            [-73.881286329215854, 40.727775185800915],
                            [-73.881158082305504, 40.735431083546679],
                            [-73.891222869467825, 40.735528216264925],
                            [-73.891349963283659, 40.727872292446293],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.886126616578082,
                    centroid_y: 40.739307673185905,
                    zone_label: 297,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.891222869467825, 40.735528216264925],
                            [-73.881158082305504, 40.735431083546679],
                            [-73.881029786085236, 40.743086970770634],
                            [-73.89109572678521, 40.74318412956768],
                            [-73.891222869467825, 40.735528216264925],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.88599887257115,
                    centroid_y: 40.746963568209196,
                    zone_label: 298,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.89109572678521, 40.74318412956768],
                            [-73.881029786085236, 40.743086970770634],
                            [-73.880901440533563, 40.750742847472253],
                            [-73.890968535214554, 40.750840032353992],
                            [-73.89109572678521, 40.74318412956768],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.885871079443803,
                    centroid_y: 40.754619452712802,
                    zone_label: 299,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.890968535214554, 40.750840032353992],
                            [-73.880901440533563, 40.750742847472253],
                            [-73.880773045628985, 40.758398713650962],
                            [-73.890841294734528, 40.758495924623311],
                            [-73.890968535214554, 40.750840032353992],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.885743237174594,
                    centroid_y: 40.762275326696198,
                    zone_label: 300,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.890841294734528, 40.758495924623311],
                            [-73.880773045628985, 40.758398713650962],
                            [-73.880644601349985, 40.766054569306192],
                            [-73.890714005323815, 40.766151806375078],
                            [-73.890841294734528, 40.758495924623311],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.885615345742124,
                    centroid_y: 40.769931190158822,
                    zone_label: 301,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.890714005323815, 40.766151806375078],
                            [-73.880644601349985, 40.766054569306192],
                            [-73.880516107675021, 40.773710414437438],
                            [-73.89058666696107, 40.773807677608751],
                            [-73.890714005323815, 40.766151806375078],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.885231376250573,
                    centroid_y: 40.792898717416527,
                    zone_label: 302,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.890331843294078, 40.789119388519623],
                            [-73.880258972051038, 40.789022073125658],
                            [-73.88013033005889, 40.796677886681529],
                            [-73.890204357947098, 40.796775228195706],
                            [-73.890331843294078, 40.789119388519623],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.884975150379702,
                    centroid_y: 40.80821034964103,
                    zone_label: 303,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.890076823562595, 40.804431057351508],
                            [-73.880001638584517, 40.804333689711186],
                            [-73.879872897606333, 40.811989482214081],
                            [-73.889949240119179, 40.812086875986466],
                            [-73.890076823562595, 40.804431057351508],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.88484696351685,
                    centroid_y: 40.815866149967448,
                    zone_label: 304,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.889949240119179, 40.812086875986466],
                            [-73.879872897606333, 40.811989482214081],
                            [-73.879744107102724, 40.819645264189639],
                            [-73.889821607595422, 40.819742684100035],
                            [-73.889949240119179, 40.812086875986466],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.884718727340328,
                    centroid_y: 40.823521939769229,
                    zone_label: 305,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.889821607595422, 40.819742684100035],
                            [-73.879744107102724, 40.819645264189639],
                            [-73.87961526705206, 40.827301035637326],
                            [-73.889693925969894, 40.827398481691667],
                            [-73.889821607595422, 40.819742684100035],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.884590441828621,
                    centroid_y: 40.831177719045847,
                    zone_label: 306,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.889693925969894, 40.827398481691667],
                            [-73.87961526705206, 40.827301035637326],
                            [-73.879486377432684, 40.834956796556611],
                            [-73.88956619522115, 40.835054268760835],
                            [-73.889693925969894, 40.827398481691667],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.884462106960186,
                    centroid_y: 40.838833487796734,
                    zone_label: 307,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.88956619522115, 40.835054268760835],
                            [-73.879486377432684, 40.834956796556611],
                            [-73.879357438222954, 40.842612546946903],
                            [-73.889438415327731, 40.842710045306958],
                            [-73.88956619522115, 40.835054268760835],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.88433372271345,
                    centroid_y: 40.846489246021356,
                    zone_label: 308,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.889438415327731, 40.842710045306958],
                            [-73.879357438222954, 40.842612546946903],
                            [-73.879228449401211, 40.85026828680769],
                            [-73.889310586268152, 40.850365811329524],
                            [-73.889438415327731, 40.842710045306958],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.884205289066827,
                    centroid_y: 40.854144993719174,
                    zone_label: 309,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.889310586268152, 40.850365811329524],
                            [-73.879228449401211, 40.85026828680769],
                            [-73.87909941094577, 40.857924016138405],
                            [-73.88918270802094, 40.858021566827965],
                            [-73.889310586268152, 40.850365811329524],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.884076805998731,
                    centroid_y: 40.861800730889634,
                    zone_label: 310,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.88918270802094, 40.858021566827965],
                            [-73.87909941094577, 40.857924016138405],
                            [-73.878970322834903, 40.865579734938521],
                            [-73.889054780564578, 40.865677311801775],
                            [-73.88918270802094, 40.858021566827965],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.877732447626286,
                    centroid_y: 40.639682836373296,
                    zone_label: 311,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.882821453770248, 40.635903592354957],
                            [-73.872771671372149, 40.635805923760422],
                            [-73.872642866739241, 40.643461921487081],
                            [-73.88269379690766, 40.643559616318683],
                            [-73.882821453770248, 40.635903592354957],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.877604192241165,
                    centroid_y: 40.64733884197905,
                    zone_label: 312,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.88269379690766, 40.643559616318683],
                            [-73.872642866739241, 40.643461921487081],
                            [-73.872514012612385, 40.651117908692761],
                            [-73.882566090991986, 40.651215629767329],
                            [-73.88269379690766, 40.643559616318683],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.877475887571791,
                    centroid_y: 40.654994837066475,
                    zone_label: 313,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.882566090991986, 40.651215629767329],
                            [-73.872514012612385, 40.651117908692761],
                            [-73.872385108970093, 40.658773885376853],
                            [-73.882438336001854, 40.65887163270034],
                            [-73.882566090991986, 40.651215629767329],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.877347533596776,
                    centroid_y: 40.662650821635019,
                    zone_label: 314,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.882438336001854, 40.65887163270034],
                            [-73.872385108970093, 40.658773885376853],
                            [-73.872256155790794, 40.666429851538815],
                            [-73.882310531915962, 40.666527625117141],
                            [-73.882438336001854, 40.65887163270034],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.877219130294677,
                    centroid_y: 40.670306795684127,
                    zone_label: 315,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.882310531915962, 40.666527625117141],
                            [-73.872256155790794, 40.666429851538815],
                            [-73.872127153052958, 40.674085807178088],
                            [-73.882182678712951, 40.674183607017177],
                            [-73.882310531915962, 40.666527625117141],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.877090677643963,
                    centroid_y: 40.677962759213209,
                    zone_label: 316,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.882182678712951, 40.674183607017177],
                            [-73.872127153052958, 40.674085807178088],
                            [-73.871998100735041, 40.681741752294073],
                            [-73.882054776371447, 40.681839578399881],
                            [-73.882182678712951, 40.674183607017177],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.876962175623248,
                    centroid_y: 40.685618712221725,
                    zone_label: 317,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.882054776371447, 40.681839578399881],
                            [-73.871998100735041, 40.681741752294073],
                            [-73.871868998815444, 40.689397686886274],
                            [-73.881926824870064, 40.689495539264705],
                            [-73.882054776371447, 40.681839578399881],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.876833624211002,
                    centroid_y: 40.693274654709121,
                    zone_label: 318,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.881926824870064, 40.689495539264705],
                            [-73.871868998815444, 40.689397686886274],
                            [-73.871739847272593, 40.697053610954057],
                            [-73.881798824187427, 40.69715148961108],
                            [-73.881926824870064, 40.689495539264705],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.876705023385782,
                    centroid_y: 40.700930586674801,
                    zone_label: 319,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.881798824187427, 40.69715148961108],
                            [-73.871739847272593, 40.697053610954057],
                            [-73.871610646084889, 40.704709524496892],
                            [-73.881670774302108, 40.704807429438446],
                            [-73.881798824187427, 40.69715148961108],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.876576373125999,
                    centroid_y: 40.708586508118245,
                    zone_label: 320,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.881670774302108, 40.704807429438446],
                            [-73.871610646084889, 40.704709524496892],
                            [-73.871481395230731, 40.712365427514229],
                            [-73.881542675192705, 40.712463358746241],
                            [-73.881670774302108, 40.704807429438446],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.876447673410183,
                    centroid_y: 40.716242419038871,
                    zone_label: 321,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.881542675192705, 40.712463358746241],
                            [-73.871481395230731, 40.712365427514229],
                            [-73.871352094688476, 40.720021320005479],
                            [-73.881414526837773, 40.720119277533925],
                            [-73.881542675192705, 40.712463358746241],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.87631892421679,
                    centroid_y: 40.72389831943611,
                    zone_label: 322,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.881414526837773, 40.720119277533925],
                            [-73.871352094688476, 40.720021320005479],
                            [-73.871222744436494, 40.727677201970103],
                            [-73.881286329215854, 40.727775185800915],
                            [-73.881414526837773, 40.720119277533925],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.876190125524289,
                    centroid_y: 40.731554209309429,
                    zone_label: 323,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.881286329215854, 40.727775185800915],
                            [-73.871222744436494, 40.727677201970103],
                            [-73.871093344453115, 40.735333073407531],
                            [-73.881158082305504, 40.735431083546679],
                            [-73.881286329215854, 40.727775185800915],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.876061277311038,
                    centroid_y: 40.739210088658261,
                    zone_label: 324,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.881158082305504, 40.735431083546679],
                            [-73.871093344453115, 40.735333073407531],
                            [-73.870963894716681, 40.742988934317204],
                            [-73.881029786085236, 40.743086970770634],
                            [-73.881158082305504, 40.735431083546679],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.875932379555536,
                    centroid_y: 40.746865957482029,
                    zone_label: 325,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.881029786085236, 40.743086970770634],
                            [-73.870963894716681, 40.742988934317204],
                            [-73.870834395205492, 40.750644784698565],
                            [-73.880901440533563, 40.750742847472253],
                            [-73.881029786085236, 40.743086970770634],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.875803432236154,
                    centroid_y: 40.754521815780215,
                    zone_label: 326,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.880901440533563, 40.750742847472253],
                            [-73.870834395205492, 40.750644784698565],
                            [-73.870704845897876, 40.758300624551062],
                            [-73.880773045628985, 40.758398713650962],
                            [-73.880901440533563, 40.750742847472253],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.875674435331263,
                    centroid_y: 40.762177663552215,
                    zone_label: 327,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.880773045628985, 40.758398713650962],
                            [-73.870704845897876, 40.758300624551062],
                            [-73.87057524677212, 40.765956453874118],
                            [-73.880644601349985, 40.766054569306192],
                            [-73.880773045628985, 40.758398713650962],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.875545388819305,
                    centroid_y: 40.76983350079751,
                    zone_label: 328,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.880644601349985, 40.766054569306192],
                            [-73.87057524677212, 40.765956453874118],
                            [-73.870445597806494, 40.773612272667201],
                            [-73.880516107675021, 40.773710414437438],
                            [-73.880644601349985, 40.766054569306192],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.875416292678608,
                    centroid_y: 40.777489327515539,
                    zone_label: 329,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.880516107675021, 40.773710414437438],
                            [-73.870445597806494, 40.773612272667201],
                            [-73.870315898979229, 40.781268080929742],
                            [-73.880387564582563, 40.781366249044105],
                            [-73.880516107675021, 40.773710414437438],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.87489941139529,
                    centroid_y: 40.808112529103845,
                    zone_label: 330,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.880001638584517, 40.804333689711186],
                            [-73.869926503110264, 40.804235442528579],
                            [-73.869796604618926, 40.811891208663411],
                            [-73.879872897606333, 40.811989482214081],
                            [-73.880001638584517, 40.804333689711186],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.874770066785928,
                    centroid_y: 40.815768303177208,
                    zone_label: 331,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.879872897606333, 40.811989482214081],
                            [-73.869796604618926, 40.811891208663411],
                            [-73.869666656157079, 40.81954696426493],
                            [-73.879744107102724, 40.819645264189639],
                            [-73.879872897606333, 40.811989482214081],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.874640672417712,
                    centroid_y: 40.823424066719987,
                    zone_label: 332,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.879744107102724, 40.819645264189639],
                            [-73.869666656157079, 40.81954696426493],
                            [-73.869536657702909, 40.827202709332589],
                            [-73.87961526705206, 40.827301035637326],
                            [-73.879744107102724, 40.819645264189639],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.874511228268972,
                    centroid_y: 40.831079819731634,
                    zone_label: 333,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.87961526705206, 40.827301035637326],
                            [-73.869536657702909, 40.827202709332589],
                            [-73.869406609234559, 40.834858443865834],
                            [-73.879486377432684, 40.834956796556611],
                            [-73.87961526705206, 40.827301035637326],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.874381734317879,
                    centroid_y: 40.838735562211596,
                    zone_label: 334,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.879486377432684, 40.834956796556611],
                            [-73.869406609234559, 40.834858443865834],
                            [-73.869276510730217, 40.84251416786411],
                            [-73.879357438222954, 40.842612546946903],
                            [-73.879486377432684, 40.834956796556611],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.87425219054272,
                    centroid_y: 40.846391294159304,
                    zone_label: 335,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.879357438222954, 40.842612546946903],
                            [-73.869276510730217, 40.84251416786411],
                            [-73.869146362167967, 40.850169881326877],
                            [-73.879228449401211, 40.85026828680769],
                            [-73.879357438222954, 40.842612546946903],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.874122596921737,
                    centroid_y: 40.854047015574231,
                    zone_label: 336,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.879228449401211, 40.85026828680769],
                            [-73.869146362167967, 40.850169881326877],
                            [-73.869016163525998, 40.857825584253568],
                            [-73.87909941094577, 40.857924016138405],
                            [-73.879228449401211, 40.85026828680769],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.873992953433145,
                    centroid_y: 40.861702726455825,
                    zone_label: 337,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.87909941094577, 40.857924016138405],
                            [-73.869016163525998, 40.857825584253568],
                            [-73.868885914782368, 40.865481276643649],
                            [-73.878970322834903, 40.865579734938521],
                            [-73.87909941094577, 40.857924016138405],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.867552712723366,
                    centroid_y: 40.647240696673265,
                    zone_label: 338,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.872642866739241, 40.643461921487081],
                            [-73.862591986064757, 40.643363352068889],
                            [-73.862461983748275, 40.65101931279672],
                            [-73.872514012612385, 40.651117908692761],
                            [-73.872642866739241, 40.643461921487081],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.8674232596331,
                    centroid_y: 40.654896665397303,
                    zone_label: 339,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.872514012612385, 40.651117908692761],
                            [-73.862461983748275, 40.65101931279672],
                            [-73.862331931475296, 40.658675262996987],
                            [-73.872385108970093, 40.658773885376853],
                            [-73.872514012612385, 40.651117908692761],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.867293756796045,
                    centroid_y: 40.662552623596504,
                    zone_label: 340,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.872385108970093, 40.658773885376853],
                            [-73.862331931475296, 40.658675262996987],
                            [-73.862201829224148, 40.666331202669156],
                            [-73.872256155790794, 40.666429851538815],
                            [-73.872385108970093, 40.658773885376853],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.867164204190587,
                    centroid_y: 40.670208571270301,
                    zone_label: 341,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.872256155790794, 40.666429851538815],
                            [-73.862201829224148, 40.666331202669156],
                            [-73.862071676973059, 40.673987131812638],
                            [-73.872127153052958, 40.674085807178088],
                            [-73.872256155790794, 40.666429851538815],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.867034601795041,
                    centroid_y: 40.677864508418139,
                    zone_label: 342,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.872127153052958, 40.674085807178088],
                            [-73.862071676973059, 40.673987131812638],
                            [-73.861941474700288, 40.681643050426871],
                            [-73.871998100735041, 40.681741752294073],
                            [-73.872127153052958, 40.674085807178088],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.866904949587791,
                    centroid_y: 40.685520435039429,
                    zone_label: 343,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.871998100735041, 40.681741752294073],
                            [-73.861941474700288, 40.681643050426871],
                            [-73.861811222384063, 40.68929895851128],
                            [-73.871868998815444, 40.689397686886274],
                            [-73.871998100735041, 40.681741752294073],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.866775247547125,
                    centroid_y: 40.693176351133637,
                    zone_label: 344,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.871868998815444, 40.689397686886274],
                            [-73.861811222384063, 40.68929895851128],
                            [-73.861680920002613, 40.696954856065325],
                            [-73.871739847272593, 40.697053610954057],
                            [-73.871868998815444, 40.689397686886274],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.866645495651383,
                    centroid_y: 40.700832256700174,
                    zone_label: 345,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.871739847272593, 40.697053610954057],
                            [-73.861680920002613, 40.696954856065325],
                            [-73.861550567534138, 40.704610743088409],
                            [-73.871610646084889, 40.704709524496892],
                            [-73.871739847272593, 40.697053610954057],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.866515693878867,
                    centroid_y: 40.708488151738486,
                    zone_label: 346,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.871610646084889, 40.704709524496892],
                            [-73.861550567534138, 40.704610743088409],
                            [-73.861420164956854, 40.712266619579992],
                            [-73.871481395230731, 40.712365427514229],
                            [-73.871610646084889, 40.704709524496892],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.866385842207819,
                    centroid_y: 40.716144036248004,
                    zone_label: 347,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.871481395230731, 40.712365427514229],
                            [-73.861420164956854, 40.712266619579992],
                            [-73.861289712248919, 40.719922485539477],
                            [-73.871352094688476, 40.720021320005479],
                            [-73.871481395230731, 40.712365427514229],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.866255940616554,
                    centroid_y: 40.72379991022818,
                    zone_label: 348,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.871352094688476, 40.720021320005479],
                            [-73.861289712248919, 40.719922485539477],
                            [-73.861159209388504, 40.727578340966353],
                            [-73.871222744436494, 40.727677201970103],
                            [-73.871352094688476, 40.720021320005479],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.866125989083272,
                    centroid_y: 40.731455773678427,
                    zone_label: 349,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.871222744436494, 40.727677201970103],
                            [-73.861159209388504, 40.727578340966353],
                            [-73.861028656353767, 40.735234185859994],
                            [-73.871093344453115, 40.735333073407531],
                            [-73.871222744436494, 40.727677201970103],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.865995987586302,
                    centroid_y: 40.739111626598216,
                    zone_label: 350,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.871093344453115, 40.735333073407531],
                            [-73.861028656353767, 40.735234185859994],
                            [-73.860898053122838, 40.742890020219889],
                            [-73.870963894716681, 40.742988934317204],
                            [-73.871093344453115, 40.735333073407531],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.865865936103773,
                    centroid_y: 40.746767468986967,
                    zone_label: 351,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.870963894716681, 40.742988934317204],
                            [-73.860898053122838, 40.742890020219889],
                            [-73.860767399673847, 40.750545844045462],
                            [-73.870834395205492, 40.750644784698565],
                            [-73.870963894716681, 40.742988934317204],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.865735834613957,
                    centroid_y: 40.754423300844117,
                    zone_label: 352,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.870834395205492, 40.750644784698565],
                            [-73.860767399673847, 40.750545844045462],
                            [-73.860636695984908, 40.758201657336137],
                            [-73.870704845897876, 40.758300624551062],
                            [-73.870834395205492, 40.750644784698565],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.865605683095055,
                    centroid_y: 40.762079122169112,
                    zone_label: 353,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.870704845897876, 40.758300624551062],
                            [-73.860636695984908, 40.758201657336137],
                            [-73.860505942034109, 40.765857460091361],
                            [-73.87057524677212, 40.765956453874118],
                            [-73.870704845897876, 40.758300624551062],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.865475481525237,
                    centroid_y: 40.769734932961406,
                    zone_label: 354,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.87057524677212, 40.765956453874118],
                            [-73.860505942034109, 40.765857460091361],
                            [-73.860375137799551, 40.773513252310586],
                            [-73.870445597806494, 40.773612272667201],
                            [-73.87057524677212, 40.765956453874118],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.864693219813816,
                    centroid_y: 40.815669576496965,
                    zone_label: 355,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.869796604618926, 40.811891208663411],
                            [-73.859720361602015, 40.811792055347027],
                            [-73.859589255203772, 40.819447784338486],
                            [-73.869666656157079, 40.81954696426493],
                            [-73.869796604618926, 40.811891208663411],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.864562667275663,
                    centroid_y: 40.823325313544693,
                    zone_label: 356,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.869666656157079, 40.81954696426493],
                            [-73.859589255203772, 40.819447784338486],
                            [-73.859458098367924, 40.827103502790031],
                            [-73.869536657702909, 40.827202709332589],
                            [-73.869666656157079, 40.81954696426493],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.86443206451159,
                    centroid_y: 40.830981040055249,
                    zone_label: 357,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.869536657702909, 40.827202709332589],
                            [-73.859458098367924, 40.827103502790031],
                            [-73.859326891072456, 40.834759210701108],
                            [-73.869406609234559, 40.834858443865834],
                            [-73.869536657702909, 40.827202709332589],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.864301411499611,
                    centroid_y: 40.838636756028087,
                    zone_label: 358,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.869406609234559, 40.834858443865834],
                            [-73.859326891072456, 40.834759210701108],
                            [-73.859195633295329, 40.84241490807117],
                            [-73.869276510730217, 40.84251416786411],
                            [-73.869406609234559, 40.834858443865834],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.864170708217856,
                    centroid_y: 40.846292461462667,
                    zone_label: 359,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.869276510730217, 40.84251416786411],
                            [-73.859195633295329, 40.84241490807117],
                            [-73.859064325014501, 40.85007059489967],
                            [-73.869146362167967, 40.850169881326877],
                            [-73.869276510730217, 40.84251416786411],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.864039954644284,
                    centroid_y: 40.853948156358442,
                    zone_label: 360,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.869146362167967, 40.850169881326877],
                            [-73.859064325014501, 40.85007059489967],
                            [-73.85893296620786, 40.857726271186039],
                            [-73.869016163525998, 40.857825584253568],
                            [-73.869146362167967, 40.850169881326877],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.863909150756953,
                    centroid_y: 40.861603840714835,
                    zone_label: 361,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.869016163525998, 40.857825584253568],
                            [-73.85893296620786, 40.857726271186039],
                            [-73.858801556853351, 40.865381936929737],
                            [-73.868885914782368, 40.865481276643649],
                            [-73.869016163525998, 40.857825584253568],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.857370681441139,
                    centroid_y: 40.654797618795484,
                    zone_label: 362,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.862461983748275, 40.65101931279672],
                            [-73.852410004840607, 40.65091984209176],
                            [-73.852278803958711, 40.65857576557331],
                            [-73.862331931475296, 40.658675262996987],
                            [-73.862461983748275, 40.65101931279672],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.857240029763688,
                    centroid_y: 40.66245355039041,
                    zone_label: 363,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.862331931475296, 40.658675262996987],
                            [-73.852278803958711, 40.65857576557331],
                            [-73.852147552657442, 40.666231678520731],
                            [-73.862201829224148, 40.666331202669156],
                            [-73.862331931475296, 40.658675262996987],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.857109327876543,
                    centroid_y: 40.670109471453934,
                    zone_label: 364,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.862201829224148, 40.666331202669156],
                            [-73.852147552657442, 40.666231678520731],
                            [-73.852016250914843, 40.673887580933425],
                            [-73.862071676973059, 40.673987131812638],
                            [-73.862201829224148, 40.666331202669156],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.856978575757822,
                    centroid_y: 40.677765381985466,
                    zone_label: 365,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.862071676973059, 40.673987131812638],
                            [-73.852016250914843, 40.673887580933425],
                            [-73.851884898708988, 40.681543472810816],
                            [-73.861941474700288, 40.681643050426871],
                            [-73.862071676973059, 40.673987131812638],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.856847773385709,
                    centroid_y: 40.685421281984446,
                    zone_label: 366,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.861941474700288, 40.681643050426871],
                            [-73.851884898708988, 40.681543472810816],
                            [-73.851753496017906, 40.689199354152343],
                            [-73.861811222384063, 40.68929895851128],
                            [-73.861941474700288, 40.681643050426871],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.856716920738336,
                    centroid_y: 40.693077171450319,
                    zone_label: 367,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.861811222384063, 40.68929895851128],
                            [-73.851753496017906, 40.689199354152343],
                            [-73.851622042819642, 40.696855224957474],
                            [-73.861680920002613, 40.696954856065325],
                            [-73.861811222384063, 40.68929895851128],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.856586017793788,
                    centroid_y: 40.700733050382496,
                    zone_label: 368,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.861680920002613, 40.696954856065325],
                            [-73.851622042819642, 40.696855224957474],
                            [-73.851490539092211, 40.704511085225569],
                            [-73.861550567534138, 40.704610743088409],
                            [-73.861680920002613, 40.696954856065325],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.856455064530223,
                    centroid_y: 40.708388918780429,
                    zone_label: 369,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.861550567534138, 40.704610743088409],
                            [-73.851490539092211, 40.704511085225569],
                            [-73.851358984813615, 40.71216693495613],
                            [-73.861420164956854, 40.712266619579992],
                            [-73.861550567534138, 40.704610743088409],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.856324060925687,
                    centroid_y: 40.716044776643557,
                    zone_label: 370,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.861420164956854, 40.712266619579992],
                            [-73.851358984813615, 40.71216693495613],
                            [-73.851227379961841, 40.719822774148533],
                            [-73.861289712248919, 40.719922485539477],
                            [-73.861420164956854, 40.712266619579992],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.856193006958264,
                    centroid_y: 40.723700623971283,
                    zone_label: 371,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.861289712248919, 40.719922485539477],
                            [-73.851227379961841, 40.719822774148533],
                            [-73.851095724514835, 40.727478602802258],
                            [-73.861159209388504, 40.727578340966353],
                            [-73.861289712248919, 40.719922485539477],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.856061902606029,
                    centroid_y: 40.731356460763088,
                    zone_label: 372,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.861159209388504, 40.727578340966353],
                            [-73.851095724514835, 40.727478602802258],
                            [-73.850964018450611, 40.735134420916715],
                            [-73.861028656353767, 40.735234185859994],
                            [-73.861159209388504, 40.727578340966353],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.855930747847069,
                    centroid_y: 40.739012287018348,
                    zone_label: 373,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.861028656353767, 40.735234185859994],
                            [-73.850964018450611, 40.735134420916715],
                            [-73.850832261747072, 40.74279022849133],
                            [-73.860898053122838, 40.742890020219889],
                            [-73.861028656353767, 40.735234185859994],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.855799542659312,
                    centroid_y: 40.74666810273655,
                    zone_label: 374,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.860898053122838, 40.742890020219889],
                            [-73.850832261747072, 40.74279022849133],
                            [-73.850700454382164, 40.750446025525555],
                            [-73.860767399673847, 40.750545844045462],
                            [-73.860898053122838, 40.742890020219889],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.855668287020904,
                    centroid_y: 40.754323907917112,
                    zone_label: 375,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.860767399673847, 40.750545844045462],
                            [-73.850700454382164, 40.750446025525555],
                            [-73.850568596333801, 40.758101812018829],
                            [-73.860636695984908, 40.758201657336137],
                            [-73.860767399673847, 40.750545844045462],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.855142759519964,
                    centroid_y: 40.784947023251746,
                    zone_label: 376,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.860244283259277, 40.78116903399323],
                            [-73.850172717866954, 40.781069108247245],
                            [-73.850040656863683, 40.788724852571043],
                            [-73.860113378391347, 40.788824805138773],
                            [-73.860244283259277, 40.78116903399323],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.854748083082754,
                    centroid_y: 40.807914249080049,
                    zone_label: 377,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.859851417584707, 40.804136315816216],
                            [-73.849776382452717, 40.804036309586785],
                            [-73.849644169000683, 40.811692022277597],
                            [-73.859720361602015, 40.811792055347027],
                            [-73.859851417584707, 40.804136315816216],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.854616423045741,
                    centroid_y: 40.815569969939332,
                    zone_label: 378,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.859720361602015, 40.811792055347027],
                            [-73.849644169000683, 40.811692022277597],
                            [-73.849511904688029, 40.819347724422968],
                            [-73.859589255203772, 40.819447784338486],
                            [-73.859720361602015, 40.811792055347027],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.85448471235955,
                    centroid_y: 40.823225680255938,
                    zone_label: 379,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.859589255203772, 40.819447784338486],
                            [-73.849511904688029, 40.819347724422968],
                            [-73.849379589492557, 40.827003416022315],
                            [-73.859458098367924, 40.827103502790031],
                            [-73.859589255203772, 40.819447784338486],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.85435295100207,
                    centroid_y: 40.830881380029297,
                    zone_label: 380,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.859458098367924, 40.827103502790031],
                            [-73.849379589492557, 40.827003416022315],
                            [-73.849247223392013, 40.834659097075104],
                            [-73.859326891072456, 40.834759210701108],
                            [-73.859458098367924, 40.827103502790031],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.854221138951189,
                    centroid_y: 40.838537069258884,
                    zone_label: 381,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.859326891072456, 40.834759210701108],
                            [-73.849247223392013, 40.834659097075104],
                            [-73.849114806364184, 40.842314767580795],
                            [-73.859195633295329, 40.84241490807117],
                            [-73.859326891072456, 40.834759210701108],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.854089276184766,
                    centroid_y: 40.846192747944109,
                    zone_label: 382,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.859195633295329, 40.84241490807117],
                            [-73.849114806364184, 40.842314767580795],
                            [-73.84898233838679, 40.849970427538764],
                            [-73.859064325014501, 40.85007059489967],
                            [-73.859195633295329, 40.84241490807117],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.853957362680603,
                    centroid_y: 40.853848416084446,
                    zone_label: 383,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.859064325014501, 40.85007059489967],
                            [-73.84898233838679, 40.849970427538764],
                            [-73.848849819437604, 40.857626076948527],
                            [-73.85893296620786, 40.857726271186039],
                            [-73.859064325014501, 40.85007059489967],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.853825398416546,
                    centroid_y: 40.861504073679349,
                    zone_label: 384,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.85893296620786, 40.857726271186039],
                            [-73.848849819437604, 40.857626076948527],
                            [-73.848717249494314, 40.865281715809502],
                            [-73.858801556853351, 40.865381936929737],
                            [-73.85893296620786, 40.857726271186039],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.847449903304152,
                    centroid_y: 40.647041781981017,
                    zone_label: 385,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.852541155325028, 40.643263908076626],
                            [-73.842490374960889, 40.643163589522985],
                            [-73.842358076330441, 40.650819496590586],
                            [-73.852410004840607, 40.65091984209176],
                            [-73.852541155325028, 40.643263908076626],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.847318153437087,
                    centroid_y: 40.65469769727364,
                    zone_label: 386,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.852410004840607, 40.65091984209176],
                            [-73.842358076330441, 40.650819496590586],
                            [-73.842225726861514, 40.658475393118522],
                            [-73.852278803958711, 40.65857576557331],
                            [-73.852410004840607, 40.65091984209176],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.847186352941065,
                    centroid_y: 40.66235360202937,
                    zone_label: 387,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.852278803958711, 40.65857576557331],
                            [-73.842225726861514, 40.658475393118522],
                            [-73.842093326532051, 40.666131279106217],
                            [-73.852147552657442, 40.666231678520731],
                            [-73.852278803958711, 40.65857576557331],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.847054501794048,
                    centroid_y: 40.670009496247623,
                    zone_label: 388,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.852147552657442, 40.666231678520731],
                            [-73.842093326532051, 40.666131279106217],
                            [-73.841960875319884, 40.673787154553111],
                            [-73.852016250914843, 40.673887580933425],
                            [-73.852147552657442, 40.666231678520731],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.846922599974008,
                    centroid_y: 40.677665379927824,
                    zone_label: 389,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.852016250914843, 40.673887580933425],
                            [-73.841960875319884, 40.673787154553111],
                            [-73.841828373202887, 40.681443019458619],
                            [-73.851884898708988, 40.681543472810816],
                            [-73.852016250914843, 40.673887580933425],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.846790647458889,
                    centroid_y: 40.685321253069404,
                    zone_label: 390,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.851884898708988, 40.681543472810816],
                            [-73.841828373202887, 40.681443019458619],
                            [-73.841695820158932, 40.689098873822175],
                            [-73.851753496017906, 40.689199354152343],
                            [-73.851884898708988, 40.681543472810816],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.846658644226679,
                    centroid_y: 40.692977115671809,
                    zone_label: 391,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.851753496017906, 40.689199354152343],
                            [-73.841695820158932, 40.689098873822175],
                            [-73.841563216165852, 40.696754717643188],
                            [-73.851622042819642, 40.696855224957474],
                            [-73.851753496017906, 40.689199354152343],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.846526590255252,
                    centroid_y: 40.70063296773445,
                    zone_label: 392,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.851622042819642, 40.696855224957474],
                            [-73.841563216165852, 40.696754717643188],
                            [-73.841430561201449, 40.704410550921111],
                            [-73.851490539092211, 40.704511085225569],
                            [-73.851622042819642, 40.696855224957474],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.846394485522524,
                    centroid_y: 40.708288809256764,
                    zone_label: 393,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.851490539092211, 40.704511085225569],
                            [-73.841430561201449, 40.704410550921111],
                            [-73.841297855243553, 40.712066373655368],
                            [-73.851358984813615, 40.71216693495613],
                            [-73.851490539092211, 40.704511085225569],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.846262330006425,
                    centroid_y: 40.715944640238163,
                    zone_label: 394,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.851358984813615, 40.71216693495613],
                            [-73.841297855243553, 40.712066373655368],
                            [-73.841165098269954, 40.719722185845363],
                            [-73.851227379961841, 40.719822774148533],
                            [-73.851358984813615, 40.71216693495613],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.84613012368483,
                    centroid_y: 40.723600460678121,
                    zone_label: 395,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.851227379961841, 40.719822774148533],
                            [-73.841165098269954, 40.719722185845363],
                            [-73.84103229025844, 40.727377987490563],
                            [-73.851095724514835, 40.727478602802258],
                            [-73.851227379961841, 40.719822774148533],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.845997866535569,
                    centroid_y: 40.731256270576019,
                    zone_label: 396,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.851095724514835, 40.727478602802258],
                            [-73.84103229025844, 40.727377987490563],
                            [-73.840899431186742, 40.735033778590385],
                            [-73.850964018450611, 40.735134420916715],
                            [-73.851095724514835, 40.727478602802258],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.845865558536516,
                    centroid_y: 40.738912069931338,
                    zone_label: 397,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.850964018450611, 40.735134420916715],
                            [-73.840899431186742, 40.735033778590385],
                            [-73.840766521032663, 40.74268955914426],
                            [-73.850832261747072, 40.74279022849133],
                            [-73.850964018450611, 40.735134420916715],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.84573319966556,
                    centroid_y: 40.746567858743482,
                    zone_label: 398,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.850832261747072, 40.74279022849133],
                            [-73.840766521032663, 40.74268955914426],
                            [-73.84063355977392, 40.750345329151607],
                            [-73.850700454382164, 40.750446025525555],
                            [-73.850832261747072, 40.74279022849133],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.845600789900502,
                    centroid_y: 40.754223637011883,
                    zone_label: 399,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.850700454382164, 40.750446025525555],
                            [-73.84063355977392, 40.750345329151607],
                            [-73.840500547388231, 40.75800108861187],
                            [-73.850568596333801, 40.758101812018829],
                            [-73.850700454382164, 40.750446025525555],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.845468329219131,
                    centroid_y: 40.761879404735986,
                    zone_label: 400,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.850568596333801, 40.758101812018829],
                            [-73.840500547388231, 40.75800108861187],
                            [-73.840367483853314, 40.765656837524489],
                            [-73.850436687579887, 40.765757587970562],
                            [-73.850568596333801, 40.758101812018829],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.845335817599306,
                    centroid_y: 40.769535161915236,
                    zone_label: 401,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.850436687579887, 40.765757587970562],
                            [-73.840367483853314, 40.765656837524489],
                            [-73.84023436914687, 40.773312575888895],
                            [-73.850304728098322, 40.773413353380228],
                            [-73.850436687579887, 40.765757587970562],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.845203255018731,
                    centroid_y: 40.777190908549052,
                    zone_label: 402,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.850304728098322, 40.773413353380228],
                            [-73.84023436914687, 40.773312575888895],
                            [-73.840101203246576, 40.780968303704519],
                            [-73.850172717866954, 40.781069108247245],
                            [-73.850304728098322, 40.773413353380228],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.845070641455251,
                    centroid_y: 40.784846644636879,
                    zone_label: 403,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.850172717866954, 40.781069108247245],
                            [-73.840101203246576, 40.780968303704519],
                            [-73.839967986130077, 40.788624020970794],
                            [-73.850040656863683, 40.788724852571043],
                            [-73.850172717866954, 40.781069108247245],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.844937976886555,
                    centroid_y: 40.792502370178148,
                    zone_label: 404,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.850040656863683, 40.788724852571043],
                            [-73.839967986130077, 40.788624020970794],
                            [-73.839834717775048, 40.796279727687157],
                            [-73.849908545066313, 40.796380586351077],
                            [-73.850040656863683, 40.788724852571043],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.844539676926786,
                    centroid_y: 40.815469483517013,
                    zone_label: 405,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.849644169000683, 40.811692022277597],
                            [-73.839568027259972, 40.811591109467912],
                            [-73.839434605055118, 40.819246784531174],
                            [-73.849511904688029, 40.819347724422968],
                            [-73.849644169000683, 40.811692022277597],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.84440680811467,
                    centroid_y: 40.823125166866461,
                    zone_label: 406,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.849511904688029, 40.819347724422968],
                            [-73.839434605055118, 40.819246784531174],
                            [-73.839301131522234, 40.826902449042272],
                            [-73.849379589492557, 40.827003416022315],
                            [-73.849511904688029, 40.819347724422968],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.844273888185967,
                    centroid_y: 40.830780839666538,
                    zone_label: 407,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.849379589492557, 40.827003416022315],
                            [-73.839301131522234, 40.826902449042272],
                            [-73.839167606638853, 40.834558103000639],
                            [-73.849247223392013, 40.834659097075104],
                            [-73.849379589492557, 40.827003416022315],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.84414091711831,
                    centroid_y: 40.838436501916703,
                    zone_label: 408,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.849247223392013, 40.834659097075104],
                            [-73.839167606638853, 40.834558103000639],
                            [-73.839034030382564, 40.842213746405733],
                            [-73.849114806364184, 40.842314767580795],
                            [-73.849247223392013, 40.834659097075104],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.844007894889344,
                    centroid_y: 40.846092153616382,
                    zone_label: 409,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.849114806364184, 40.842314767580795],
                            [-73.839034030382564, 40.842213746405733],
                            [-73.8389004027309, 40.849869379257001],
                            [-73.84898233838679, 40.849970427538764],
                            [-73.849114806364184, 40.842314767580795],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.843874821476803,
                    centroid_y: 40.853747794765034,
                    zone_label: 410,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.84898233838679, 40.849970427538764],
                            [-73.8389004027309, 40.849869379257001],
                            [-73.838766723661436, 40.857525001553846],
                            [-73.848849819437604, 40.857626076948527],
                            [-73.84898233838679, 40.849970427538764],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.843741696858217,
                    centroid_y: 40.861403425362091,
                    zone_label: 411,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.848849819437604, 40.857626076948527],
                            [-73.838766723661436, 40.857525001553846],
                            [-73.838632993151663, 40.865180613295749],
                            [-73.848717249494314, 40.865281715809502],
                            [-73.848849819437604, 40.857626076948527],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.837398574284535,
                    centroid_y: 40.646941012619877,
                    zone_label: 412,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.842490374960889, 40.643163589522985],
                            [-73.832439645413089, 40.643062396420724],
                            [-73.832306198658713, 40.650718276305952],
                            [-73.842358076330441, 40.650819496590586],
                            [-73.842490374960889, 40.643163589522985],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.837132726769426,
                    centroid_y: 40.66225277852611,
                    zone_label: 413,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.842225726861514, 40.658475393118522],
                            [-73.832172700624895, 40.658374145645368],
                            [-73.832039151289337, 40.666030004438426],
                            [-73.842093326532051, 40.666131279106217],
                            [-73.842225726861514, 40.658475393118522],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.836999726384605,
                    centroid_y: 40.669908645664123,
                    zone_label: 414,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.842093326532051, 40.666131279106217],
                            [-73.832039151289337, 40.666030004438426],
                            [-73.831905550629742, 40.673685852684514],
                            [-73.841960875319884, 40.673787154553111],
                            [-73.842093326532051, 40.666131279106217],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.836866674885272,
                    centroid_y: 40.677564502257965,
                    zone_label: 415,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.841960875319884, 40.673787154553111],
                            [-73.831905550629742, 40.673685852684514],
                            [-73.831771898623771, 40.681341690383078],
                            [-73.841828373202887, 40.681443019458619],
                            [-73.841960875319884, 40.673787154553111],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.83673357224923,
                    centroid_y: 40.685220348307062,
                    zone_label: 416,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.841828373202887, 40.681443019458619],
                            [-73.831771898623771, 40.681341690383078],
                            [-73.831638195249099, 40.688997517533537],
                            [-73.841695820158932, 40.689098873822175],
                            [-73.841828373202887, 40.681443019458619],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.836600418454211,
                    centroid_y: 40.692876183810867,
                    zone_label: 417,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.841695820158932, 40.689098873822175],
                            [-73.831638195249099, 40.688997517533537],
                            [-73.831504440483357, 40.696653334135313],
                            [-73.841563216165852, 40.696754717643188],
                            [-73.841695820158932, 40.689098873822175],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.836467213477974,
                    centroid_y: 40.700532008768768,
                    zone_label: 418,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.841563216165852, 40.696754717643188],
                            [-73.831504440483357, 40.696653334135313],
                            [-73.831370634304193, 40.704309140187831],
                            [-73.841430561201449, 40.704410550921111],
                            [-73.841563216165852, 40.696754717643188],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.836333957298208,
                    centroid_y: 40.708187823180225,
                    zone_label: 419,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.841430561201449, 40.704410550921111],
                            [-73.831370634304193, 40.704309140187831],
                            [-73.831236776689195, 40.711964935690524],
                            [-73.841297855243553, 40.712066373655368],
                            [-73.841430561201449, 40.704410550921111],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.836200649892646,
                    centroid_y: 40.715843627044634,
                    zone_label: 420,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.841297855243553, 40.712066373655368],
                            [-73.831236776689195, 40.711964935690524],
                            [-73.831102867615996, 40.719620720642801],
                            [-73.841165098269954, 40.719722185845363],
                            [-73.841297855243553, 40.712066373655368],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.836067291238976,
                    centroid_y: 40.723499420361442,
                    zone_label: 421,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.841165098269954, 40.719722185845363],
                            [-73.831102867615996, 40.719620720642801],
                            [-73.830968907062172, 40.727276495044123],
                            [-73.84103229025844, 40.727377987490563],
                            [-73.841165098269954, 40.719722185845363],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.835933881314858,
                    centroid_y: 40.731155203130086,
                    zone_label: 422,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.84103229025844, 40.727377987490563],
                            [-73.830968907062172, 40.727276495044123],
                            [-73.830834895005296, 40.73493225889387],
                            [-73.840899431186742, 40.735033778590385],
                            [-73.84103229025844, 40.727377987490563],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.835800420097954,
                    centroid_y: 40.738810975349963,
                    zone_label: 423,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.840899431186742, 40.735033778590385],
                            [-73.830834895005296, 40.73493225889387],
                            [-73.830700831422931, 40.742588012191518],
                            [-73.840766521032663, 40.74268955914426],
                            [-73.840899431186742, 40.735033778590385],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.835666907565937,
                    centroid_y: 40.746466737020548,
                    zone_label: 424,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.840766521032663, 40.74268955914426],
                            [-73.830700831422931, 40.742588012191518],
                            [-73.830566716292623, 40.750243754936456],
                            [-73.84063355977392, 40.750345329151607],
                            [-73.840766521032663, 40.74268955914426],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.835533343696412,
                    centroid_y: 40.754122488141235,
                    zone_label: 425,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.84063355977392, 40.750345329151607],
                            [-73.830566716292623, 40.750243754936456],
                            [-73.830432549591904, 40.757899487128149],
                            [-73.840500547388231, 40.75800108861187],
                            [-73.84063355977392, 40.750345329151607],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.835399728467038,
                    centroid_y: 40.761778228711471,
                    zone_label: 426,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.840500547388231, 40.75800108861187],
                            [-73.830432549591904, 40.757899487128149],
                            [-73.83029833129828, 40.765555208765996],
                            [-73.840367483853314, 40.765656837524489],
                            [-73.840500547388231, 40.75800108861187],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.835266061855393,
                    centroid_y: 40.76943395873068,
                    zone_label: 427,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.840367483853314, 40.765656837524489],
                            [-73.83029833129828, 40.765555208765996],
                            [-73.830164061389269, 40.773210919849454],
                            [-73.84023436914687, 40.773312575888895],
                            [-73.840367483853314, 40.765656837524489],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.835132343839078,
                    centroid_y: 40.777089678198323,
                    zone_label: 428,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.84023436914687, 40.773312575888895],
                            [-73.830164061389269, 40.773210919849454],
                            [-73.830029739842345, 40.780866620377942],
                            [-73.840101203246576, 40.780968303704519],
                            [-73.84023436914687, 40.773312575888895],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.834998574395669,
                    centroid_y: 40.784745387113801,
                    zone_label: 429,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.840101203246576, 40.780968303704519],
                            [-73.830029739842345, 40.780866620377942],
                            [-73.829895366635014, 40.788522310350892],
                            [-73.839967986130077, 40.788624020970794],
                            [-73.840101203246576, 40.780968303704519],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.834596957278421,
                    centroid_y: 40.807712450541665,
                    zone_label: 430,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.83970139815915, 40.803935423853048],
                            [-73.829626465148849, 40.803833658627916],
                            [-73.829491936824894, 40.811489316930853],
                            [-73.839568027259972, 40.811591109467912],
                            [-73.83970139815915, 40.803935423853048],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.834462981902121,
                    centroid_y: 40.815368117242883,
                    zone_label: 431,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.839568027259972, 40.811591109467912],
                            [-73.829491936824894, 40.811489316930853],
                            [-73.829357356750293, 40.819144964676006],
                            [-73.839434605055118, 40.819246784531174],
                            [-73.839568027259972, 40.811591109467912],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.834328954986447,
                    centroid_y: 40.823023773389131,
                    zone_label: 432,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.839434605055118, 40.819246784531174],
                            [-73.829357356750293, 40.819144964676006],
                            [-73.82922272490238, 40.826800601862786],
                            [-73.839301131522234, 40.826902449042272],
                            [-73.839434605055118, 40.819246784531174],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.834194876508789,
                    centroid_y: 40.830679418979813,
                    zone_label: 433,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.839301131522234, 40.826902449042272],
                            [-73.82922272490238, 40.826800601862786],
                            [-73.829088041258615, 40.834456228490623],
                            [-73.839167606638853, 40.834558103000639],
                            [-73.839301131522234, 40.826902449042272],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.834060746446681,
                    centroid_y: 40.838335054014415,
                    zone_label: 434,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.839167606638853, 40.834558103000639],
                            [-73.829088041258615, 40.834456228490623],
                            [-73.82895330579629, 40.842111844558985],
                            [-73.839034030382564, 40.842213746405733],
                            [-73.839167606638853, 40.834558103000639],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.833926564777599,
                    centroid_y: 40.845990678492342,
                    zone_label: 435,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.839034030382564, 40.842213746405733],
                            [-73.82895330579629, 40.842111844558985],
                            [-73.828818518492838, 40.849767450067262],
                            [-73.8389004027309, 40.849869379257001],
                            [-73.839034030382564, 40.842213746405733],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.833792331478989,
                    centroid_y: 40.85364629241306,
                    zone_label: 436,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.8389004027309, 40.849869379257001],
                            [-73.828818518492838, 40.849767450067262],
                            [-73.828683679325565, 40.857423045014947],
                            [-73.838766723661436, 40.857525001553846],
                            [-73.8389004027309, 40.849869379257001],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.833658046528271,
                    centroid_y: 40.861301895775966,
                    zone_label: 437,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.838766723661436, 40.857525001553846],
                            [-73.828683679325565, 40.857423045014947],
                            [-73.828548788271817, 40.865078629401445],
                            [-73.838632993151663, 40.865180613295749],
                            [-73.838766723661436, 40.857525001553846],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.827213249756952,
                    centroid_y: 40.654495229520904,
                    zone_label: 438,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.832306198658713, 40.650718276305952],
                            [-73.822254372266414, 40.650616181250754],
                            [-73.822119725689987, 40.658272023166781],
                            [-73.832172700624895, 40.658374145645368],
                            [-73.832306198658713, 40.650718276305952],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.827079151690043,
                    centroid_y: 40.662151079893469,
                    zone_label: 439,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.832172700624895, 40.658374145645368],
                            [-73.822119725689987, 40.658272023166781],
                            [-73.821985027370673, 40.665927854530246],
                            [-73.832039151289337, 40.666030004438426],
                            [-73.832172700624895, 40.658374145645368],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.826945002089573,
                    centroid_y: 40.669806919716287,
                    zone_label: 440,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.832039151289337, 40.666030004438426],
                            [-73.821985027370673, 40.665927854530246],
                            [-73.821850277285961, 40.673583675340552],
                            [-73.831905550629742, 40.673685852684514],
                            [-73.832039151289337, 40.666030004438426],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.826810800933217,
                    centroid_y: 40.677462748988738,
                    zone_label: 441,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.831905550629742, 40.673685852684514],
                            [-73.821850277285961, 40.673583675340552],
                            [-73.821715475413356, 40.681239485597118],
                            [-73.831771898623771, 40.681341690383078],
                            [-73.831905550629742, 40.673685852684514],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.826676548198492,
                    centroid_y: 40.685118567710298,
                    zone_label: 442,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.831771898623771, 40.681341690383078],
                            [-73.821715475413356, 40.681239485597118],
                            [-73.82158062173032, 40.688895285299381],
                            [-73.831638195249099, 40.688997517533537],
                            [-73.831771898623771, 40.681341690383078],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.826542243862974,
                    centroid_y: 40.692774375880354,
                    zone_label: 443,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.831638195249099, 40.688997517533537],
                            [-73.82158062173032, 40.688895285299381],
                            [-73.821445716214299, 40.696551074446752],
                            [-73.831504440483357, 40.696653334135313],
                            [-73.831638195249099, 40.688997517533537],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.826407887904224,
                    centroid_y: 40.700430173498347,
                    zone_label: 444,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.831504440483357, 40.696653334135313],
                            [-73.821445716214299, 40.696551074446752],
                            [-73.821310758842728, 40.70420685303867],
                            [-73.831370634304193, 40.704309140187831],
                            [-73.831504440483357, 40.696653334135313],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.826273480299719,
                    centroid_y: 40.7080859605637,
                    zone_label: 445,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.831370634304193, 40.704309140187831],
                            [-73.821310758842728, 40.70420685303867],
                            [-73.821175749593053, 40.711862621074538],
                            [-73.831236776689195, 40.711964935690524],
                            [-73.831370634304193, 40.704309140187831],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.826139021027004,
                    centroid_y: 40.715741737075824,
                    zone_label: 446,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.831236776689195, 40.711964935690524],
                            [-73.821175749593053, 40.711862621074538],
                            [-73.82104068844265, 40.719518378553786],
                            [-73.831102867615996, 40.719620720642801],
                            [-73.831236776689195, 40.711964935690524],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.826004510063569,
                    centroid_y: 40.723397503034164,
                    zone_label: 447,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.831102867615996, 40.719620720642801],
                            [-73.82104068844265, 40.719518378553786],
                            [-73.820905575368968, 40.727174125475848],
                            [-73.830968907062172, 40.727276495044123],
                            [-73.831102867615996, 40.719620720642801],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.825869947386934,
                    centroid_y: 40.731053258438124,
                    zone_label: 448,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.830968907062172, 40.727276495044123],
                            [-73.820905575368968, 40.727174125475848],
                            [-73.820770410349326, 40.734829861840119],
                            [-73.830834895005296, 40.73493225889387],
                            [-73.830968907062172, 40.727276495044123],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.825735332974503,
                    centroid_y: 40.738709003287148,
                    zone_label: 449,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.830834895005296, 40.73493225889387],
                            [-73.820770410349326, 40.734829861840119],
                            [-73.820635193361127, 40.742485587646058],
                            [-73.830700831422931, 40.742588012191518],
                            [-73.830834895005296, 40.73493225889387],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.82560066680378,
                    centroid_y: 40.746364737580656,
                    zone_label: 450,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.830700831422931, 40.742588012191518],
                            [-73.820635193361127, 40.742485587646058],
                            [-73.820499924381735, 40.750141302893084],
                            [-73.830566716292623, 40.750243754936456],
                            [-73.830700831422931, 40.742588012191518],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.825465948852198,
                    centroid_y: 40.754020461318085,
                    zone_label: 451,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.830566716292623, 40.750243754936456],
                            [-73.820499924381735, 40.750141302893084],
                            [-73.820364603388455, 40.757797007580614],
                            [-73.830432549591904, 40.757899487128149],
                            [-73.830566716292623, 40.750243754936456],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.825331179097205,
                    centroid_y: 40.761676174498838,
                    zone_label: 452,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.830432549591904, 40.757899487128149],
                            [-73.820364603388455, 40.757797007580614],
                            [-73.820229230358635, 40.765452701708057],
                            [-73.83029833129828, 40.765555208765996],
                            [-73.830432549591904, 40.757899487128149],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.825196357516177,
                    centroid_y: 40.769331877122362,
                    zone_label: 453,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.83029833129828, 40.765555208765996],
                            [-73.820229230358635, 40.765452701708057],
                            [-73.820093805269579, 40.773108385274881],
                            [-73.830164061389269, 40.773210919849454],
                            [-73.83029833129828, 40.765555208765996],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.825061484086504,
                    centroid_y: 40.776987569188108,
                    zone_label: 454,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.830164061389269, 40.773210919849454],
                            [-73.820093805269579, 40.773108385274881],
                            [-73.819958328098565, 40.780764058280504],
                            [-73.830029739842345, 40.780866620377942],
                            [-73.830164061389269, 40.773210919849454],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.82492655878562,
                    centroid_y: 40.784643250695481,
                    zone_label: 455,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.830029739842345, 40.780866620377942],
                            [-73.819958328098565, 40.780764058280504],
                            [-73.819822798822912, 40.788419720724335],
                            [-73.829895366635014, 40.788522310350892],
                            [-73.830029739842345, 40.780866620377942],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.824791581590873,
                    centroid_y: 40.792298921643898,
                    zone_label: 456,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.829895366635014, 40.788522310350892],
                            [-73.819822798822912, 40.788419720724335],
                            [-73.81968721741984, 40.79607537260582],
                            [-73.829760941744695, 40.796177989767735],
                            [-73.829895366635014, 40.788522310350892],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.824386338416886,
                    centroid_y: 40.815265871129881,
                    zone_label: 457,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.829491936824894, 40.811489316930853],
                            [-73.81941589814052, 40.811386644679459],
                            [-73.81928016021871, 40.81904226487049],
                            [-73.829357356750293, 40.819144964676006],
                            [-73.829491936824894, 40.811489316930853],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.824251153420093,
                    centroid_y: 40.822921499836895,
                    zone_label: 458,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.829357356750293, 40.819144964676006],
                            [-73.81928016021871, 40.81904226487049],
                            [-73.819144370078433, 40.82669787449688],
                            [-73.82922272490238, 40.826800601862786],
                            [-73.829357356750293, 40.819144964676006],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.824115916416062,
                    centroid_y: 40.830577117982124,
                    zone_label: 459,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.82922272490238, 40.826800601862786],
                            [-73.819144370078433, 40.82669787449688],
                            [-73.819008527696866, 40.834353473558089],
                            [-73.829088041258615, 40.834456228490623],
                            [-73.82922272490238, 40.826800601862786],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.82398062738207,
                    centroid_y: 40.838232725565,
                    zone_label: 460,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.829088041258615, 40.834456228490623],
                            [-73.819008527696866, 40.834353473558089],
                            [-73.8188726330512, 40.842009062053521],
                            [-73.82895330579629, 40.842111844558985],
                            [-73.829088041258615, 40.834456228490623],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.823845286295395,
                    centroid_y: 40.845888322584983,
                    zone_label: 461,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.82895330579629, 40.842111844558985],
                            [-73.8188726330512, 40.842009062053521],
                            [-73.818736686118584, 40.849664639982635],
                            [-73.828818518492838, 40.849767450067262],
                            [-73.82895330579629, 40.842111844558985],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.823709893133284,
                    centroid_y: 40.853543909041491,
                    zone_label: 462,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.828818518492838, 40.849767450067262],
                            [-73.818736686118584, 40.849664639982635],
                            [-73.818600686876181, 40.85732020734487],
                            [-73.828683679325565, 40.857423045014947],
                            [-73.828818518492838, 40.849767450067262],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.823574447873014,
                    centroid_y: 40.861199484933984,
                    zone_label: 463,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.828683679325565, 40.857423045014947],
                            [-73.818600686876181, 40.85732020734487],
                            [-73.818464635301112, 40.864975764139636],
                            [-73.828548788271817, 40.865078629401445],
                            [-73.828683679325565, 40.857423045014947],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.817296069829737,
                    centroid_y: 40.646736849931784,
                    zone_label: 464,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.822388967122464, 40.642960328782735],
                            [-73.812338340529749, 40.642857386622026],
                            [-73.812202597594521, 40.650513211438017],
                            [-73.822254372266414, 40.650616181250754],
                            [-73.822388967122464, 40.642960328782735],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.817160874963022,
                    centroid_y: 40.654392683315805,
                    zone_label: 465,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.822254372266414, 40.650616181250754],
                            [-73.812202597594521, 40.650513211438017],
                            [-73.812066802497966, 40.658169025695763],
                            [-73.822119725689987, 40.658272023166781],
                            [-73.822254372266414, 40.650616181250754],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.817025628144165,
                    centroid_y: 40.662048506144423,
                    zone_label: 466,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.822119725689987, 40.658272023166781],
                            [-73.812066802497966, 40.658169025695763],
                            [-73.811930955217392, 40.665824829394694],
                            [-73.821985027370673, 40.665927854530246],
                            [-73.822119725689987, 40.658272023166781],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.816890329350542,
                    centroid_y: 40.669704318417061,
                    zone_label: 467,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.821985027370673, 40.665927854530246],
                            [-73.811930955217392, 40.665824829394694],
                            [-73.811795055730101, 40.673480622534214],
                            [-73.821850277285961, 40.673583675340552],
                            [-73.821985027370673, 40.665927854530246],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.816754978559558,
                    centroid_y: 40.677360120133123,
                    zone_label: 468,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.821850277285961, 40.673583675340552],
                            [-73.811795055730101, 40.673480622534214],
                            [-73.811659104013387, 40.681136405113755],
                            [-73.821715475413356, 40.681239485597118],
                            [-73.821850277285961, 40.673583675340552],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.816619575748589,
                    centroid_y: 40.685015911292048,
                    zone_label: 469,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.821715475413356, 40.681239485597118],
                            [-73.811659104013387, 40.681136405113755],
                            [-73.811523100044525, 40.688792177132733],
                            [-73.82158062173032, 40.688895285299381],
                            [-73.821715475413356, 40.681239485597118],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.816484120895026,
                    centroid_y: 40.692671691893253,
                    zone_label: 470,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.82158062173032, 40.688895285299381],
                            [-73.811523100044525, 40.688792177132733],
                            [-73.811387043800778, 40.696447938590573],
                            [-73.821445716214299, 40.696551074446752],
                            [-73.82158062173032, 40.688895285299381],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.816348613976174,
                    centroid_y: 40.700327461936155,
                    zone_label: 471,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.821445716214299, 40.696551074446752],
                            [-73.811387043800778, 40.696447938590573],
                            [-73.811250935259395, 40.704103689486679],
                            [-73.821310758842728, 40.70420685303867],
                            [-73.821445716214299, 40.696551074446752],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.816213054969438,
                    centroid_y: 40.707983221420179,
                    zone_label: 472,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.821310758842728, 40.70420685303867],
                            [-73.811250935259395, 40.704103689486679],
                            [-73.811114774397609, 40.711759429820468],
                            [-73.821175749593053, 40.711862621074538],
                            [-73.821310758842728, 40.70420685303867],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.816077443852052,
                    centroid_y: 40.715638970344727,
                    zone_label: 473,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.821175749593053, 40.711862621074538],
                            [-73.811114774397609, 40.711759429820468],
                            [-73.810978561192627, 40.719415159591371],
                            [-73.82104068844265, 40.719518378553786],
                            [-73.821175749593053, 40.711862621074538],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.815941780601406,
                    centroid_y: 40.723294708709254,
                    zone_label: 474,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.82104068844265, 40.719518378553786],
                            [-73.810978561192627, 40.719415159591371],
                            [-73.810842295621683, 40.72707087879882],
                            [-73.820905575368968, 40.727174125475848],
                            [-73.82104068844265, 40.719518378553786],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.815806065194735,
                    centroid_y: 40.730950436513169,
                    zone_label: 475,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.820905575368968, 40.727174125475848],
                            [-73.810842295621683, 40.72707087879882],
                            [-73.810705977661911, 40.734726587442196],
                            [-73.820770410349326, 40.734829861840119],
                            [-73.820905575368968, 40.727174125475848],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.81567029760933,
                    centroid_y: 40.738606153755867,
                    zone_label: 476,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.820770410349326, 40.734829861840119],
                            [-73.810705977661911, 40.734726587442196],
                            [-73.810569607290546, 40.742382285520982],
                            [-73.820635193361127, 40.742485587646058],
                            [-73.820770410349326, 40.734829861840119],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.815534477822496,
                    centroid_y: 40.746261860436825,
                    zone_label: 477,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.820635193361127, 40.742485587646058],
                            [-73.810569607290546, 40.742382285520982],
                            [-73.810433184484708, 40.750037973034551],
                            [-73.820499924381735, 40.750141302893084],
                            [-73.820635193361127, 40.742485587646058],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.81539860581141,
                    centroid_y: 40.753917556555429,
                    zone_label: 478,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.820499924381735, 40.750141302893084],
                            [-73.810433184484708, 40.750037973034551],
                            [-73.810296709221561, 40.757693649982357],
                            [-73.820364603388455, 40.757797007580614],
                            [-73.820499924381735, 40.750141302893084],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.815262681553349,
                    centroid_y: 40.761573242111112,
                    zone_label: 479,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.820364603388455, 40.757797007580614],
                            [-73.810296709221561, 40.757693649982357],
                            [-73.810160181478224, 40.765349316363782],
                            [-73.820229230358635, 40.765452701708057],
                            [-73.820364603388455, 40.757797007580614],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.815126705025563,
                    centroid_y: 40.769228917103312,
                    zone_label: 480,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.820229230358635, 40.765452701708057],
                            [-73.810160181478224, 40.765349316363782],
                            [-73.810023601231819, 40.773004972178299],
                            [-73.820093805269579, 40.773108385274881],
                            [-73.820229230358635, 40.765452701708057],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.81499067620517,
                    centroid_y: 40.776884581531441,
                    zone_label: 481,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.820093805269579, 40.773108385274881],
                            [-73.810023601231819, 40.773004972178299],
                            [-73.809886968459438, 40.780660617425298],
                            [-73.819958328098565, 40.780764058280504],
                            [-73.820093805269579, 40.773108385274881],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.814854595069434,
                    centroid_y: 40.784540235394935,
                    zone_label: 482,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.819958328098565, 40.780764058280504],
                            [-73.809886968459438, 40.780660617425298],
                            [-73.8097502831382, 40.788316252104245],
                            [-73.819822798822912, 40.788419720724335],
                            [-73.819958328098565, 40.780764058280504],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.814718461595533,
                    centroid_y: 40.792195878693214,
                    zone_label: 483,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.819822798822912, 40.788419720724335],
                            [-73.8097502831382, 40.788316252104245],
                            [-73.809613545245142, 40.795971876214516],
                            [-73.81968721741984, 40.79607537260582],
                            [-73.819822798822912, 40.788419720724335],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.81430974691618,
                    centroid_y: 40.815162745191081,
                    zone_label: 484,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.81941589814052, 40.811386644679459],
                            [-73.809339911651819, 40.811283092726839],
                            [-73.809203015905624, 40.818938685127733],
                            [-73.81928016021871, 40.81904226487049],
                            [-73.81941589814052, 40.811386644679459],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.814173403861005,
                    centroid_y: 40.822818346222824,
                    zone_label: 485,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.81928016021871, 40.81904226487049],
                            [-73.809203015905624, 40.818938685127733],
                            [-73.809066067495763, 40.826594266957699],
                            [-73.819144370078433, 40.82669787449688],
                            [-73.81928016021871, 40.81904226487049],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.813764059888612,
                    centroid_y: 40.845785085907387,
                    zone_label: 486,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.8188726330512, 40.842009062053521],
                            [-73.808792012593031, 40.841905398902519],
                            [-73.808654906054116, 40.849560949016244],
                            [-73.818736686118584, 40.849664639982635],
                            [-73.8188726330512, 40.842009062053521],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.807244895276284,
                    centroid_y: 40.646633456630838,
                    zone_label: 487,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.812338340529749, 40.642857386622026],
                            [-73.802287766075693, 40.642753569951708],
                            [-73.802150875083953, 40.65040936688083],
                            [-73.812202597594521, 40.650513211438017],
                            [-73.812338340529749, 40.642857386622026],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.807108552121235,
                    centroid_y: 40.654289262242266,
                    zone_label: 488,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.812202597594521, 40.650513211438017],
                            [-73.802150875083953, 40.65040936688083],
                            [-73.802013931489952, 40.658065153245417],
                            [-73.812066802497966, 40.658169025695763],
                            [-73.812202597594521, 40.650513211438017],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.806972156573011,
                    centroid_y: 40.661945057292023,
                    zone_label: 489,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.812066802497966, 40.658169025695763],
                            [-73.802013931489952, 40.658065153245417],
                            [-73.801876935270812, 40.665720929044902],
                            [-73.811930955217392, 40.665824829394694],
                            [-73.812066802497966, 40.658169025695763],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.806835708608816,
                    centroid_y: 40.669600841779534,
                    zone_label: 490,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.811930955217392, 40.665824829394694],
                            [-73.801876935270812, 40.665720929044902],
                            [-73.801739886403638, 40.673376694278666],
                            [-73.811795055730101, 40.673480622534214],
                            [-73.811930955217392, 40.665824829394694],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.806699208205856,
                    centroid_y: 40.677256615704188,
                    zone_label: 491,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.811795055730101, 40.673480622534214],
                            [-73.801739886403638, 40.673376694278666],
                            [-73.801602784865537, 40.681032448946141],
                            [-73.811659104013387, 40.681136405113755],
                            [-73.811795055730101, 40.673480622534214],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.806562655341281,
                    centroid_y: 40.684912379065423,
                    zone_label: 492,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.811659104013387, 40.681136405113755],
                            [-73.801602784865537, 40.681032448946141],
                            [-73.801465630633601, 40.688688193046744],
                            [-73.811523100044525, 40.688792177132733],
                            [-73.811659104013387, 40.681136405113755],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.806426049992325,
                    centroid_y: 40.692568131862664,
                    zone_label: 493,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.811523100044525, 40.688792177132733],
                            [-73.801465630633601, 40.688688193046744],
                            [-73.801328423684893, 40.6963439265799],
                            [-73.811387043800778, 40.696447938590573],
                            [-73.811523100044525, 40.688792177132733],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.806289392136094,
                    centroid_y: 40.7002238740953,
                    zone_label: 494,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.811387043800778, 40.696447938590573],
                            [-73.801328423684893, 40.6963439265799],
                            [-73.801191163996464, 40.703999649544997],
                            [-73.811250935259395, 40.704103689486679],
                            [-73.811387043800778, 40.696447938590573],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.806152681749751,
                    centroid_y: 40.707879605762763,
                    zone_label: 495,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.811250935259395, 40.704103689486679],
                            [-73.801191163996464, 40.703999649544997],
                            [-73.801053851545348, 40.711655361941474],
                            [-73.811114774397609, 40.711759429820468],
                            [-73.811250935259395, 40.704103689486679],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.806015918810431,
                    centroid_y: 40.715535326864483,
                    zone_label: 496,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.811114774397609, 40.711759429820468],
                            [-73.801053851545348, 40.711655361941474],
                            [-73.800916486308594, 40.719311063768728],
                            [-73.810978561192627, 40.719415159591371],
                            [-73.811114774397609, 40.711759429820468],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.80587910329524,
                    centroid_y: 40.723191037399857,
                    zone_label: 497,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.810978561192627, 40.719415159591371],
                            [-73.800916486308594, 40.719311063768728],
                            [-73.800779068263182, 40.726966755026204],
                            [-73.810842295621683, 40.72707087879882],
                            [-73.810978561192627, 40.719415159591371],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.805742235181285,
                    centroid_y: 40.730846737368324,
                    zone_label: 498,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.810842295621683, 40.72707087879882],
                            [-73.800779068263182, 40.726966755026204],
                            [-73.800641597386118, 40.734622435713305],
                            [-73.810705977661911, 40.734726587442196],
                            [-73.810842295621683, 40.72707087879882],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.805605314445643,
                    centroid_y: 40.738502426769294,
                    zone_label: 499,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.810705977661911, 40.734726587442196],
                            [-73.800641597386118, 40.734622435713305],
                            [-73.800504073654409, 40.742278105829456],
                            [-73.810569607290546, 40.742382285520982],
                            [-73.810705977661911, 40.734726587442196],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.805468341065406,
                    centroid_y: 40.746158105602198,
                    zone_label: 500,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.810569607290546, 40.742382285520982],
                            [-73.800504073654409, 40.742278105829456],
                            [-73.800366497045005, 40.74993376537406],
                            [-73.810433184484708, 40.750037973034551],
                            [-73.810569607290546, 40.742382285520982],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.805331315017611,
                    centroid_y: 40.75381377386644,
                    zone_label: 501,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.810433184484708, 40.750037973034551],
                            [-73.800366497045005, 40.74993376537406],
                            [-73.800228867534841, 40.757589414346555],
                            [-73.810296709221561, 40.757693649982357],
                            [-73.810433184484708, 40.750037973034551],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.805194236279291,
                    centroid_y: 40.761469431561451,
                    zone_label: 502,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.810296709221561, 40.757693649982357],
                            [-73.800228867534841, 40.757589414346555],
                            [-73.800091185100868, 40.765245052746351],
                            [-73.810160181478224, 40.765349316363782],
                            [-73.810296709221561, 40.757693649982357],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.805057104827483,
                    centroid_y: 40.769125078686656,
                    zone_label: 503,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.810160181478224, 40.765349316363782],
                            [-73.800091185100868, 40.765245052746351],
                            [-73.799953449720036, 40.772900680572882],
                            [-73.810023601231819, 40.773004972178299],
                            [-73.810160181478224, 40.765349316363782],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.804919920639207,
                    centroid_y: 40.776780715241479,
                    zone_label: 504,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.810023601231819, 40.773004972178299],
                            [-73.799953449720036, 40.772900680572882],
                            [-73.799815661369209, 40.780556297825569],
                            [-73.809886968459438, 40.780660617425298],
                            [-73.810023601231819, 40.773004972178299],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.804782683691457,
                    centroid_y: 40.784436341225344,
                    zone_label: 505,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.809886968459438, 40.780660617425298],
                            [-73.799815661369209, 40.780556297825569],
                            [-73.799677820025295, 40.788211904503804],
                            [-73.8097502831382, 40.788316252104245],
                            [-73.809886968459438, 40.780660617425298],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.804645393961195,
                    centroid_y: 40.792091956637663,
                    zone_label: 506,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.8097502831382, 40.788316252104245],
                            [-73.799677820025295, 40.788211904503804],
                            [-73.799539925665187, 40.795867500607052],
                            [-73.809613545245142, 40.795971876214516],
                            [-73.8097502831382, 40.788316252104245],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.804233207845115,
                    centroid_y: 40.815058739439692,
                    zone_label: 507,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.809339911651819, 40.811283092726839],
                            [-73.79926397780379, 40.811178661086245],
                            [-73.799125924256188, 40.818834225461018],
                            [-73.809203015905624, 40.818938685127733],
                            [-73.809339911651819, 40.811283092726839],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.803545173182513,
                    centroid_y: 40.853336499292162,
                    zone_label: 508,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.808654906054116, 40.849560949016244],
                            [-73.7985731787454, 40.849456377181362],
                            [-73.798434859421519, 40.857111888663887],
                            [-73.808517746759449, 40.857216488556745],
                            [-73.808654906054116, 40.849560949016244],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.803407407371665,
                    centroid_y: 40.860992019534883,
                    zone_label: 509,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.808517746759449, 40.857216488556745],
                            [-73.798434859421519, 40.857111888663887],
                            [-73.798296486872673, 40.864767389566239],
                            [-73.808380534685938, 40.864872017523481],
                            [-73.808517746759449, 40.857216488556745],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.797331211702584,
                    centroid_y: 40.638873400538067,
                    zone_label: 510,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.80242460448801, 40.635097762458656],
                            [-73.792375230206318, 40.634993099407275],
                            [-73.792237244201047, 40.642648878784996],
                            [-73.802287766075693, 40.642753569951708],
                            [-73.80242460448801, 40.635097762458656],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.797193773093269,
                    centroid_y: 40.646529188709458,
                    zone_label: 511,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.802287766075693, 40.642753569951708],
                            [-73.792237244201047, 40.642648878784996],
                            [-73.79209920517566, 40.650304647592442],
                            [-73.802150875083953, 40.65040936688083],
                            [-73.802287766075693, 40.642753569951708],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.797056281672653,
                    centroid_y: 40.654184966313458,
                    zone_label: 512,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.802150875083953, 40.65040936688083],
                            [-73.79209920517566, 40.650304647592442],
                            [-73.791961113107092, 40.657960405829009],
                            [-73.802013931489952, 40.658065153245417],
                            [-73.802150875083953, 40.65040936688083],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.796918737417869,
                    centroid_y: 40.661840733349472,
                    zone_label: 513,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.802013931489952, 40.658065153245417],
                            [-73.791961113107092, 40.657960405829009],
                            [-73.791822967972251, 40.665616153494099],
                            [-73.801876935270812, 40.665720929044902],
                            [-73.802013931489952, 40.658065153245417],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.796781140305882,
                    centroid_y: 40.669496489816886,
                    zone_label: 514,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.801876935270812, 40.665720929044902],
                            [-73.791822967972251, 40.665616153494099],
                            [-73.791684769748116, 40.673271890587131],
                            [-73.801739886403638, 40.673376694278666],
                            [-73.801876935270812, 40.665720929044902],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.796643490313727,
                    centroid_y: 40.677152235715141,
                    zone_label: 515,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.801739886403638, 40.673376694278666],
                            [-73.791684769748116, 40.673271890587131],
                            [-73.79154651841155, 40.680927617107514],
                            [-73.801602784865537, 40.681032448946141],
                            [-73.801739886403638, 40.673376694278666],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.796505787418383,
                    centroid_y: 40.684807971043611,
                    zone_label: 516,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.801602784865537, 40.681032448946141],
                            [-73.79154651841155, 40.680927617107514],
                            [-73.791408213939462, 40.688583333054666],
                            [-73.801465630633601, 40.688688193046744],
                            [-73.801602784865537, 40.681032448946141],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.796368031596842,
                    centroid_y: 40.69246369580177,
                    zone_label: 517,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.801465630633601, 40.688688193046744],
                            [-73.791408213939462, 40.688583333054666],
                            [-73.791269856308716, 40.696239038427997],
                            [-73.801328423684893, 40.6963439265799],
                            [-73.801465630633601, 40.688688193046744],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.796230222826097,
                    centroid_y: 40.700119409988986,
                    zone_label: 518,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.801328423684893, 40.6963439265799],
                            [-73.791269856308716, 40.696239038427997],
                            [-73.791131445496205, 40.703894733226896],
                            [-73.801191163996464, 40.703999649544997],
                            [-73.801328423684893, 40.6963439265799],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.796092361083041,
                    centroid_y: 40.707775113604683,
                    zone_label: 519,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.801191163996464, 40.703999649544997],
                            [-73.791131445496205, 40.703894733226896],
                            [-73.790992981478738, 40.711550417450816],
                            [-73.801053851545348, 40.711655361941474],
                            [-73.801191163996464, 40.703999649544997],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.795954446344652,
                    centroid_y: 40.715430806648286,
                    zone_label: 520,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.801053851545348, 40.711655361941474],
                            [-73.790992981478738, 40.711550417450816],
                            [-73.790854464233178, 40.719206091099139],
                            [-73.800916486308594, 40.719311063768728],
                            [-73.801053851545348, 40.711655361941474],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.795816478587824,
                    centroid_y: 40.723086489119197,
                    zone_label: 521,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.800916486308594, 40.719311063768728],
                            [-73.790854464233178, 40.719206091099139],
                            [-73.790715893736333, 40.726861754171303],
                            [-73.800779068263182, 40.726966755026204],
                            [-73.800916486308594, 40.719311063768728],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.795678457789521,
                    centroid_y: 40.730742161016849,
                    zone_label: 522,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.800779068263182, 40.726966755026204],
                            [-73.790715893736333, 40.726861754171303],
                            [-73.790577269964999, 40.734517406666704],
                            [-73.800641597386118, 40.734622435713305],
                            [-73.800779068263182, 40.726966755026204],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.79554038392655,
                    centroid_y: 40.738397822340644,
                    zone_label: 523,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.800641597386118, 40.734622435713305],
                            [-73.790577269964999, 40.734517406666704],
                            [-73.790438592895967, 40.742173048584775],
                            [-73.800504073654409, 40.742278105829456],
                            [-73.800641597386118, 40.734622435713305],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.795402256975876,
                    centroid_y: 40.746053473090008,
                    zone_label: 524,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.800504073654409, 40.742278105829456],
                            [-73.790438592895967, 40.742173048584775],
                            [-73.790299862506032, 40.74982867992491],
                            [-73.800366497045005, 40.74993376537406],
                            [-73.800504073654409, 40.742278105829456],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.795264076914293,
                    centroid_y: 40.753709113264357,
                    zone_label: 525,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.800366497045005, 40.74993376537406],
                            [-73.790299862506032, 40.74982867992491],
                            [-73.790161078771916, 40.757484300686542],
                            [-73.800228867534841, 40.757589414346555],
                            [-73.800366497045005, 40.74993376537406],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.795125843718694,
                    centroid_y: 40.761364742863094,
                    zone_label: 526,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.800228867534841, 40.757589414346555],
                            [-73.790161078771916, 40.757484300686542],
                            [-73.790022241670414, 40.765139910869095],
                            [-73.800091185100868, 40.765245052746351],
                            [-73.800228867534841, 40.757589414346555],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.7949875573659,
                    centroid_y: 40.76902036188568,
                    zone_label: 527,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.800091185100868, 40.765245052746351],
                            [-73.790022241670414, 40.765139910869095],
                            [-73.789883351178204, 40.772795510471973],
                            [-73.799953449720036, 40.772900680572882],
                            [-73.800091185100868, 40.765245052746351],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.79484921783272,
                    centroid_y: 40.776675970331482,
                    zone_label: 528,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.799953449720036, 40.772900680572882],
                            [-73.789883351178204, 40.772795510471973],
                            [-73.789744407272039, 40.780451099494591],
                            [-73.799815661369209, 40.780556297825569],
                            [-73.799953449720036, 40.772900680572882],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.794710825095962,
                    centroid_y: 40.784331568199967,
                    zone_label: 529,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.799815661369209, 40.780556297825569],
                            [-73.789744407272039, 40.780451099494591],
                            [-73.789605409928598, 40.788106677936383],
                            [-73.799677820025295, 40.788211904503804],
                            [-73.799815661369209, 40.780556297825569],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.794295327432025,
                    centroid_y: 40.807298298335617,
                    zone_label: 530,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.799401978265735, 40.803523086134724],
                            [-73.789327254836621, 40.803417803075163],
                            [-73.789188097041418, 40.811073349770979],
                            [-73.79926397780379, 40.811178661086245],
                            [-73.799401978265735, 40.803523086134724],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.793323966418029,
                    centroid_y: 40.86088696500434,
                    zone_label: 531,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.798434859421519, 40.857111888663887],
                            [-73.788352025308569, 40.857006407679648],
                            [-73.788212492307636, 40.864661880281332],
                            [-73.798296486872673, 40.864767389566239],
                            [-73.798434859421519, 40.857111888663887],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.787281290132583,
                    centroid_y: 40.638768286245444,
                    zone_label: 532,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.792375230206318, 40.634993099407275],
                            [-73.782325908921692, 40.634887562107586],
                            [-73.782186775346531, 40.642543313135221],
                            [-73.792237244201047, 40.642648878784996],
                            [-73.792375230206318, 40.634993099407275],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.787142703721457,
                    centroid_y: 40.64642404618094,
                    zone_label: 533,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.792237244201047, 40.642648878784996],
                            [-73.782186775346531, 40.642543313135221],
                            [-73.782047588310533, 40.650199053586185],
                            [-73.79209920517566, 40.650304647592442],
                            [-73.792237244201047, 40.642648878784996],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.78700406405828,
                    centroid_y: 40.654079795542678,
                    zone_label: 534,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.79209920517566, 40.650304647592442],
                            [-73.782047588310533, 40.650199053586185],
                            [-73.781908347790448, 40.657854783459854],
                            [-73.791961113107092, 40.657960405829009],
                            [-73.79209920517566, 40.650304647592442],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.786865371119887,
                    centroid_y: 40.661735534330035,
                    zone_label: 535,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.791961113107092, 40.657960405829009],
                            [-73.781908347790448, 40.657854783459854],
                            [-73.781769053763028, 40.665510502755659],
                            [-73.791822967972251, 40.665616153494099],
                            [-73.791961113107092, 40.657960405829009],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.786726624883102,
                    centroid_y: 40.669391262542419,
                    zone_label: 536,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.791822967972251, 40.665616153494099],
                            [-73.781769053763028, 40.665510502755659],
                            [-73.781629706204996, 40.673166211472982],
                            [-73.791684769748116, 40.673271890587131],
                            [-73.791822967972251, 40.665616153494099],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.786587825324773,
                    centroid_y: 40.677046980179263,
                    zone_label: 537,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.791684769748116, 40.673271890587131],
                            [-73.781629706204996, 40.673166211472982],
                            [-73.781490305093087, 40.680821909611254],
                            [-73.79154651841155, 40.680927617107514],
                            [-73.791684769748116, 40.673271890587131],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.786448972421681,
                    centroid_y: 40.684702687239955,
                    zone_label: 538,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.79154651841155, 40.680927617107514],
                            [-73.781490305093087, 40.680821909611254],
                            [-73.781350850403967, 40.688477597169872],
                            [-73.791408213939462, 40.688583333054666],
                            [-73.79154651841155, 40.680927617107514],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.786310066150619,
                    centroid_y: 40.692358383723906,
                    zone_label: 539,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.791408213939462, 40.688583333054666],
                            [-73.781350850403967, 40.688477597169872],
                            [-73.781211342114332, 40.696133274148238],
                            [-73.791269856308716, 40.696239038427997],
                            [-73.791408213939462, 40.688583333054666],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.786171106488368,
                    centroid_y: 40.700014069630541,
                    zone_label: 540,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.791269856308716, 40.696239038427997],
                            [-73.781211342114332, 40.696133274148238],
                            [-73.781071780200875, 40.703788940545778],
                            [-73.791131445496205, 40.703894733226896],
                            [-73.791269856308716, 40.696239038427997],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.786032093411691,
                    centroid_y: 40.707669744959254,
                    zone_label: 541,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.791131445496205, 40.703894733226896],
                            [-73.781071780200875, 40.703788940545778],
                            [-73.780932164640248, 40.711444596361893],
                            [-73.790992981478738, 40.711550417450816],
                            [-73.791131445496205, 40.703894733226896],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.785893026897298,
                    centroid_y: 40.715325409709472,
                    zone_label: 542,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.790992981478738, 40.711550417450816],
                            [-73.780932164640248, 40.711444596361893],
                            [-73.780792495409059, 40.719100241595996],
                            [-73.790854464233178, 40.719206091099139],
                            [-73.790992981478738, 40.711550417450816],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.785753906921954,
                    centroid_y: 40.722981063880603,
                    zone_label: 543,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.790854464233178, 40.719206091099139],
                            [-73.780792495409059, 40.719100241595996],
                            [-73.780652772483961, 40.726755876247502],
                            [-73.790715893736333, 40.726861754171303],
                            [-73.790854464233178, 40.719206091099139],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.785614733462339,
                    centroid_y: 40.730636707472065,
                    zone_label: 544,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.790715893736333, 40.726861754171303],
                            [-73.780652772483961, 40.726755876247502],
                            [-73.780512995841562, 40.734411500315801],
                            [-73.790577269964999, 40.734517406666704],
                            [-73.790715893736333, 40.726861754171303],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.785475506495217,
                    centroid_y: 40.738292340483255,
                    zone_label: 545,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.790577269964999, 40.734517406666704],
                            [-73.780512995841562, 40.734411500315801],
                            [-73.780373165458442, 40.742067113800346],
                            [-73.790438592895967, 40.742173048584775],
                            [-73.790577269964999, 40.734517406666704],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.785336225997199,
                    centroid_y: 40.745947962913611,
                    zone_label: 546,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.790438592895967, 40.742173048584775],
                            [-73.780373165458442, 40.742067113800346],
                            [-73.780233281311212, 40.749722716700518],
                            [-73.790299862506032, 40.74982867992491],
                            [-73.790438592895967, 40.742173048584775],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.785196891945034,
                    centroid_y: 40.753603574762529,
                    zone_label: 547,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.790299862506032, 40.74982867992491],
                            [-73.780233281311212, 40.749722716700518],
                            [-73.780093343376421, 40.757378309015735],
                            [-73.790161078771916, 40.757484300686542],
                            [-73.790299862506032, 40.74982867992491],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.785057504315304,
                    centroid_y: 40.761259176029426,
                    zone_label: 548,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.790161078771916, 40.757484300686542],
                            [-73.780093343376421, 40.757378309015735],
                            [-73.779953351630638, 40.765033890745421],
                            [-73.790022241670414, 40.765139910869095],
                            [-73.790161078771916, 40.757484300686542],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.784918063084689,
                    centroid_y: 40.768914766713728,
                    zone_label: 549,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.790022241670414, 40.765139910869095],
                            [-73.779953351630638, 40.765033890745421],
                            [-73.779813306050372, 40.772689461888973],
                            [-73.789883351178204, 40.772795510471973],
                            [-73.790022241670414, 40.765139910869095],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.784778568229797,
                    centroid_y: 40.77657034681485,
                    zone_label: 550,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.789883351178204, 40.772795510471973],
                            [-73.779813306050372, 40.772689461888973],
                            [-73.779673206612131, 40.780345022445836],
                            [-73.789744407272039, 40.780451099494591],
                            [-73.789883351178204, 40.772795510471973],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.784639019727237,
                    centroid_y: 40.78422591633219,
                    zone_label: 551,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.789744407272039, 40.780451099494591],
                            [-73.779673206612131, 40.780345022445836],
                            [-73.779533053292468, 40.788000572415413],
                            [-73.789605409928598, 40.788106677936383],
                            [-73.789744407272039, 40.780451099494591],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.78366067610709,
                    centroid_y: 40.83781460655949,
                    zone_label: 552,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.78877030237858, 40.834039926357264],
                            [-73.778691000546658, 40.833933649867156],
                            [-73.7785504693978, 40.841589125709412],
                            [-73.788630930320593, 40.841695430717031],
                            [-73.78877030237858, 40.834039926357264],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.783520697579448,
                    centroid_y: 40.84547009138582,
                    zone_label: 553,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.788630930320593, 40.841695430717031],
                            [-73.7785504693978, 40.841589125709412],
                            [-73.778409884179041, 40.849244590959721],
                            [-73.788491504638401, 40.849350924491354],
                            [-73.788630930320593, 40.841695430717031],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.77723142179147,
                    centroid_y: 40.638662297593854,
                    zone_label: 554,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.782325908921692, 40.634887562107586],
                            [-73.772276641074697, 40.634781150573033],
                            [-73.772136359952867, 40.642436873015832],
                            [-73.782186775346531, 40.642543313135221],
                            [-73.782325908921692, 40.634887562107586],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.777091687601725,
                    centroid_y: 40.646318029058691,
                    zone_label: 555,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.782186775346531, 40.642543313135221],
                            [-73.772136359952867, 40.642436873015832],
                            [-73.771996024929507, 40.650092584875509],
                            [-73.782047588310533, 40.650199053586185],
                            [-73.782186775346531, 40.642543313135221],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.776951899719108,
                    centroid_y: 40.653973749943312,
                    zone_label: 556,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.782047588310533, 40.650199053586185],
                            [-73.771996024929507, 40.650092584875509],
                            [-73.771855635981169, 40.657748286151424],
                            [-73.781908347790448, 40.657854783459854],
                            [-73.782047588310533, 40.650199053586185],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.776812058120271,
                    centroid_y: 40.661629460247134,
                    zone_label: 557,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.781908347790448, 40.657854783459854],
                            [-73.771855635981169, 40.657748286151424],
                            [-73.771715193084418, 40.66540397684301],
                            [-73.781769053763028, 40.665510502755659],
                            [-73.781908347790448, 40.657854783459854],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.776672162781907,
                    centroid_y: 40.669285159969554,
                    zone_label: 558,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.781769053763028, 40.665510502755659],
                            [-73.771715193084418, 40.66540397684301],
                            [-73.771574696215779, 40.673059656949675],
                            [-73.781629706204996, 40.673166211472982],
                            [-73.781769053763028, 40.665510502755659],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.776532213680582,
                    centroid_y: 40.676940849109975,
                    zone_label: 559,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.781629706204996, 40.673166211472982],
                            [-73.771574696215779, 40.673059656949675],
                            [-73.77143414535179, 40.680715326470803],
                            [-73.781490305093087, 40.680821909611254],
                            [-73.781629706204996, 40.673166211472982],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.776392210792949,
                    centroid_y: 40.684596527667821,
                    zone_label: 560,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.781490305093087, 40.680821909611254],
                            [-73.77143414535179, 40.680715326470803],
                            [-73.771293540468974, 40.688370985405811],
                            [-73.781350850403967, 40.688477597169872],
                            [-73.781490305093087, 40.680821909611254],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.776252154095587,
                    centroid_y: 40.692252195642482,
                    zone_label: 561,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.781350850403967, 40.688477597169872],
                            [-73.771293540468974, 40.688370985405811],
                            [-73.771152881543784, 40.696026633754116],
                            [-73.781211342114332, 40.696133274148238],
                            [-73.781350850403967, 40.688477597169872],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.776112043565064,
                    centroid_y: 40.699907853033388,
                    zone_label: 562,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.781211342114332, 40.696133274148238],
                            [-73.771152881543784, 40.696026633754116],
                            [-73.77101216855273, 40.703682271515113],
                            [-73.781071780200875, 40.703788940545778],
                            [-73.781211342114332, 40.696133274148238],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.775971879178002,
                    centroid_y: 40.707563499839914,
                    zone_label: 563,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.781071780200875, 40.703788940545778],
                            [-73.77101216855273, 40.703682271515113],
                            [-73.770871401472277, 40.711337898688207],
                            [-73.780932164640248, 40.711444596361893],
                            [-73.781071780200875, 40.703788940545778],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.775831660910882,
                    centroid_y: 40.715219136061485,
                    zone_label: 564,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.780932164640248, 40.711444596361893],
                            [-73.770871401472277, 40.711337898688207],
                            [-73.770730580278851, 40.718993515272793],
                            [-73.780792495409059, 40.719100241595996],
                            [-73.780932164640248, 40.711444596361893],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.775691388740285,
                    centroid_y: 40.722874761697526,
                    zone_label: 565,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.780792495409059, 40.719100241595996],
                            [-73.770730580278851, 40.718993515272793],
                            [-73.770589704948875, 40.726649121268302],
                            [-73.780652772483961, 40.726755876247502],
                            [-73.780792495409059, 40.719100241595996],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.775551062642734,
                    centroid_y: 40.730530376747424,
                    zone_label: 566,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.780652772483961, 40.726755876247502],
                            [-73.770589704948875, 40.726649121268302],
                            [-73.770448775458817, 40.734304716674117],
                            [-73.780512995841562, 40.734411500315801],
                            [-73.780652772483961, 40.726755876247502],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.775410682594725,
                    centroid_y: 40.738185981210592,
                    zone_label: 567,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.780512995841562, 40.734411500315801],
                            [-73.770448775458817, 40.734304716674117],
                            [-73.770307791785058, 40.741960301489691],
                            [-73.780373165458442, 40.742067113800346],
                            [-73.780512995841562, 40.734411500315801],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.775270248572753,
                    centroid_y: 40.745841575086466,
                    zone_label: 568,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.780373165458442, 40.742067113800346],
                            [-73.770307791785058, 40.741960301489691],
                            [-73.770166753903965, 40.749615875714397],
                            [-73.780233281311212, 40.749722716700518],
                            [-73.780373165458442, 40.742067113800346],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.77512976055327,
                    centroid_y: 40.753497158374422,
                    zone_label: 569,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.780233281311212, 40.749722716700518],
                            [-73.770166753903965, 40.749615875714397],
                            [-73.770025661791934, 40.757271439347655],
                            [-73.780093343376421, 40.757378309015735],
                            [-73.780233281311212, 40.749722716700518],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.7749892185128,
                    centroid_y: 40.761152731073899,
                    zone_label: 570,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.780093343376421, 40.757378309015735],
                            [-73.770025661791934, 40.757271439347655],
                            [-73.769884515425318, 40.764926992388872],
                            [-73.779953351630638, 40.765033890745421],
                            [-73.780093343376421, 40.757378309015735],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.774848622427726,
                    centroid_y: 40.7688082931843,
                    zone_label: 571,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.779953351630638, 40.765033890745421],
                            [-73.769884515425318, 40.764926992388872],
                            [-73.769743314780456, 40.772582534837468],
                            [-73.779813306050372, 40.772689461888973],
                            [-73.779953351630638, 40.765033890745421],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.774707972274541,
                    centroid_y: 40.776463844705027,
                    zone_label: 572,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.779813306050372, 40.772689461888973],
                            [-73.769743314780456, 40.772582534837468],
                            [-73.769602059833687, 40.780238066692846],
                            [-73.779673206612131, 40.780345022445836],
                            [-73.779813306050372, 40.772689461888973],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.774567268029571,
                    centroid_y: 40.78411938563552,
                    zone_label: 573,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.779673206612131, 40.780345022445836],
                            [-73.769602059833687, 40.780238066692846],
                            [-73.769460750561308, 40.787893587954429],
                            [-73.779533053292468, 40.788000572415413],
                            [-73.779673206612131, 40.780345022445836],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.774426509669311,
                    centroid_y: 40.791774915975189,
                    zone_label: 574,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.779533053292468, 40.788000572415413],
                            [-73.769460750561308, 40.787893587954429],
                            [-73.769319386939628, 40.795549098621628],
                            [-73.779392846067864, 40.795656111797108],
                            [-73.779533053292468, 40.788000572415413],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.767181607119937,
                    centroid_y: 40.638555434596803,
                    zone_label: 575,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.772276641074697, 40.634781150573033],
                            [-73.762227427105813, 40.634673864817174],
                            [-73.762085998460734, 40.64232955844038],
                            [-73.772136359952867, 40.642436873015832],
                            [-73.772276641074697, 40.634781150573033],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.767040725174851,
                    centroid_y: 40.646211137356183,
                    zone_label: 576,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.772136359952867, 40.642436873015832],
                            [-73.762085998460734, 40.64232955844038],
                            [-73.761944515473431, 40.649985241473956],
                            [-73.771996024929507, 40.650092584875509],
                            [-73.772136359952867, 40.642436873015832],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.766899789096158,
                    centroid_y: 40.653866829528873,
                    zone_label: 577,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.771996024929507, 40.650092584875509],
                            [-73.761944515473431, 40.649985241473956],
                            [-73.761802978120286, 40.657640913917277],
                            [-73.771855635981169, 40.657748286151424],
                            [-73.771996024929507, 40.650092584875509],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.766758798860238,
                    centroid_y: 40.661522511114271,
                    zone_label: 578,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.771855635981169, 40.657748286151424],
                            [-73.761802978120286, 40.657640913917277],
                            [-73.761661386377668, 40.665296575769766],
                            [-73.771715193084418, 40.66540397684301],
                            [-73.771855635981169, 40.657748286151424],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.766617754443644,
                    centroid_y: 40.669178182111786,
                    zone_label: 579,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.771715193084418, 40.66540397684301],
                            [-73.761661386377668, 40.665296575769766],
                            [-73.761519740221914, 40.672952227030784],
                            [-73.771574696215779, 40.673059656949675],
                            [-73.771715193084418, 40.66540397684301],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.766476655822714,
                    centroid_y: 40.676833842520821,
                    zone_label: 580,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.771574696215779, 40.673059656949675],
                            [-73.761519740221914, 40.672952227030784],
                            [-73.761378039629363, 40.680607867699777],
                            [-73.77143414535179, 40.680715326470803],
                            [-73.771574696215779, 40.673059656949675],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.766335502973931,
                    centroid_y: 40.684489492340759,
                    zone_label: 581,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.77143414535179, 40.680715326470803],
                            [-73.761378039629363, 40.680607867699777],
                            [-73.761236284576313, 40.688263497776113],
                            [-73.771293540468974, 40.688370985405811],
                            [-73.77143414535179, 40.680715326470803],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.766194295873689,
                    centroid_y: 40.692145131571046,
                    zone_label: 582,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.771293540468974, 40.688370985405811],
                            [-73.761236284576313, 40.688263497776113],
                            [-73.761094475039101, 40.695919117259223],
                            [-73.771152881543784, 40.696026633754116],
                            [-73.771293540468974, 40.688370985405811],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.766053034498356,
                    centroid_y: 40.699800760211055,
                    zone_label: 583,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.771152881543784, 40.696026633754116],
                            [-73.761094475039101, 40.695919117259223],
                            [-73.760952610993996, 40.703574726148496],
                            [-73.77101216855273, 40.703682271515113],
                            [-73.771152881543784, 40.696026633754116],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.765911718824356,
                    centroid_y: 40.707456378260204,
                    zone_label: 584,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.77101216855273, 40.703682271515113],
                            [-73.760952610993996, 40.703574726148496],
                            [-73.760810692417266, 40.711230324443335],
                            [-73.770871401472277, 40.711337898688207],
                            [-73.77101216855273, 40.703682271515113],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.765770348827971,
                    centroid_y: 40.715111985717897,
                    zone_label: 585,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.770871401472277, 40.711337898688207],
                            [-73.760810692417266, 40.711230324443335],
                            [-73.76066871928515, 40.718885912143143],
                            [-73.770730580278851, 40.718993515272793],
                            [-73.770871401472277, 40.711337898688207],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.765628924485597,
                    centroid_y: 40.722767582583522,
                    zone_label: 586,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.770730580278851, 40.718993515272793],
                            [-73.76066871928515, 40.718885912143143],
                            [-73.760526691573915, 40.726541489247332],
                            [-73.770589704948875, 40.726649121268302],
                            [-73.770730580278851, 40.718993515272793],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.765487445773587,
                    centroid_y: 40.730423168856504,
                    zone_label: 587,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.770589704948875, 40.726649121268302],
                            [-73.760526691573915, 40.726541489247332],
                            [-73.760384609259788, 40.734197055755295],
                            [-73.770448775458817, 40.734304716674117],
                            [-73.770589704948875, 40.726649121268302],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.765345912668209,
                    centroid_y: 40.738078744536253,
                    zone_label: 588,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.770448775458817, 40.734304716674117],
                            [-73.760384609259788, 40.734197055755295],
                            [-73.760242472318964, 40.741852611666452],
                            [-73.770307791785058, 40.741960301489691],
                            [-73.770448775458817, 40.734304716674117],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.765204325145774,
                    centroid_y: 40.745734309622165,
                    zone_label: 589,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.770307791785058, 40.741960301489691],
                            [-73.760242472318964, 40.741852611666452],
                            [-73.760100280727656, 40.749508156980198],
                            [-73.770166753903965, 40.749615875714397],
                            [-73.770307791785058, 40.741960301489691],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.765062683182563,
                    centroid_y: 40.75338986411365,
                    zone_label: 590,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.770166753903965, 40.749615875714397],
                            [-73.760100280727656, 40.749508156980198],
                            [-73.759958034462031, 40.757163691695951],
                            [-73.770025661791934, 40.757271439347655],
                            [-73.770166753903965, 40.749615875714397],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.764920986754873,
                    centroid_y: 40.761045408010126,
                    zone_label: 591,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.770025661791934, 40.757271439347655],
                            [-73.759958034462031, 40.757163691695951],
                            [-73.759815733498243, 40.764819215813098],
                            [-73.769884515425318, 40.764926992388872],
                            [-73.770025661791934, 40.757271439347655],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.764779235838944,
                    centroid_y: 40.768700941310996,
                    zone_label: 592,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.769884515425318, 40.764926992388872],
                            [-73.759815733498243, 40.764819215813098],
                            [-73.759673377812462, 40.77247472933108],
                            [-73.769743314780456, 40.772582534837468],
                            [-73.769884515425318, 40.764926992388872],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.757131846558494,
                    centroid_y: 40.638447697267885,
                    zone_label: 593,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.762227427105813, 40.634673864817174],
                            [-73.752178267455548, 40.634565704853664],
                            [-73.752035691310809, 40.642221369422543],
                            [-73.762085998460734, 40.64232955844038],
                            [-73.762227427105813, 40.634673864817174],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.756989816881614,
                    centroid_y: 40.646103371087044,
                    zone_label: 594,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.762085998460734, 40.64232955844038],
                            [-73.752035691310809, 40.642221369422543],
                            [-73.751893060383196, 40.64987702339522],
                            [-73.761944515473431, 40.649985241473956],
                            [-73.762085998460734, 40.64232955844038],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.756847732630348,
                    centroid_y: 40.653759034312969,
                    zone_label: 595,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.761944515473431, 40.649985241473956],
                            [-73.751893060383196, 40.64987702339522],
                            [-73.751750374648893, 40.657532666771097],
                            [-73.761802978120286, 40.657640913917277],
                            [-73.761944515473431, 40.649985241473956],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.75670559378095,
                    centroid_y: 40.661414686945072,
                    zone_label: 596,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.761802978120286, 40.657640913917277],
                            [-73.751750374648893, 40.657532666771097],
                            [-73.751607634084053, 40.665188299549555],
                            [-73.761661386377668, 40.665296575769766],
                            [-73.761802978120286, 40.657640913917277],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.756563400309645,
                    centroid_y: 40.669070328982755,
                    zone_label: 597,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.761661386377668, 40.665296575769766],
                            [-73.751607634084053, 40.665188299549555],
                            [-73.751464838664845, 40.672843921730014],
                            [-73.761519740221914, 40.672952227030784],
                            [-73.761661386377668, 40.665296575769766],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.756421152192701,
                    centroid_y: 40.676725960425408,
                    zone_label: 598,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.761519740221914, 40.672952227030784],
                            [-73.751464838664845, 40.672843921730014],
                            [-73.751321988367408, 40.680499533311846],
                            [-73.761378039629363, 40.680607867699777],
                            [-73.761519740221914, 40.672952227030784],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.756278849406385,
                    centroid_y: 40.68438158127244,
                    zone_label: 599,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.761378039629363, 40.680607867699777],
                            [-73.751321988367408, 40.680499533311846],
                            [-73.75117908316787, 40.688155134294462],
                            [-73.761236284576313, 40.688263497776113],
                            [-73.761378039629363, 40.680607867699777],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.756136491926853,
                    centroid_y: 40.692037191523248,
                    zone_label: 600,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.761236284576313, 40.688263497776113],
                            [-73.75117908316787, 40.688155134294462],
                            [-73.751036123042326, 40.695810724677273],
                            [-73.761094475039101, 40.695919117259223],
                            [-73.761236284576313, 40.688263497776113],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.755994079730357,
                    centroid_y: 40.699692791177242,
                    zone_label: 601,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.761094475039101, 40.695919117259223],
                            [-73.751036123042326, 40.695810724677273],
                            [-73.750893107966874, 40.70346630445966],
                            [-73.760952610993996, 40.703574726148496],
                            [-73.761094475039101, 40.695919117259223],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.755851612793023,
                    centroid_y: 40.707348380233796,
                    zone_label: 602,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.760952610993996, 40.703574726148496],
                            [-73.750893107966874, 40.70346630445966],
                            [-73.750750037917612, 40.711121873641034],
                            [-73.760810692417266, 40.711230324443335],
                            [-73.760952610993996, 40.703574726148496],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.755709091091077,
                    centroid_y: 40.71500395869235,
                    zone_label: 603,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.760810692417266, 40.711230324443335],
                            [-73.750750037917612, 40.711121873641034],
                            [-73.750606912870595, 40.718777432220776],
                            [-73.76066871928515, 40.718885912143143],
                            [-73.760810692417266, 40.711230324443335],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.755566514600574,
                    centroid_y: 40.72265952655227,
                    zone_label: 604,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.76066871928515, 40.718885912143143],
                            [-73.750606912870595, 40.718777432220776],
                            [-73.750463732801848, 40.726432980198318],
                            [-73.760526691573915, 40.726541489247332],
                            [-73.76066871928515, 40.718885912143143],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.75542388329778,
                    centroid_y: 40.730315083812982,
                    zone_label: 605,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.760526691573915, 40.726541489247332],
                            [-73.750463732801848, 40.726432980198318],
                            [-73.75032049768744, 40.734088517573042],
                            [-73.760384609259788, 40.734197055755295],
                            [-73.760526691573915, 40.726541489247332],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.755281197158709,
                    centroid_y: 40.737970630473889,
                    zone_label: 606,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.760384609259788, 40.734197055755295],
                            [-73.75032049768744, 40.734088517573042],
                            [-73.75017720750337, 40.741744044344358],
                            [-73.760242472318964, 40.741852611666452],
                            [-73.760384609259788, 40.734197055755295],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.755138456159557,
                    centroid_y: 40.745626166534393,
                    zone_label: 607,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.760242472318964, 40.741852611666452],
                            [-73.75017720750337, 40.741744044344358],
                            [-73.750033862225649, 40.749399560511662],
                            [-73.760100280727656, 40.749508156980198],
                            [-73.760242472318964, 40.741852611666452],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.754995660276364,
                    centroid_y: 40.753281691993898,
                    zone_label: 608,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.760100280727656, 40.749508156980198],
                            [-73.750033862225649, 40.749399560511662],
                            [-73.749890461830262, 40.757055066074372],
                            [-73.759958034462031, 40.757163691695951],
                            [-73.760100280727656, 40.749508156980198],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.754852809485186,
                    centroid_y: 40.760937206851807,
                    zone_label: 609,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.759958034462031, 40.757163691695951],
                            [-73.749890461830262, 40.757055066074372],
                            [-73.749747006293177, 40.764710561031876],
                            [-73.759815733498243, 40.764819215813098],
                            [-73.759958034462031, 40.757163691695951],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.754709903762119,
                    centroid_y: 40.768592711107523,
                    zone_label: 610,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.759815733498243, 40.764819215813098],
                            [-73.749747006293177, 40.764710561031876],
                            [-73.749603495590335, 40.772366045383585],
                            [-73.759673377812462, 40.77247472933108],
                            [-73.759815733498243, 40.764819215813098],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.754566943083233,
                    centroid_y: 40.776248204760471,
                    zone_label: 611,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.759673377812462, 40.77247472933108],
                            [-73.749603495590335, 40.772366045383585],
                            [-73.749459929697707, 40.780021519128915],
                            [-73.759530967380826, 40.780130232249284],
                            [-73.759673377812462, 40.77247472933108],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.746938963162776,
                    centroid_y: 40.645994730264988,
                    zone_label: 612,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.752035691310809, 40.642221369422543],
                            [-73.741985438943772, 40.642112305976077],
                            [-73.741841660099652, 40.649767930653091],
                            [-73.751893060383196, 40.64987702339522],
                            [-73.752035691310809, 40.642221369422543],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.74679573076267,
                    centroid_y: 40.653650364309343,
                    zone_label: 613,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.751893060383196, 40.64987702339522],
                            [-73.741841660099652, 40.649767930653091],
                            [-73.741697826008007, 40.657423544726669],
                            [-73.751750374648893, 40.657532666771097],
                            [-73.751893060383196, 40.64987702339522],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.746652443323498,
                    centroid_y: 40.661305987753288,
                    zone_label: 614,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.751750374648893, 40.657532666771097],
                            [-73.741697826008007, 40.657423544726669],
                            [-73.741553936644806, 40.665079148196234],
                            [-73.751607634084053, 40.665188299549555],
                            [-73.751750374648893, 40.657532666771097],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.746509100821271,
                    centroid_y: 40.668961600596212,
                    zone_label: 615,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.751607634084053, 40.665188299549555],
                            [-73.741553936644806, 40.665079148196234],
                            [-73.741409991986004, 40.672734741061156],
                            [-73.751464838664845, 40.672843921730014],
                            [-73.751607634084053, 40.665188299549555],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.746365703232129,
                    centroid_y: 40.676617202837519,
                    zone_label: 616,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.751464838664845, 40.672843921730014],
                            [-73.741409991986004, 40.672734741061156],
                            [-73.741265992007555, 40.680390323320843],
                            [-73.751321988367408, 40.680499533311846],
                            [-73.751464838664845, 40.672843921730014],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.746222250532028,
                    centroid_y: 40.684272794476591,
                    zone_label: 617,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.751321988367408, 40.680499533311846],
                            [-73.741265992007555, 40.680390323320843],
                            [-73.741121936685389, 40.688045894974678],
                            [-73.75117908316787, 40.688155134294462],
                            [-73.751321988367408, 40.680499533311846],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.746078742697023,
                    centroid_y: 40.691928375512845,
                    zone_label: 618,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.75117908316787, 40.688155134294462],
                            [-73.741121936685389, 40.688045894974678],
                            [-73.740977825995429, 40.695701456022078],
                            [-73.751036123042326, 40.695810724677273],
                            [-73.75117908316787, 40.688155134294462],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.745935179703139,
                    centroid_y: 40.699583945945669,
                    zone_label: 619,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.751036123042326, 40.695810724677273],
                            [-73.740977825995429, 40.695701456022078],
                            [-73.740833659913591, 40.703357006462419],
                            [-73.750893107966874, 40.70346630445966],
                            [-73.751036123042326, 40.695810724677273],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.745791561526346,
                    centroid_y: 40.707239505774474,
                    zone_label: 620,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.750893107966874, 40.70346630445966],
                            [-73.740833659913591, 40.703357006462419],
                            [-73.740689438415728, 40.711012546295102],
                            [-73.750750037917612, 40.711121873641034],
                            [-73.750893107966874, 40.70346630445966],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.745647888142614,
                    centroid_y: 40.714895054998628,
                    zone_label: 621,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.750750037917612, 40.711121873641034],
                            [-73.740689438415728, 40.711012546295102],
                            [-73.740545161477741, 40.718668075519538],
                            [-73.750606912870595, 40.718777432220776],
                            [-73.750750037917612, 40.711121873641034],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.745504159527925,
                    centroid_y: 40.722550593617562,
                    zone_label: 622,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.750606912870595, 40.718777432220776],
                            [-73.740545161477741, 40.718668075519538],
                            [-73.740400829075469, 40.726323594135117],
                            [-73.750463732801848, 40.726432980198318],
                            [-73.750606912870595, 40.718777432220776],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.745360375658194,
                    centroid_y: 40.730206121630651,
                    zone_label: 623,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.750463732801848, 40.726432980198318],
                            [-73.740400829075469, 40.726323594135117],
                            [-73.740256441184755, 40.733979102141227],
                            [-73.75032049768744, 40.734088517573042],
                            [-73.750463732801848, 40.726432980198318],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.745216536509389,
                    centroid_y: 40.73786163903732,
                    zone_label: 624,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.75032049768744, 40.734088517573042],
                            [-73.740256441184755, 40.733979102141227],
                            [-73.74011199778144, 40.741634599537271],
                            [-73.75017720750337, 40.741744044344358],
                            [-73.75032049768744, 40.734088517573042],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.745072642057366,
                    centroid_y: 40.745517145836956,
                    zone_label: 625,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.75017720750337, 40.741744044344358],
                            [-73.74011199778144, 40.741634599537271],
                            [-73.73996749884131, 40.749290086322659],
                            [-73.750033862225649, 40.749399560511662],
                            [-73.75017720750337, 40.741744044344358],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.74492869227808,
                    centroid_y: 40.753172642028957,
                    zone_label: 626,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.750033862225649, 40.749399560511662],
                            [-73.73996749884131, 40.749290086322659],
                            [-73.73982294434019, 40.756945562496796],
                            [-73.749890461830262, 40.757055066074372],
                            [-73.750033862225649, 40.749399560511662],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.744784687147401,
                    centroid_y: 40.76082812761274,
                    zone_label: 627,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.749890461830262, 40.757055066074372],
                            [-73.73982294434019, 40.756945562496796],
                            [-73.739678334253838, 40.764601028059062],
                            [-73.749747006293177, 40.764710561031876],
                            [-73.749890461830262, 40.757055066074372],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.744640626641157,
                    centroid_y: 40.7684836025877,
                    zone_label: 628,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.749747006293177, 40.764710561031876],
                            [-73.739678334253838, 40.764601028059062],
                            [-73.739533668558011, 40.772256483008881],
                            [-73.749603495590335, 40.772366045383585],
                            [-73.749747006293177, 40.764710561031876],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.744496510735274,
                    centroid_y: 40.776139066953235,
                    zone_label: 629,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.749603495590335, 40.772366045383585],
                            [-73.739533668558011, 40.772256483008881],
                            [-73.739388947228463, 40.779911927345623],
                            [-73.749459929697707, 40.780021519128915],
                            [-73.749603495590335, 40.772366045383585],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.736743783934088,
                    centroid_y: 40.653540819531841,
                    zone_label: 630,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.741841660099652, 40.649767930653091],
                            [-73.731790315063662, 40.649657963261447],
                            [-73.731645332638692, 40.657313547797898],
                            [-73.741697826008007, 40.657423544726669],
                            [-73.741841660099652, 40.649767930653091],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.736599347929044,
                    centroid_y: 40.661196413552759,
                    zone_label: 631,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.741697826008007, 40.657423544726669],
                            [-73.731645332638692, 40.657313547797898],
                            [-73.731500294501146, 40.664969121723672],
                            [-73.741553936644806, 40.665079148196234],
                            [-73.741697826008007, 40.657423544726669],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.736454856419883,
                    centroid_y: 40.668851996966005,
                    zone_label: 632,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.741553936644806, 40.665079148196234],
                            [-73.731500294501146, 40.664969121723672],
                            [-73.731355200626808, 40.672624685038123],
                            [-73.741409991986004, 40.672734741061156],
                            [-73.741553936644806, 40.665079148196234],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.736310309382432,
                    centroid_y: 40.676507569770983,
                    zone_label: 633,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.741409991986004, 40.672734741061156],
                            [-73.731355200626808, 40.672624685038123],
                            [-73.731210050991407, 40.680280237740668],
                            [-73.741265992007555, 40.680390323320843],
                            [-73.741409991986004, 40.672734741061156],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.736165706792562,
                    centroid_y: 40.68416313196709,
                    zone_label: 634,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.741265992007555, 40.680390323320843],
                            [-73.731210050991407, 40.680280237740668],
                            [-73.731064845570714, 40.687935779830674],
                            [-73.741121936685389, 40.688045894974678],
                            [-73.741265992007555, 40.680390323320843],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.736021048626085,
                    centroid_y: 40.691818683553706,
                    zone_label: 635,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.741121936685389, 40.688045894974678],
                            [-73.731064845570714, 40.687935779830674],
                            [-73.730919584340441, 40.695591311307567],
                            [-73.740977825995429, 40.695701456022078],
                            [-73.741121936685389, 40.688045894974678],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.735876334858844,
                    centroid_y: 40.699474224530242,
                    zone_label: 636,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.740977825995429, 40.695701456022078],
                            [-73.730919584340441, 40.695591311307567],
                            [-73.730774267276288, 40.703246832170713],
                            [-73.740833659913591, 40.703357006462419],
                            [-73.740977825995429, 40.695701456022078],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.735731565466622,
                    centroid_y: 40.707129754896087,
                    zone_label: 637,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.740833659913591, 40.703357006462419],
                            [-73.730774267276288, 40.703246832170713],
                            [-73.73062889435397, 40.710902342419516],
                            [-73.740689438415728, 40.711012546295102],
                            [-73.740833659913591, 40.703357006462419],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.735586740425163,
                    centroid_y: 40.71478527465063,
                    zone_label: 638,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.740689438415728, 40.711012546295102],
                            [-73.73062889435397, 40.710902342419516],
                            [-73.730483465549156, 40.718557842053357],
                            [-73.740545161477741, 40.718668075519538],
                            [-73.740689438415728, 40.711012546295102],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.735441859710278,
                    centroid_y: 40.722440783793282,
                    zone_label: 639,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.740545161477741, 40.718668075519538],
                            [-73.730483465549156, 40.718557842053357],
                            [-73.730337980837504, 40.726213331071662],
                            [-73.740400829075469, 40.726323594135117],
                            [-73.740545161477741, 40.718668075519538],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.735296923297724,
                    centroid_y: 40.730096282323416,
                    zone_label: 640,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.740400829075469, 40.726323594135117],
                            [-73.730337980837504, 40.726213331071662],
                            [-73.730192440194685, 40.733868809473783],
                            [-73.740256441184755, 40.733979102141227],
                            [-73.740400829075469, 40.726323594135117],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.735151931163202,
                    centroid_y: 40.737751770240436,
                    zone_label: 641,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.740256441184755, 40.733979102141227],
                            [-73.730192440194685, 40.733868809473783],
                            [-73.730046843596298, 40.741524277259174],
                            [-73.74011199778144, 40.741634599537271],
                            [-73.740256441184755, 40.733979102141227],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.735006883282466,
                    centroid_y: 40.74540724754376,
                    zone_label: 642,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.74011199778144, 40.741634599537271],
                            [-73.730046843596298, 40.741524277259174],
                            [-73.729901191017987, 40.749179734427166],
                            [-73.73996749884131, 40.749290086322659],
                            [-73.74011199778144, 40.741634599537271],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.734861779631217,
                    centroid_y: 40.753062714232769,
                    zone_label: 643,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.73996749884131, 40.749290086322659],
                            [-73.729901191017987, 40.749179734427166],
                            [-73.729755482435337, 40.756835180977191],
                            [-73.73982294434019, 40.756945562496796],
                            [-73.73996749884131, 40.749290086322659],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.734716620185139,
                    centroid_y: 40.76071817030688,
                    zone_label: 644,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.73982294434019, 40.756945562496796],
                            [-73.729755482435337, 40.756835180977191],
                            [-73.729609717823948, 40.764490616908645],
                            [-73.739678334253838, 40.764601028059062],
                            [-73.73982294434019, 40.756945562496796],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.73457140491989,
                    centroid_y: 40.768373615765455,
                    zone_label: 645,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.739678334253838, 40.764601028059062],
                            [-73.729609717823948, 40.764490616908645],
                            [-73.729463897159391, 40.772146042220939],
                            [-73.739533668558011, 40.772256483008881],
                            [-73.739678334253838, 40.764601028059062],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.726691892585507,
                    centroid_y: 40.653430399994413,
                    zone_label: 646,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.731790315063662, 40.649657963261447],
                            [-73.721739025716005, 40.649547121234292],
                            [-73.721592894981939, 40.657202675998811],
                            [-73.731645332638692, 40.657313547797898],
                            [-73.731790315063662, 40.649657963261447],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.726109218629702,
                    centroid_y: 40.684052593757905,
                    zone_label: 647,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.731210050991407, 40.680280237740668],
                            [-73.721154165760566, 40.680169276585353],
                            [-73.721007810265618, 40.687824788876512],
                            [-73.731064845570714, 40.687935779830674],
                            [-73.731210050991407, 40.680280237740668],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.725233526659196,
                    centroid_y: 40.729985565905281,
                    zone_label: 648,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.730337980837504, 40.726213331071662],
                            [-73.720275188530735, 40.726102191022036],
                            [-73.720128495160139, 40.733757639584823],
                            [-73.730192440194685, 40.733868809473783],
                            [-73.730337980837504, 40.726213331071662],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.72508738156327,
                    centroid_y: 40.737641024097279,
                    zone_label: 649,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.730192440194685, 40.733868809473783],
                            [-73.720128495160139, 40.733757639584823],
                            [-73.719981745391095, 40.741413077524108],
                            [-73.730046843596298, 40.741524277259174],
                            [-73.730192440194685, 40.733868809473783],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.72494118027808,
                    centroid_y: 40.745296471668858,
                    zone_label: 650,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.730046843596298, 40.741524277259174],
                            [-73.719981745391095, 40.741413077524108],
                            [-73.719834939198975, 40.749068504839251],
                            [-73.729901191017987, 40.749179734427166],
                            [-73.730046843596298, 40.741524277259174],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.724794922779168,
                    centroid_y: 40.752951908619366,
                    zone_label: 651,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.729901191017987, 40.749179734427166],
                            [-73.719834939198975, 40.749068504839251],
                            [-73.719688076559237, 40.756723921529655],
                            [-73.729755482435337, 40.756835180977191],
                            [-73.729901191017987, 40.749179734427166],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.724648609042021,
                    centroid_y: 40.760607334948233,
                    zone_label: 652,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.729755482435337, 40.756835180977191],
                            [-73.719688076559237, 40.756723921529655],
                            [-73.719541157447253, 40.764379327594717],
                            [-73.729609717823948, 40.764490616908645],
                            [-73.729755482435337, 40.756835180977191],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.715170186185418,
                    centroid_y: 40.729873972390365,
                    zone_label: 653,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.720275188530735, 40.726102191022036],
                            [-73.710212452597872, 40.725990174000415],
                            [-73.710064606524085, 40.733645592488521],
                            [-73.720128495160139, 40.733757639584823],
                            [-73.720275188530735, 40.726102191022036],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.715022888152561,
                    centroid_y: 40.737529400621966,
                    zone_label: 654,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.720128495160139, 40.733757639584823],
                            [-73.710064606524085, 40.733645592488521],
                            [-73.709916703608926, 40.741301000346297],
                            [-73.719981745391095, 40.741413077524108],
                            [-73.720128495160139, 40.733757639584823],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.714875533487429,
                    centroid_y: 40.745184818226342,
                    zone_label: 655,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.719981745391095, 40.741413077524108],
                            [-73.709916703608926, 40.741301000346297],
                            [-73.709768743827624, 40.748956397573117],
                            [-73.719834939198975, 40.749068504839251],
                            [-73.719981745391095, 40.741413077524108],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.714728122165369,
                    centroid_y: 40.752840225202874,
                    zone_label: 656,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.719834939198975, 40.749068504839251],
                            [-73.709768743827624, 40.748956397573117],
                            [-73.709620727155382, 40.756611784168399],
                            [-73.719688076559237, 40.756723921529655],
                            [-73.719834939198975, 40.749068504839251],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.714580654161651,
                    centroid_y: 40.760495621550959,
                    zone_label: 657,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.719688076559237, 40.756723921529655],
                            [-73.709620727155382, 40.756611784168399],
                            [-73.709472653567403, 40.76426716013151],
                            [-73.719541157447253, 40.764379327594717],
                            [-73.719688076559237, 40.756723921529655],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.704958451374111,
                    centroid_y: 40.737416899828744,
                    zone_label: 658,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.710064606524085, 40.733645592488521],
                            [-73.700000774729403, 40.733532668199153],
                            [-73.699851718692912, 40.741188045740024],
                            [-73.709916703608926, 40.741301000346297],
                            [-73.710064606524085, 40.733645592488521],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.704809943353709,
                    centroid_y: 40.745072287230499,
                    zone_label: 659,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.709916703608926, 40.741301000346297],
                            [-73.699851718692912, 40.741188045740024],
                            [-73.699702605347184, 40.748843412643083],
                            [-73.709768743827624, 40.748956397573117],
                            [-73.709916703608926, 40.741301000346297],
                        ],
                    ],
                },
            },
            {
                type: "Feature",
                properties: {
                    centroid_x: -73.704661378233197,
                    centroid_y: 40.752727663997561,
                    zone_label: 660,
                },
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-73.709768743827624, 40.748956397573117],
                            [-73.699702605347184, 40.748843412643083],
                            [-73.699553434667251, 40.756498768907726],
                            [-73.709620727155382, 40.756611784168399],
                            [-73.709768743827624, 40.748956397573117],
                        ],
                    ],
                },
            },
        ],
    },
];
